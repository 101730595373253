import { combineReducers } from 'redux'
import {reducer as formReducer} from 'redux-form';
import { routerReducer} from 'react-router-redux'
import {reducer as toastrReducer} from 'react-redux-toastr'

import authenticate from './authReducers'
import userBoards from './userBoardsReducers'
import board from './boardReducers'
import global from './globalReducers'
import notifications from './notificationReducers'


const reducers = combineReducers({
    auth: authenticate,
    board,
    userBoards,
    global,
    notifications,
    toastr: toastrReducer,
    routing: routerReducer,
    form: formReducer //Make sure this is always last
})

export default reducers
