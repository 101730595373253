import React from 'react'

const SearchField = ({onSearchBoard, onCloseSearch, searchIsPending, query}) => {

    let handleKeyDown = (e) => {
        if (e.keyCode === 27)
            onCloseSearch()
        else if (e.keyCode === 13)
            handleSearch()
    }

    let handleSearch = () => {
        const query = $("#searchQueryField")[0].value
        if (query.trim().length > 0) {
            onSearchBoard(query.trim())
        }
    }

    return (
        <div className="shtum--search-container">
            <div className="form-group">
                <div className="input-group">
                    <div className="input-group-addon shtum--search-symbol" onClick={handleSearch}>
                        {searchIsPending ? <i className="fa fa-cog fa-spin"/> : <i className="fa fa-search"/>}
                    </div>
                    <input id="searchQueryField" className="form-control shtum--search-box" type="search"
                           onKeyDown={handleKeyDown} placeholder="Search the board" defaultValue={query}/>
                    <div className="input-group-addon shtum--search-close" onClick={onCloseSearch}>
                        <i className="fa fa-times-circle-o"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SearchField;

