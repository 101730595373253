import {firebase} from '../services/dataSvc'
import {log} from '../lib/logging'
import Immutable from 'immutable'
import moment from 'moment'
import {push} from 'react-router-redux'
import toast from '../lib/toast'
import _ from 'lodash'

import {listenToThread} from './threadActions'
import {serviceRoot, functionsRoot} from '../config'
import {logEvent} from '../lib/logging'
import {createSessionId} from "./authActions";

export const initBoard = (boardId, threadId) => {
    let _dispatch, _getState;

    return function (dispatch, getState) {

        _dispatch = dispatch
        _getState = getState

        if (!validateUserAndWaitIfNotAuthorised(dispatch, boardId, threadId))
            return {type: 'IGNORE'};

        detachListeners()

        const boardList = getState().userBoards.boardList

        let userAlias, role, boardName, pinnedThreadId
        if (boardList) {
            userAlias = boardList[boardId].alias
            role = boardList[boardId].role
            boardName = boardList[boardId].name
            pinnedThreadId = pinnedThreadIdFromUserBoard(boardId)

            dispatchInitBoardAction(dispatch, userAlias, role, boardName, pinnedThreadId)

            dispatch(loadBoardAndListen(boardId, threadId, 5));
        } else {
            //TODO: consider always getting this info from FB in case it has changed
            const currentUserId = firebase.auth().currentUser.uid;
            const path = 'users/' + currentUserId + '/boards/' + boardId;

            firebase.database()
                .ref(path)
                .once('value')
                .then(function (snapshot) {
                    userAlias = snapshot.val().alias
                    role = snapshot.val().role
                    boardName = snapshot.val().name

                    dispatchInitBoardAction(dispatch, userAlias, role, boardName)
                    dispatch(loadBoardAndListen(boardId, threadId, 10))
                })
        }

    }

    function pinnedThreadIdFromUserBoard(boardId) {
        const userBoards = _getState().userBoards;
        if (!userBoards) return null;

        const boardList = userBoards.boardList;
        const board = boardList[boardId];
        if (!board) return null;

        return board.pinnedThreadId;
    }

    function detachListeners() {
        const activeBoard = _getState().board.activeBoard;
        if (activeBoard.threadsRef) {
            //log("Detaching listener for threads on board " + activeBoard.boardId)
            activeBoard.threadsRef.off()
        }

        const threads = _getState().board.threads;
        if (threads.length == 0) return

        threads.forEach(function (thread) {
            if (thread.postsRef) thread.postsRef.off()
            if (thread.userThreadNotificationsRef) thread.userThreadNotificationsRef.off()
            //log("Detaching listener for thread id " + thread.threadId)
        })
    }

    function validateUserAndWaitIfNotAuthorised(dispatch) {
        let currentUser = firebase.auth().currentUser;
        if (!currentUser) {
            waitForAuthBeforeInitBoard(dispatch, boardId, threadId);
            return false;
        }
        return true;
    }

    function waitForAuthBeforeInitBoard(dispatch, boardId, threadId) {
        const observer = function (user) {
            if (user) {
                dispatch(initBoard(boardId, threadId));
                unsubscribe();
            }
        };
        const unsubscribe = firebase.auth().onAuthStateChanged(observer);
    }

    function dispatchInitBoardAction(dispatch, userAlias, role, boardName, pinnedThreadId) {
        dispatch({
            type: 'INIT_BOARD',
            boardId,
            threadId,
            userAlias,
            role,
            boardName,
            pinnedThreadId
        });
    }
}

export const loadBoardAndListen = (boardId, threadId, threadCount = 10) => {

    let _dispatch, _getState;

    return function (dispatch, getState) {
        _dispatch = dispatch;
        _getState = getState;

        if (threadId)
            loadSingleThread()
        else
            loadExistingThreads()

        loadMemberNames()
    }

    function loadExistingThreads() {

        let latestThreadId, latestThreadCreatedOn, lastThreadId, lastThreadCreatedOn, pinnedThreadId
        const threadsRef = firebase.database().ref('boards/' + boardId + '/threads')

        threadsRef
            .orderByKey()
            .limitToLast(threadCount)
            .once('value', function (snapshot) {

                let threads = snapshot.val();

                if (!threads)
                    _dispatch(boardThreadsInitialised())
                else {

                    const orderedThreads = Immutable.fromJS(threads).sort(function (thread1, thread2) {
                        let createdOn1 = thread1.get("createdOn");
                        let createdOn2 = thread2.get("createdOn");
                        return createdOn2 - createdOn1;
                    });

                    latestThreadId = orderedThreads.keySeq().first();
                    latestThreadCreatedOn = orderedThreads.first().get("createdOn");

                    lastThreadId = orderedThreads.keySeq().last();
                    lastThreadCreatedOn = orderedThreads.last().get("createdOn");

                    // console.log(`loadBoardAndListen (after): pinnedThreadId=${_getState().board.activeBoard.pinnedThreadId}`);
                    pinnedThreadId = _getState().board.activeBoard.pinnedThreadId;

                    if (pinnedThreadId) {
                        console.log(`listening to pinned thread: ${pinnedThreadId}`)
                        _dispatch(listenToThread(boardId, pinnedThreadId, null, null));
                    }

                    orderedThreads.forEach((thread, threadId, iter) => {

                        const createdOn = thread.get("createdOn")
                        const startedBy = thread.get("startedBy")

                        // Listen to threads, ignore the pinned thread which has already been retrieved
                        if (lastThreadId !== threadId && threadId !== pinnedThreadId) {
                            _dispatch(listenToThread(boardId, threadId, createdOn, startedBy))
                        } else {
                            if (threadId !== pinnedThreadId) {
                                _dispatch(listenToThread(boardId, threadId, createdOn, startedBy, function () {
                                    _dispatch(boardThreadsInitialised(threadsRef, latestThreadId, latestThreadCreatedOn, lastThreadId, lastThreadCreatedOn))
                                }))
                            } else {
                                // Mark the board as initialised if the last thead is the pinned thread
                                _dispatch(boardThreadsInitialised(threadsRef, latestThreadId, latestThreadCreatedOn, lastThreadId, lastThreadCreatedOn))
                            }
                        }
                    });


                }

            }).then(() => {

            listenToThreads(threadsRef, latestThreadId, latestThreadCreatedOn);

        });


    }

    function loadSingleThread() {

        let latestThreadId, latestThreadCreatedOn, lastThreadId, lastThreadCreatedOn;
        const threadRef = firebase.database().ref('boards/' + boardId + '/threads/' + threadId);

        threadRef
            .once('value', function (snapshot) {

                let thread = snapshot.val();

                if (!thread)
                    _dispatch(boardThreadsInitialised())
                else {
                    latestThreadId = threadId
                    latestThreadCreatedOn = thread.createdOn

                    lastThreadId = threadId
                    lastThreadCreatedOn = thread.createdOn

                    const createdOn = thread.createdOn
                    const startedBy = thread.startedBy

                    _dispatch(listenToThread(boardId, threadId, createdOn, startedBy, function () {
                        _dispatch(boardThreadsInitialised(threadRef, latestThreadId, latestThreadCreatedOn, lastThreadId, lastThreadCreatedOn))
                    }))
                }

            })
    }

    function boardThreadsInitialised(threadsRef, latestThreadId, latestThreadCreatedOn, lastThreadId, lastThreadCreatedOn) {
        return (({
            type: 'BOARD_THREADS_INITIALISED',
            latestThreadId,
            latestThreadCreatedOn,
            lastThreadId,
            lastThreadCreatedOn,
            threadsRef
        }))
    }


    function listenToThreads(threadsRef, latestThreadId, latestThreadCreatedOn) {

        latestThreadId = latestThreadId ? latestThreadId : '';

        threadsRef
            .orderByKey()
            .startAt(latestThreadId)
            .on('child_added', function (snapshot) {

                const threadId = snapshot.key;
                const threadInfo = snapshot.val();

                const threadCreatedOn = threadInfo.createdOn;
                if (latestThreadCreatedOn && threadCreatedOn <= latestThreadCreatedOn) return;

                const createdOn = threadInfo.createdOn
                const startedBy = threadInfo.startedBy

                _dispatch(listenToThread(boardId, threadId, createdOn, startedBy))
            })
    }

    function loadMemberNames() {
        $.get(serviceRoot + "boards/" + boardId + "/members", function (data) {
            _dispatch(
                {
                    type: 'MEMBER_NAMES_LOADED',
                    names: data
                }
            )
        })
    }

}

export const loadMoreThreads = (boardId, threadCount = 3) => {

    let _dispatch, _getState;

    return function (dispatch, getState) {
        _dispatch = dispatch
        _getState = getState
        dispatch(moreThreadsLoading())
        loadOlderThreads()
    }

    function loadOlderThreads() {

        const lastThreadId = _getState().board.activeBoard.lastThreadId

        log(`loadMoreThreads-loadOlderThreads (pre-thread load)`)
        //Should be true for the first thread on a board
        if (!lastThreadId) {
            _dispatch(allThreadsLoaded())
            return
        }

        let lastThreadLoadedId, lastThreadLoadedCreatedOn, pinnedThreadId;

        firebase.database()
            .ref('boards/' + boardId + '/threads')
            .orderByKey()
            .endAt(lastThreadId)
            .limitToLast(threadCount + 1)
            .once('value', function (snapshot) {

                let threads = snapshot.val();

//log(`loadMoreThreads-loadOlderThreads (Got firebase thread)`)
                if (!threads || _.size(threads) === 1) {
                    _dispatch(allThreadsLoaded())
                    //log("All threads loaded")
                } else {
                    const orderedThreads = Immutable.fromJS(threads).sort(function (thread1, thread2) {
                        let createdOn1 = thread1.get("createdOn");
                        let createdOn2 = thread2.get("createdOn");
                        return createdOn2 - createdOn1;
                    });

                    lastThreadLoadedId = orderedThreads.keySeq().last();
                    lastThreadLoadedCreatedOn = orderedThreads.last().get("createdOn");
                    pinnedThreadId = _getState().board.activeBoard.pinnedThreadId;

                    orderedThreads.forEach((thread, threadId, iter) => {

                        if (lastThreadId === threadId)
                            return

                        //log('Loading more thread: ' + threadId + ' started on: ' + epochToString(thread.get("createdOn")));

                        const createdOn = thread.get("createdOn")
                        const startedBy = thread.get("startedBy")

                        // Listen to the thread (unless it's the pinned thread)
                        if (lastThreadLoadedId !== threadId && threadId !== pinnedThreadId) {
                            _dispatch(listenToThread(boardId, threadId, createdOn, startedBy))
                        } else {
                            if (threadId !== pinnedThreadId) {
                                _dispatch(listenToThread(boardId, threadId, createdOn, startedBy, function () {
                                    _dispatch(moreThreadsLoaded(lastThreadLoadedId, lastThreadLoadedCreatedOn))
                                }))
                            } else {
                                _dispatch(moreThreadsLoaded(lastThreadLoadedId, lastThreadLoadedCreatedOn));
                            }
                        }
                    });
                }
            })

    }

    function moreThreadsLoading() {
        return (({
            type: 'MORE_THREADS_LOADING'
        }))
    }

    function allThreadsLoaded() {
        return (({
            type: 'ALL_THREADS_LOADED'
        }))
    }

    function moreThreadsLoaded(lastThreadId, lastThreadCreatedOn) {
        return (({
            type: 'MORE_THREADS_LOADED',
            lastThreadId,
            lastThreadCreatedOn
        }))
    }

}

//region Search

export const startSearch = () => {
    return {
        type: 'START_SEARCH'
    }
}

export const closeSearch = () => {
    return {
        type: 'CLOSE_SEARCH'
    }
}

export const pageSearchBoard = (query, page, limit) => {
    return function (dispatch, getState) {
        const offset = (page - 1) * limit
        dispatch(searchBoard(query, offset, limit))
    }
}

export const searchBoard = (query, offset = 0, limit = 10) => {
    let _dispatch, _getState;

    return function (dispatch, getState) {
        _dispatch = dispatch
        _getState = getState

        _dispatch({type: "SEARCH_PENDING"})
        doSearch()
    }

    function doSearch() {
        const boardId = _getState().board.activeBoard.boardId
        //console.log(`Search board query = ${query}, boardId = ${boardId}`)

        const url = functionsRoot + "searchBoardTrigger"
        const q = {b: boardId, q: query, o: offset, l: limit}

        //console.log(`q = ${JSON.stringify(q)}`)
        firebase.auth().currentUser.getIdToken().then(function (token) {
            const settings = {
                url,
                data: q,
                headers: {'authorization': 'Bearer ' + token}
            }

            //console.log(`token = ${JSON.stringify(token)}`)

            $.get(settings)
                .done(function (data) {
                    /*console.log('Query results, data =')
                     console.log(data)*/
                    _dispatch({type: "SEARCH_RESULTS", results: JSON.parse(data), query, offset, limit})
                });
        })
    }
}

export const backToSearchResults = () => {
    return ({type: "BACK_TO_SEARCH_RESULTS"})
}

//endregion

//region User Invites
export const startInviteUsers = () => {
    return {
        type: 'START_INVITE_USERS'
    }
};

export const inviteUsersComplete = () => {
    return {
        type: 'INVITE_USERS_COMPLETE'
    }
}


export const inviteUsers = (emails, boardMembers, message) => {
    let _dispatch, _getState;

    return function (dispatch, getState) {
        _dispatch = dispatch;
        _getState = getState;
        callInviteService();
    }

    function callInviteService() {

        _dispatch({type: 'INVITE_USERS_PROCESSING'})

        const activeBoard = _getState().board.activeBoard;

        const postParams =
            {
                currentBoardId: activeBoard.boardId,
                boardName: activeBoard.name,
                activeAlias: activeBoard.userAlias,
                userId: _getState().auth.uid,
                emails: JSON.stringify(emails),
                boardMembers: JSON.stringify(boardMembers),
                message,
                siteRoot: `${window.location.protocol}//${window.location.host}/`
            };

        console.log(`boardmembers = ${JSON.stringify(boardMembers)}`)

        firebase.auth().currentUser.getIdToken().then(function (token) {

            const settings = {
                url: `${functionsRoot}createInvitesTrigger`,
                data: postParams,
                headers: {'authorization': 'Bearer ' + token}
            }

            const inviteCall = $.post(settings);

            inviteCall.done(
                function (data) {
                    _dispatch(inviteUsersComplete())
                    toast.success('Invites succeeded', 'Your invites have been sent')
                    log(data)
                    const emailCount = emails.length
                    logEvent("Board", "invites", undefined, emailCount)
                }).fail(
                function (jqXHR, textStatus, errorThrown) {
                    _dispatch({type: 'INVITE_USERS_FAILED'})
                    toast.error('Invites failed', 'Please check your internet connection and try again')
                    log('Invites failed\n' + jqXHR.status + '\n' + textStatus + '\n' + errorThrown)
                })
        })
    }
}

export const prepareToAcceptInvite = (inviteCode) => {
    console.log("prepareToAcceptInvite = " + inviteCode)
    return {
        type: 'PREPARE_TO_ACCEPT_INVITE',
        inviteCode
    }
}

export const processInvite = (inviteCode) => {
    let _dispatch, _getState, currentUserId, byUserId,
        byUserAlias, invitedOn, toBoardId, toBoardName, inviteAcceptedOn;

    return function (dispatch, getState) {
        _dispatch = dispatch;
        _getState = getState;
        currentUserId = firebase.auth().currentUser.uid;

        getInviteDetails().then(function () {
            if (inviteAcceptedOn)
                inviteAlreadyAccepted();
            else
                isUserAlreadyAMember().then(function (isUserAMember) {
                    if (isUserAMember)
                        userIsAMember()
                    else
                        dispatchInviteDetails();
                })

        });

    }

    function isUserAlreadyAMember() {
        const path = '/boards/' + toBoardId + '/members/' + currentUserId;

        return new Promise(function (resolve, reject) {
            firebase.database()
                .ref(path)
                .once('value')
                .then(function (snapshot) {
                    const memberDetails = snapshot.val()
                    resolve(memberDetails ? true : false)
                })
        });
    }

    function userIsAMember() {
        _dispatch({type: 'INVITED_USER_ALREADY_A_MEMBER'})

        toast.error('Invite error', 'You are already a member of ' + toBoardName)
    }

    function getInviteDetails() {
        const path = '/invites/' + inviteCode;

        return new Promise(function (resolve, reject) {
            firebase.database()
                .ref(path)
                .once('value')
                .then(function (snapshot) {
                    const invite = snapshot.val()

                    byUserAlias = invite.byUserAlias
                    byUserId = invite.byUserId
                    invitedOn = invite.invitedOn
                    toBoardId = invite.toBoardId
                    toBoardName = invite.toBoardName
                    inviteAcceptedOn = invite.inviteAcceptedOn
                    resolve()
                })
        });
    }

    function inviteAlreadyAccepted() {
        _dispatch({type: 'INVITE_ALREADY_ACCEPTED'})
        toast.error('Invite error', 'This invite has already been accepted')
    }

    function dispatchInviteDetails() {
        const inviteAction = {
            type: 'PROCESS_INVITE',
            inviteCode,
            byUserAlias,
            byUserId,
            invitedOn,
            toBoardId,
            toBoardName
        }

        _dispatch(inviteAction);
    }

}

export const joinBoard = (userAlias) => {
    let _dispatch, _getState, updates = {};
    let inviteCode, byUserId, byUserAlias, invitedOn, toBoardId, toBoardName;
    const currentUserId = firebase.auth().currentUser.uid

    return function (dispatch, getState) {
        _dispatch = dispatch
        _getState = getState

        dispatch({
            type: "JOIN_BOARD_PENDING"
        })

        setScopeVars()
        checkInviteValidity(AcceptInvite)
    }

    function setScopeVars() {
        const invite = _getState().board.invite
        inviteCode = invite.inviteCode
        byUserId = invite.byUserId
        byUserAlias = invite.byUserAlias
        invitedOn = invite.invitedOn
        toBoardId = invite.toBoardId
        toBoardName = invite.toBoardName
    }

    function checkInviteValidity(acceptFunc) {
        //TODO: Check validity
        /*
         Check if user is already a member
         Check if invite has already been accepted
         *Possibly check these things earlier anyway

         Check if alias has already been used
         */
        if (true)
            acceptFunc()
    }


    function AcceptInvite() {

        const postParams =
            {
                toBoardName,
                userId: currentUserId,
                userAlias,
                byUserId,
                byUserAlias
            };


        const acceptInviteCall = $.post(`${serviceRoot}boards/${toBoardId}/invites/${inviteCode}/accept`, postParams);

        acceptInviteCall.done(
            function (data) {
                _dispatch(push('/board/' + toBoardId))
                _dispatch({
                    type: 'BOARD_JOINED',
                    name: toBoardName,
                    pending: false,
                    error: false,
                    boardId: toBoardId
                })
                logEvent("Board", "invite-accepted")
            }).fail(
            function (jqXHR, textStatus, errorThrown) {
                _dispatch({
                    type: 'JOIN_BOARD_ERROR',
                    pending: false,
                    name: toBoardName,
                    error: true,
                    boardId: toBoardId,
                    errorMessage: "There was an error joining the board: " + textStatus
                })
            })
    }
}

export const joinBoardComplete = () => {
    return {
        type: 'BOARD_JOINED'
    }
}
//endregion
