let firebaseConfig, serviceRoot, gaTrackingCode, functionsRoot;
let newDisplayPostSecs = 600;

if(__DEV__ || __LOCAL__ || __LOCAL_LIVE__)
    gaTrackingCode = 'UA-85790710-1'

if(__TEST__)
    gaTrackingCode = 'UA-85790710-2'

if (__DEV__ || __LOCAL__) {
    firebaseConfig = {
        apiKey: "AIzaSyDYNFOzjiumpBijXB_4UZuh1MOKjgcmrCg",
        authDomain: "shtum-dev.firebaseapp.com",
        databaseURL: "https://shtum-dev.firebaseio.com",
        storageBucket: "shtum-dev.appspot.com"
    }
}

if (__TEST__) {
    firebaseConfig = {
        apiKey: "AIzaSyAiGWNROyvyUk0-TQt49qgAwCE3fkvrY4w",
        authDomain: "shtum-test.firebaseapp.com",
        databaseURL: "https://shtum-test.firebaseio.com",
        storageBucket: "shtum-test.appspot.com"
    }
}

if (__PROD__ || __LOCAL_LIVE__) {
    firebaseConfig = {
        apiKey: "AIzaSyD2-_sOSfh786jMe7NksQAZVQT6o5JtFyo",
        authDomain: "shtum.firebaseapp.com",
        databaseURL: "https://shtum.firebaseio.com",
        storageBucket: "project-6857976610930136836.appspot.com"
    }
}

if (__LOCAL__) {
    serviceRoot = 'http://localhost:8088/'
    functionsRoot = 'https://us-central1-shtum-dev.cloudfunctions.net/'
}

if (__LOCAL_LIVE__) {
    serviceRoot = 'http://localhost:8088/'
    functionsRoot = 'https://us-central1-project-6857976610930136836.cloudfunctions.net/'
}

if (__DEV__) {
    serviceRoot = 'https://shtum-dev.herokuapp.com/'
    functionsRoot = 'https://us-central1-shtum-dev.cloudfunctions.net/'
}

if (__TEST__) {
    serviceRoot = 'https://shtum-test.herokuapp.com/'
    functionsRoot = 'https://us-central1-shtum-test.cloudfunctions.net/'
}

if (__PROD__) {
    serviceRoot = 'https://shtum.herokuapp.com/'
    functionsRoot = 'https://us-central1-project-6857976610930136836.cloudfunctions.net/'
}


if (__LOCAL__ || __DEV__ || __TEST__) {
    console.log("The __LOCAL__ variable = " + __LOCAL__)
    console.log("The __DEV__ variable = " + __DEV__)
    console.log("The __TEST__ variable = " + __TEST__)
    console.log("The __PROD__ variable = " + __PROD__)

    console.log("serviceRoot = " + serviceRoot)
}

export {firebaseConfig, serviceRoot, functionsRoot, newDisplayPostSecs, gaTrackingCode}
