import React, {Component, PropTypes} from 'react';
import Modal from 'react-modal'
import {reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux'

import {joinBoard, joinBoardComplete} from "../actions/boardActions";

const validate = values => {
    const errors = {};
    const regex = /[^\w\.\-\_\u00C0-\u024F\u1E00-\u1EFF]/gmi;

    if (!values.userAlias) {
        errors.userAlias = 'You must enter the alias you want to use on this board'
    } else if (values.userAlias.trim().length < 3) {
        errors.userAlias = 'Alias names must be at least 3 chars'
    } else if (regex.test(values.userAlias)){
        errors.userAlias = "No spaces or special characters are allowed"
    }

    return errors
};

class JoinBoard extends Component {
    constructor(props) {
        super(props)
        this.state = {
            modalIsOpen: props.showJoinBoard
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
    }

    renderUserAlias = field =>
        <>
            <div className="form-group has-error shtum--form-error">
                {field.meta.touched && field.meta.error && <div className="help-block">{field.meta.error}</div>}
                {this.props.joinBoardError && this.props.errorMessage &&
                <div className="help-block">{this.props.errorMessage}</div>}
            </div>
            <div className="form-group">
                <input type="field.type"
                       className="form-control shtum--form-input shtum--form-input_dark-gray shtum--font-bold"
                       id="userAlias"
                       placeholder="Enter the alias you're going to use for this board" {...field.input}
                       name="userAlias"/>
            </div>
        </>

    render() {
        const {
            // fields: {userAlias},
            showJoinBoard,
            boardName,
            onJoinBoardComplete,
            onJoinBoard,
            pending,
            joinBoardError,
            errorMessage,
            submitting,
            handleSubmit,
            pristine,
            valid
        } = this.props;

        const customStyles = {
            content: {
                top: '60px'
            }
        };

        return (

            <Modal
                className="Modal__Bootstrap modal-dialog shtum--modal"
                closeTimeoutMS={150}
                isOpen={showJoinBoard}
                onRequestClose={this.handleModalCloseRequest}
                style={customStyles}
                contentLabel="Join Board"
            >
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h4 className="modal-title">Accept invite to the board: {boardName}</h4>
                    </div>
                    <div className="modal-body errMessage">
                        <form onSubmit={handleSubmit(data => onJoinBoard(data.userAlias))}>

                            <Field
                                name="userAlias"
                                component={this.renderUserAlias}
                                type="text"/>

                            <div className="modal-footer errMessage">
                                <div className="form-group">
                                    <button type="button" className="btn shtum--btn shtum--btn_red" data-dismiss="modal"
                                            onClick={onJoinBoardComplete}>Cancel
                                    </button>
                                    <button type="submit" className="btn shtum--btn shtum--btn_green_2"
                                            disabled={!valid || pristine || submitting || pending}>
                                        Join Board
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

JoinBoard = reduxForm({
    form: 'JoinBoardForm',
    // fields: ['userAlias'],
    validate
})(JoinBoard);

const mapStateToProps = (state) => {
    let board = state.board
    let invite = board.invite
    if (!invite) return ({showJoinBoard: false})

    return {
        showJoinBoard: invite ? invite.invitePending && invite.inviteValidated : false,
        boardName: invite.toBoardName,
        pending: invite.processPending,
        joinBoardError: invite.error,
        errorMessage: invite.errorMessage
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onJoinBoardComplete: () => {
            dispatch(joinBoardComplete())
        },
        onJoinBoard: (userAlias) => {
            dispatch(joinBoard(userAlias))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(JoinBoard);
