const global = (state = {pulseValue: 0}, action) => {
    switch (action.type) {
        case 'PULSE':
            return {
                pulseValue: action.value
            };
        default:
            return state;
    }
};

export default global;
