import Immutable from 'immutable'
import Cookies from 'js-cookie'

const userBoards = (state = {boardList: null, sortOrder: null}, action) => {
    switch (action.type) {
        case 'USER_LOGGED_OUT':
            return{
                boardList: null,
                sortOrder: null
            }
        case 'BOARD_CREATED':
            if (!action.error)
                return {
                    ...state,
                    createBoard: false,
                    error: false,
                    newBoard: {
                        name: action.name,
                        boardKey: action.boardKey,
                        boardOwnerId: action.boardOwnerId
                    }
                };
            else return {
                ...state,
                error: true
            }
        case 'CHANGE_ROLE_PENDING':
            return{
                ...state,
                changeRolePending: true,
                changeRoleMemberId: action.memberId
            }
        case 'CHANGE_ROLE_COMPLETE':
            return{
                ...state,
                changeRolePending: false,
                changeRoleMemberId: null
            }
        case 'CREATE_BOARD':
            return {
                ...state,
                createBoard: true,
                error: false
            }
        case 'CREATE_BOARD_COMPLETE':
            return {
                ...state,
                createBoard: false,
                error: false
            }
        case 'BOARDS_LIST_RECEIVED':
        case 'BOARDS_LIST_UPDATED':
        case 'BOARD_SORT_ORDER':
            let sortOrder = getSortOrder()
            let boardList = sortBoards(action.boards || state.boardList || {}, sortOrder).toJS()

            return {
                ...state,
                boardList, // : action.boards,
                sortOrder
            }
        case 'LAST_POST_ON_RECEIVED':
            let newBoardList = {...state.boardList}
            newBoardList[action.boardId].lastPostOn = action.lastPostOn
            newBoardList[action.boardId].lastPostByAlias = action.lastPostByAlias
            newBoardList[action.boardId].pinnedThreadId = action.pinnedThreadId

            return {
                ...state,
                newBoardList,
                //Pulse to force update
                receivedLastPostDatesOn: Date.now()
            }
        default:
            return state;
    }

    function getSortOrder() {
        return action.sortOrder || state.sortOrder || Cookies.get('sortOrder') || 'A-Z'
    }

    function sortBoards(boards, sortOrder) {
        var sortField, sortedBoards, isMyBoards = false
        switch (sortOrder) {
            case "My Boards":
                sortField = "name"
                isMyBoards = true
                break;
            case "Date Joined":
                sortField = "joinedOn"
                break;
            case "Most Recent Post":
                sortField = "lastPostOn"
                break;
            case "A-Z":
            default:
                sortField = "name"
                break;
        }

        sortedBoards = Immutable.fromJS(boards).sort(function (board1, board2) {
            let value1 = board1.get(sortField)
            let value2 = board2.get(sortField)

            let board1Role = board1.get("role")
            let board2Role = board2.get("role")

            if (isMyBoards) {
                const isBoard1Owner = (board1Role == "owner")
                const isBoard2Owner = (board2Role == "owner")

                if (isBoard1Owner && isBoard2Owner)
                    return testVals(value1, value2)

                return (isBoard1Owner && !isBoard2Owner ? -1 : 1)
            } else
                return testVals(value1, value2)

            function testVals(val1, va2) {
                if ($.isNumeric(val1 || 0) && $.isNumeric(value2 || 0))
                    return testNumbers(val1 || 0, va2 || 0)
                else
                    return testStrings(val1 || "", va2 || "")
            }

            function testStrings(val1, val2) {
                return val1.toLowerCase() < val2.toLowerCase() ? -1 : 1
            }

            function testNumbers(val1, val2) {
                return val2 - val1
            }
        })

        return sortedBoards
    }
}


export default userBoards;
