import firebase from 'firebase'
import {firebaseConfig} from '../config'

firebase.initializeApp(firebaseConfig);

const dbRef = firebase.database().ref();
const user = firebase.auth().currentUser;
const usersRef = dbRef.child("users");

export { firebase, dbRef, usersRef, user }
