const notifications = (state = {
    items: [],
    aggregateItems: [],
    unseenNotificationCount: 0,
    showNotifications: false,
    showNotificationSettings: false
}, action) => {

    switch (action.type) {
        case 'INIT_NOTIFICATIONS':
        case 'NEW_NOTIFICATION':
        case 'NOTIFICATION_UPDATE':
            return ({
                ...state,
                items: action.notifications,
                aggregateItems: action.aggregateNotifications
            })
        case 'SHOW_NOTIFICATIONS':
            return {
                ...state,
                showNotifications: true
            }
        case 'CLOSE_NOTIFICATIONS':
            return {
                ...state,
                showNotifications: false
            }
        case 'SHOW_NOTIFICATIONS_SETTINGS':
            return {
                ...state,
                showNotificationSettings: true
            }
        case 'CLOSE_NOTIFICATIONS_SETTINGS':
            return {
                ...state,
                showNotificationSettings: false
            }
        case 'NOTIFICATION_SETTINGS':
            return {
                ...state,
                notifyFrequency: action.notifyFrequency,
                emailAllMentions: action.emailAllMentions,
                showDesktopNotifications: action.showDesktopNotifications
            }
        case 'NOTIFICATIONS_SETTINGS_UPDATED':
            return {
                ...state,
                showNotificationSettings: false
            }
        default:
            return state;
    }
}

export default notifications;
