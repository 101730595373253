import {getPostIndexByPostId} from '../lib/postUtils'
import Immutable from 'immutable'

const board = (state = {
    activeBoard: {siteLoading: true},
    threads: [],
    posts: [],
    action: {
        type: 'NONE',
        showPostModal: false,
        showSearchField: false,
        searchResults: null
    }
}, action) => {
    switch (action.type) {
        case 'USER_LOGGED_OUT':
            return {
                activeBoard: {},
                threads: [],
                posts: [],
                invite: state.invite,
                action: {type: 'NONE', showPostModal: false}
            }
        case 'INIT_BOARD':
            return {
                ...state,
                activeBoard: {
                    boardId: action.boardId,
                    threadId: action.threadId,
                    userAlias: action.userAlias,
                    role: action.role,
                    isOwner: action.role === "owner",
                    name: action.boardName,
                    threadsLoading: true,
                    allThreadsLoaded: false,
                    siteLoading: false,
                    pinnedThreadId: action.pinnedThreadId,
                },
                threads: [],
                posts: [],
                action: {
                    type: 'NONE'
                }
            }
        case "PINNED_THREAD_ID_RECEIVED":
            if(action.boardId !== state.activeBoard.boardId)
                return state;

            const newActiveBoardPinnedThreadId = {
                ...state.activeBoard,
                pinnedThreadId: action.pinnedThreadId
            }

            return {
                ...state,
                activeBoard: newActiveBoardPinnedThreadId
            }
        case 'MEMBER_NAMES_LOADED':
            return {
                ...state,
                memberNames: action.names
            }
        //Listening to threads for a board
        case 'THREAD_LISTENER_ATTACHED':
            const thread = {
                threadId: action.threadId,
                boardId: action.boardId,
                createdOn: action.createdOn,
                startedBy: action.startedBy,
                postsRef: action.postsRef
            };

            if (action.threadId > state.activeBoard.latestThreadId) {
                const newActiveBoardThreadListenerAttached = {
                    ...state.activeBoard,
                    latestThreadId: action.threadId,
                    latestThreadCreatedOn: action.createdOn,
                }
                return {
                    ...state,
                    activeBoard: newActiveBoardThreadListenerAttached,
                    threads: [...state.threads, thread]
                };
            }

            return {
                ...state,
                threads: [...state.threads, thread]
            };
        case 'THREAD_NOTIFICATION_SETTING':
            const threadsIm = Immutable.fromJS(state.threads)
            const thId = action.threadId
            const threadIndex = threadsIm.findIndex(function (th) {
                return th.get("threadId") === thId
            })

            const updatedThreads = threadsIm
                .setIn([threadIndex, "following"], action.value)
                .setIn([threadIndex, "userThreadNotificationsRef"], action.userThreadNotificationsRef)
                .toJS()

            return {
                ...state,
                threads: updatedThreads
            };

        case 'BOARD_THREADS_INITIALISED':
            const newActiveBoard = {
                ...state.activeBoard,
                threadsLoading: false,
                latestThreadId: action.latestThreadId,
                latestThreadCreatedOn: action.latestThreadCreatedOn,
                lastThreadId: action.lastThreadId,
                lastThreadCreatedOn: action.lastThreadCreatedOn,
                threadsRef: action.threadsRef,
            }
            return {
                ...state,
                activeBoard: newActiveBoard
            }
        case 'MORE_THREADS_LOADING':
            const newActiveBoardMoreThreadsLoading = {
                ...state.activeBoard,
                threadsLoading: true
            }

            return {
                ...state,
                activeBoard: newActiveBoardMoreThreadsLoading
            }
        case 'MORE_THREADS_LOADED':
            const newActiveBoardMoreThreads = {
                ...state.activeBoard,
                threadsLoading: false,
                lastThreadId: action.lastThreadId || state.activeBoard.lastThreadId,
                lastThreadCreatedOn: action.lastThreadCreatedOn || state.activeBoard.lastThreadCreatedOn
            }
            return {
                ...state,
                activeBoard: newActiveBoardMoreThreads
            }
        case 'ALL_THREADS_LOADED':
            const newActiveBoardAllThreads = {
                ...state.activeBoard,
                threadsLoading: false,
                allThreadsLoaded: true
            }

            return {
                ...state,
                activeBoard: newActiveBoardAllThreads
            }
        case 'POSTS_LOADED':
            const newPosts = state.posts.concat(action.posts)
            const latestPostPostedOn = Math.max.apply(Math, newPosts.map(function (post) {
                return post.postedOn
            }))

            const newActiveBoardLatestPost = {
                ...state.activeBoard,
                latestPostPostedOn
            }

            return {
                ...state,
                activeBoard: newActiveBoardLatestPost,
                posts: newPosts //state.posts.push(...action.posts)
            }
        case 'POST_RECEIVED':
            const post = postFromAction(action)

            if (action.postedOn > state.activeBoard.latestPostPostedOn) {
                const newActiveBoardPostReceived = {
                    ...state.activeBoard,
                    latestPostPostedOn: action.postedOn
                }

                return {
                    ...state,
                    activeBoard: newActiveBoardPostReceived,
                    posts: [...state.posts, post]
                }
            }

            return {
                ...state,
                posts: [...state.posts, post]
            };
        case 'UPDATED_POST_RECEIVED':
            const updatedPost = postFromAction(action)

            let index = getPostIndexByPostId(state.posts, action.postId);
            let newUpdatedPosts = [
                ...state.posts.slice(0, index),
                updatedPost,
                ...state.posts.slice(index + 1)];

            return {
                ...state,
                posts: newUpdatedPosts
            };

        case 'CREATE_POST':
            return {
                ...state,
                action: {
                    type: 'CREATE_POST',
                    showPostEditModal: true,
                    boardId: action.boardId,
                    threadId: 'NewThread',
                    postId: 0,
                    lastPostRepliedToId: state.action.postId
                }
            };
        case 'REPLY_TO_POST':
            return {
                ...state,
                action: {
                    type: 'REPLY_TO_POST',
                    post: action.replyToPost,
                    showPostEditModal: true,
                    boardId: action.replyToPost.boardId,
                    threadId: action.replyToPost.threadId,
                    postId: action.replyToPost.postId,
                    lastPostRepliedToId: state.activeBoard.lastPostRepliedToId
                }
            };
        case 'EDIT_POST':
            return {
                ...state,
                action: {
                    type: 'EDIT_POST',
                    post: action.editPost,
                    showPostEditModal: true,
                    boardId: action.editPost.boardId,
                    threadId: action.editPost.threadId,
                    postId: action.editPost.postId,
                    lastPostRepliedToId: action.editPost.postId
                }
            };
        case 'CREATE_POST_PENDING':
        case 'UPDATE_POST_PENDING':
            const newActionPostPending = {
                ...state.action,
                pending: true
            }

            return {
                ...state,
                action: newActionPostPending
            }
        case 'CREATE_POST_COMPLETE':
        case 'UPDATE_POST_COMPLETE':
            return {
                ...state,
                action: {
                    type: 'NONE',
                    showPostEditModal: false,
                    pending: false,
                    //TODO: rename this
                    lastPostRepliedToId: state.action.postId
                }
            };
        case 'DELETE_POST_COMPLETE':
            return {
                ...state,
                action: {
                    type: 'NONE',
                    showPostEditModal: false
                }
            };
        case 'START_SEARCH':
            return {
                ...state,
                action: {
                    type: 'START_SEARCH',
                    showSearchField: true
                }
            };
        case 'SEARCH_PENDING':
            return {
                ...state,
                action: {
                    type: 'SEARCH_PENDING',
                    showSearchField: true,
                    searchPending: true,
                    showSearchResults: state.action.showSearchResults,
                    searchResults: state.action.searchResults
                }
            };
        case 'SEARCH_RESULTS':
            return {
                ...state,
                action: {
                    type: 'SEARCH_RESULTS',
                    showSearchField: true,
                    searchPending: false,
                    showSearchResults: true,
                    searchResults: {
                        numberOfResults: action.results.numberOfResults || 0,
                        posts: postsFromSearchResults(action.results.posts) || {},
                        query: action.query || "",
                        offset: action.offset,
                        limit: action.limit
                    }
                }
            };
        case 'CLOSE_SEARCH':
            return {
                ...state,
                action: {
                    type: 'NONE',
                    showSearchField: false,
                    searchPending: false,
                    showSearchResults: false,
                    searchResults: null
                },
                lastSearchResults: state.action.searchResults ? {
                    numberOfResults: state.action.searchResults.numberOfResults,
                    posts: state.action.searchResults.posts,
                    query: state.action.searchResults.query,
                    offset: state.action.searchResults.offset,
                    limit: state.action.searchResults.limit
                } : {}
            };
        case 'BACK_TO_SEARCH_RESULTS':
            return {
                ...state,
                action: {
                    type: 'SEARCH_RESULTS',
                    showSearchField: true,
                    searchPending: false,
                    showSearchResults: true,
                    searchResults: {
                        numberOfResults: state.lastSearchResults.numberOfResults,
                        posts: state.lastSearchResults.posts,
                        query: state.lastSearchResults.query,
                        offset: state.lastSearchResults.offset,
                        limit: state.lastSearchResults.limit
                    }
                },
                lastSearchResults: {}
            };
        case 'START_INVITE_USERS':
            return {
                ...state,
                inviteUsers: true,
                inviteUsersProcessing: false
            }
        case 'INVITE_USERS_PROCESSING':
            return {
                ...state,
                inviteUsers: true,
                inviteUsersProcessing: true
            }
        case 'INVITE_USERS_FAILED':
            return {
                ...state,
                inviteUsers: true,
                inviteUsersProcessing: false
            }
        case 'INVITE_USERS_COMPLETE':
            return {
                ...state,
                inviteUsers: false,
                inviteUsersProcessing: false
            }
        case 'PREPARE_TO_ACCEPT_INVITE':
            return {
                ...state,
                invite: {
                    invitePending: true,
                    inviteCode: action.inviteCode
                }
            }
        case 'JOIN_BOARD_PENDING':
            var newPendingInvite = {
                ...state.invite,
                processPending: true
            }
            return {
                ...state,
                invite: newPendingInvite
            }
        case 'PROCESS_INVITE':
            return {
                ...state,
                invite: {
                    invitePending: true,
                    processPending: false,
                    inviteValidated: true,
                    inviteCode: action.inviteCode,
                    byUserAlias: action.byUserAlias,
                    byUserId: action.byUserId,
                    invitedOn: action.invitedOn,
                    toBoardId: action.toBoardId,
                    toBoardName: action.toBoardName
                }
            }
        case 'INVITE_ALREADY_ACCEPTED':
        case 'INVITED_USER_ALREADY_A_MEMBER':
        case 'BOARD_JOINED':
            return {
                ...state,
                invite: {
                    invitePending: false,
                    processPending: false
                }
            }
        case 'JOIN_BOARD_ERROR':
            var newInvite = {
                ...state.invite,
                processPending: false,
                error: true,
                errorMessage: action.errorMessage
            }
            return {
                ...state,
                invite: newInvite
            }
        case 'MANAGE_MEMBERS':
            return {
                ...state,
                action: {
                    type: 'MANAGE_MEMBERS',
                    showManageMembers: true,
                    members: action.members
                }
            }
        case 'CLOSE_MANAGE_MEMBERS':
            return {
                ...state,
                action: {
                    type: 'NONE',
                    showManageMembers: false
                }
            }
        case 'REINSTATE_MEMBER_PENDING':
        case 'REMOVE_MEMBER_PENDING':
            var newAction = {
                ...state.action,
                pending: true
            }

            return {
                ...state,
                action: newAction
            }
        case 'REINSTATE_MEMBER_ERROR':
        case 'REINSTATE_MEMBER_COMPLETE':
        case 'REMOVE_MEMBER_ERROR': //TODO handle errors properly
        case 'REMOVE_MEMBER_COMPLETE':
            var newCompleteAction = {
                ...state.action,
                pending: false
            }

            return {
                ...state,
                action: newCompleteAction
            }

        default:
            return state;
    }
};

function postFromAction(action) {
    return {
        boardId: action.boardId,
        postId: action.postId,
        threadId: action.threadId,
        parentPostId: action.parentPostId,
        postedByAlias: action.postedByAlias,
        postedById: action.postedById,
        updatedOn: action.updatedOn,
        updatedById: action.updatedById,
        isDeleted: action.isDeleted,
        originallyPostedById: action.originallyPostedById,
        deletedById: action.deletedById,
        postedOn: action.postedOn,
        htmlContent: action.htmlContent,
        rawContent: action.rawContent,
        mediaObject: action.mediaObject,
        mediaLocation: action.mediaLocation,
        originalFileName: action.originalFileName,
        reactions: action.reactions,
        embedData: action.embedData,
        imageWidth: action.imageWidth,
        imageHeight: action.imageHeight

    };
}

function postsFromSearchResults(posts) {
    return posts.map(p => postFromAction(p))
}

export default board;
