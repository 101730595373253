import React, {Component, PropTypes} from 'react';
import Modal from 'react-modal'
import {reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux'

import {createBoard, createBoardComplete} from '../actions/userBoardsActions'

const validate = values => {
    const errors = {};
    const regex = /[^\w\.\-\_\u00C0-\u024F\u1E00-\u1EFF]/gmi;

    if (!values.boardName) {
        errors.boardName = 'A board name is required'
    } else if (values.boardName.trim().length < 2) {
        errors.boardName = 'Board names must be at least 2 chars'
    }

    if (!values.ownerAlias) {
        errors.ownerAlias = 'You must state the alias you want to use on this board'
    } else if (values.ownerAlias.trim().length < 2) {
        errors.ownerAlias = 'Alias names must be at least 3 chars'
    } else if (regex.test(values.ownerAlias)){
        errors.ownerAlias = "No spaces or special characters are allowed"
    }

    return errors
};

class CreateBoard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: props.showCreateBoard
        }
    };

    renderBoardname = field =>
        <>
            <div className="form-group has-error shtum--form-error">
                {field.meta.touched && field.meta.error &&
                <div className="help-block">{field.meta.error}</div>}
            </div>
            <div className="form-group">
                <input
                    className="form-control shtum--form-input shtum--form-input_dark-gray shtum--font-bold"
                    id="boardName" type={field.type}
                    placeholder="Name of Board" {...field.input} name="boardName"/>
            </div>
        </>

    renderOwnerAlias = field =>
        <>
            <div className="form-group has-error shtum--form-error">
                {field.meta.touched && field.meta.error &&
                <div className="help-block">{field.meta.error}</div>}
                {this.props.createBoardError && this.props.errorMessage &&
                <div className="help-block">{this.props.errorMessage}</div>}
            </div>
            <div className="form-group">
                <input
                    className="form-control shtum--form-input shtum--form-input_dark-gray shtum--font-bold"
                    id="ownerAlias" type={field.type}
                    placeholder="Your Alias" {...field.input} name="ownerAlias"/>
            </div>
        </>

    render() {
        const {
            // fields: {boardName, ownerAlias},
            showCreateBoard,
            onCreateBoardComplete,
            onCreateBoard,
            pending,
            createBoardError,
            errorMessage,
            submitting,
            handleSubmit,
            pristine,
            valid
        } = this.props;

        const customStyles = {
            content: {
                top: '60px'
            }
        };

        return (

            <Modal
                className="Modal__Bootstrap modal-dialog shtum--modal shtum--modal-create-new-board"
                closeTimeoutMS={150}
                isOpen={showCreateBoard}
                onRequestClose={this.handleModalCloseRequest}
                style={customStyles}
                contentLabel="Create Board"
            >
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h4 className="modal-title">Create Board</h4>
                    </div>
                    <div className="modal-body errMessage">
                        <form onSubmit={handleSubmit(data => onCreateBoard(data.boardName, data.ownerAlias))}>
                            <div className="modal-body">

                                <Field
                                    name="boardName"
                                    component={this.renderBoardname}
                                    type="text"/>
                                <Field
                                    name="ownerAlias"
                                    component={this.renderOwnerAlias}
                                    type="text"/>
                            </div>
                            <div className="modal-footer errMessage">
                                <div className="form-group">
                                    <button type="button" className="btn shtum--btn shtum--btn_red" data-dismiss="modal"
                                            onClick={onCreateBoardComplete}>Cancel
                                    </button>
                                    <button type="submit" className="btn shtum--btn shtum--btn_green_2"
                                            disabled={!valid || pristine || submitting || pending}>
                                        Create
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

CreateBoard = reduxForm({
    form: 'CreateBoardForm',
    // fields: ['boardName', 'ownerAlias'],
    validate
})(CreateBoard);

const mapStateToProps = (state) => {
    let userBoards = state.userBoards;
    return {
        showCreateBoard: userBoards.createBoard,
        pending: userBoards.pending,
        createBoardError: userBoards.error,
        errorMessage: userBoards.errorMessage
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateBoardComplete: () => {
            dispatch(createBoardComplete())
        },
        onCreateBoard: (boardName, ownerAlias) => {
            dispatch(createBoard(boardName, ownerAlias))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateBoard);
