import {firebase, dbRef, usersRef} from './dataSvc'
import Cookies from 'js-cookie'

export function logOut() {
    firebase.auth().signOut().then(function () {
        // Sign-out successful.
    }, function (error) {
        // TODO: handle failed signout
    });
}

export function currentUserId(){
    firebase.auth().currentUser.uid;
}

export function saveNewUser(uid, email) {
    firebase.database().ref('/users/' + uid ).set({
        email: email,
        createdOn: firebase.database.ServerValue.TIMESTAMP //Firebase.ServerValue.TIMESTAMP
    }).then(function () {
        //alert('user created')
    }, function (error) {
        alert('An error occurred: ' + error.message)
    });
}

export function resetPassword(email, callBack) {
     firebase.auth().sendPasswordResetEmail(email).then(function() {
         callBack();
     }, function(err) {
         callBack(err);
     });
}
