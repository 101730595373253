import React from 'react'
import {connect} from 'react-redux'

class PostUrlPreview extends React.Component {
    constructor(props) {
        super(props)
        this.state = {embedUrl: null, htmlPreview: false, isPostEdit: props.isPostEdit}
    }

    componentDidMount() {
        if (this.props.embedData)
            this.setState(this.props.embedData)
        this.setState({isPostEdit: !!(this.props.isPostEdit)});
    }

    componentDidUpdate(prevProps) {
        if (this.props.isPostEdit && this.props.url !== this.state.embedUrl) {
            this.setState({embedUrl: this.props.url})
            this.handleUrlChange(this.props.url)
        }
    }

    handleUrlChange = (url) => {

        let embedState
        let error = false
        this.setState({loadingPreview: true})
        this.props.loadingUrlPreviewHandler(true)

        /*
        $.getJSON('https://api.embedly.com/1/oembed?' + $.param({
            url: url,
            key: "7568090ec3ec479b9f11c2047518320f",
            secure: true,
            // frame: true,
            // maxheight: 150,
            maxwidth: 300
         */
        $.ajaxSetup({
            headers : {
                'x-api-key': '0beaa230-0125-4017-9d2e-61a2d3f805b0'
            }
        });
        $.getJSON('https://api.embed.rocks/api/?' + $.param({
            url: url,
            include: "oembed",
        }), (data) => {

            console.log("!!!!!!!PostUrlPreview being called")
            /* console.log("PostUrlPreview data")
             console.log(data)*/
            const supportedProviders = ["BandCamp", "Spotify", "SoundCloud", "Mixcloud", "YouTube", "Vimeo", "DailyMotion", "TED", ]
            const handledRichProviders = supportedProviders.some(function (v) {
                return data.site.toLowerCase() && data.site.indexOf(v.toLowerCase()) >= 0;
            })

            if (data.type === "error") {
                error = true
            }
            else if (data.oembed && data.oembed.html && handledRichProviders) {
                const html = data.oembed.html; // this.adaptIFrameForPreview(data)
                embedState = {
                    htmlPreview: true,
                    html,
                    embedUrl: url,
                    embedTitle: data.title,
                    source: data.site
                }
                this.setState(embedState)
            } else if (data.html) {
                const html = data.html; // this.adaptIFrameForPreview(data)
                embedState = {
                    htmlPreview: true,
                    html,
                    embedUrl: url,
                    embedTitle: data.title,
                    source: data.site
                }
                this.setState(embedState)
            }
            // else if (data.type === "video" || handledRichProviders) {
            //     const html = data.html; // this.adaptIFrameForPreview(data)
            //     embedState = {
            //         htmlPreview: true,
            //         html,
            //         embedUrl: url,
            //         embedTitle: data.title,
            //         source: data.site
            //     }
            //     this.setState(embedState)
            // } else if (data.type == "photo" && data.provider_name != "Instagram") {
            //     embedState = {
            //         htmlPreview: false,
            //         imagePreview: true,
            //         embedUrl: url,
            //         source: data.site
            //     }
            //     this.setState(embedState)
            // }
            else {
                embedState = {
                    htmlPreview: false,
                    imagePreview: false,
                    embedTitle: data.title,
                    embedText: data.description,
                    previewImageUrl: data.thumbnail_url,
                    source: data.site,
                    embedUrl: url
                }
                this.setState(embedState)
            }

        }).fail((jqxhr, textStatus, error) => {
            //jqxhr.status contains the error code
            if (this.props.raiseEmbedError)
                this.props.raiseEmbedError(this.state.embedUrl, jqxhr.status)
        }).always(() => {
            this.setState({
                loadingPreview: false
            })
            if (error){
                if (this.props.raiseEmbedError)
                    this.props.raiseEmbedError(this.state.embedUrl, "error")
            } else {
                this.props.updateEmbedData(embedState)
            }
            this.props.loadingUrlPreviewHandler(false)
        });
    }

    adaptIFrameForPreview = (data) => {
        const widthAndHeightRegex = /width="[0-9]+" height="[0-9]+"/g
        const htmlNoWidthAndHeight = data.html.replace(widthAndHeightRegex, '')

        const classRegex = /class="(.*?(?="))/gi
        const classMatch = classRegex.exec(htmlNoWidthAndHeight)[1]
        const classString = `class="${classMatch} embedFrame`

        return htmlNoWidthAndHeight.replace(classRegex, classString);
    }

    createMarkup = (src) => {
        return {__html: src}
    }

    render() {

        /*if (this.props.url != this.state.embedUrl)
         console.log("this.props.url = " + this.props.url)*/

        let titleClassName = this.state.isPostEdit ? "embedTitle" : ""

        return (
            <div className="media embedPreviewContainer">
                {this.state.loadingPreview ?
                    <div className="loadingSmall">
                        <strong>LOADING...</strong>
                        {/*<img src="../../images/loading.gif"/>*/}
                    </div>
                    :
                    this.state.htmlPreview ?
                        <div>
                            <div dangerouslySetInnerHTML={this.createMarkup(this.state.html)}/>
                            {/*<div className="embed-footer"><a href={this.state.embedUrl} target="_blank">*/}
                            {/*    <strong>{this.state.source}</strong> {this.state.embedTitle}</a></div>*/}
                        </div>
                        :
                        this.state.imagePreview ?
                            <div>
                                <a href={this.state.embedUrl} target="_blank">
                                    <img className="thumbnail mediaPreview" src={this.state.embedUrl}/>
                                </a>
                            </div>
                            :
                            <div>
                                <div className="media-left media-top embed-image">
                                    <a href={this.state.embedUrl} target="_blank">
                                        <img className="embedPreviewImage" src={this.state.previewImageUrl}/>
                                    </a>
                                </div>
                                < div className="media-body embed-body">
                                    <div className={titleClassName}>
                                        <a className="embedTitleLink" href={this.state.embedUrl} target="_blank">
                                            <h4 className="media-heading">{this.state.embedTitle}</h4>
                                        </a>
                                    </div>
                                    <div className="hidden-xs">{this.state.embedText}</div>
                                    {/*<div className="embed-footer"><a href={this.state.embedUrl}*/}
                                    {/*                                 target="_blank">{this.state.source}</a></div>*/}
                                </div>
                            </div>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
    /*let board = state.board;
     return {
     threads: board.threads,
     posts: board.posts
     }*/
};

const mapDispatchToProps = (dispatch) => {
    return {
        /*onSubscribeToThread: (boardId, threadId, userId) =>
         dispatch(subscribeToThread(boardId, threadId, userId)),
         onUnsubscribeFromThread: (boardId, threadId, userId) =>
         dispatch(unsubscribeFromThread(boardId, threadId, userId, false)),*/
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostUrlPreview);
