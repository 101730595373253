import React, {Component, PropTypes} from 'react';
import {reduxForm, formValueSelector, Field} from 'redux-form';
import {connect} from 'react-redux'

import {loginWithPassword} from '../actions/authActions'
import {forgotPassword} from "../actions/authActions";

const validate = values => {
    const errors = {};

    if (!values.email) {
        errors.email = 'An email address is required to login'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }

    return errors
};

class Login extends Component {

    handleResetPassword = (e) => {
        e.preventDefault()
        const email = this.props.email || '';
        this.props.onForgotPassword(email.trim())
    }

    render() {
        const {
            // fields: {email, password},
            onLogin,
            pending,
            loginError,
            errorMessage,
            submitting,
            handleSubmit
        }
            = this.props;


        return (
            <div
                className="col-xs-8 col-sm-6 col-md-4 col-xs-offset-2 col-sm-offset-3 col-md-offset-4 shthum--auth-page-login">
                <form id="frmLogin" className="shtum--form-login" role="form"
                      onSubmit={handleSubmit(data => onLogin(data.email, data.password))}>

                    <Field
                        name="email"
                        component={field => {
                            return (<>
                                <div className="form-group has-error shtum--form-error">
                                    {field.meta.touched && field.meta.error &&
                                    <div className="help-block">{field.meta.error}</div>}
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control shtum--form-input" id="email"
                                           placeholder="Enter email" {...field.input} name="email"/>
                                </div>
                            </>)
                        }}
                        type="text"/>

                    <Field
                        name="password"
                        component={field => {
                            return (<>
                                <div className="form-group has-error shtum--form-error">
                                    {loginError && errorMessage &&
                                    <div className="help-block">{errorMessage}</div>}
                                </div>
                                <div className="form-group">
                                    <input type="password" className="form-control shtum--form-input" id="password"
                                           placeholder="Password" {...field.input} name="password"/>
                                </div>
                            </>)
                        }}
                        type="text"/>


                    <div className="form-inline">
                        <div className="form-group">
                            <Field
                                name="remember"
                                component={field => {
                                    return (
                                        <div className="checkbox shtum--form-login-checkbox">
                                            <div className="shtum--checkbox">
                                                <input type="checkbox" id="frmLoginCheckbox" name="remember"
                                                       {...field.input}/>
                                                <span/>
                                            </div>
                                            <label htmlFor="frmLoginCheckbox">remember me</label>
                                        </div>)
                                }}
                                type="checkbox"/>
                        </div>
                        <div className="form-group text-right">
                            <button type="submit" className="btn shtum--btn shtum--btn_green"
                                    disabled={submitting || pending}>
                                Login
                            </button>
                        </div>
                    </div>
                    <div className="form-group text-right">
                        <a href="#" className="shtum--form-login-forgotten-link"
                           onClick={this.handleResetPassword.bind(this)}>forgotten password?</a>
                    </div>
                </form>
            </div>
        )
            ;
    }
}

Login = reduxForm({
    form: 'LoginForm',
    // fields: ['email', 'password'],
    validate
})(Login);

const selector = formValueSelector('LoginForm')
const mapStateToProps = (state) => {
    let auth = state.auth;
    const email = selector(state, 'email')
    return {
        pending: auth.pending,
        loginError: auth.error,
        errorCode: auth.errorCode,
        errorMessage: auth.errorMessage,
        email
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogin: (email, password) => {
            dispatch(loginWithPassword(email, password))
        },
        onForgotPassword: (email) =>
            dispatch(forgotPassword(email))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Login);
