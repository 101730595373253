import React from 'react'


export default class MessageModal extends React.Component {
    showAlert (opts) {
        //var title = opts.title;
        var detail = opts.detail;
        var modal = $('#messageModal');

        modal.modal();
        modal.find('#message').text(detail);
    }
    componentDidMount = () => {
        var that = this;
        $('#messageModal').on('hide.bs.modal', function (e) {
            if(that.props.handleCloseMessageModal){
                that.props.handleCloseMessageModal(that.props.stateObj);
            }
        })
    }
    closeMessageModal (){
        $('#messageModal').modal('hide');
    }
    render () {
        return (
            <div className="modal fade shtum--modal" id="messageModal" tabIndex="-1" role="dialog">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-body errMessage">
                            <h4 id="message" className="text-center"></h4>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn shtum--btn shtum--btn_red" onClick={this.closeMessageModal}>Close</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
