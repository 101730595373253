import React from 'react'
import {connect} from "react-redux";

import {unsubscribeFromThread} from "../actions/threadActions";

class Unsubscribe extends React.Component {

    constructor(props) {
        super(props)
        let entity = props.params.entity
        let entityId1 = props.params.entityId1
        let entityId2 = props.params.entityId2
        let entityId3 = props.params.entityId3

        props.onUnsubscribe(entity, entityId1, entityId2, entityId3)
    }


    render() {
        console.log('render unsubscribe')

        return (
            <div className="pageCenter">
                <i className="fa fa-cog fa-spin spinnerIcon"/>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        onUnsubscribe: (entity, entityId1, entityId2, entityId3) => {
            if (entity == "thread") {
                var boardId = entityId1, threadId = entityId2 //, userId = entityId3
                dispatch(unsubscribeFromThread(boardId, threadId, true))
            }
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Unsubscribe);
