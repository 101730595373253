import React, {Component, PropTypes} from 'react';
import Modal from 'react-modal'
import {reduxForm, reset, Field} from 'redux-form';
import {connect} from 'react-redux'

import {passwordResetComplete, requestPasswordReset} from '../actions/authActions'

const validate = values => {
    const errors = {};

    if (!values.email) {
        errors.email = 'An email address is required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
        errors.email = 'Invalid email address'
    }

    return errors
};

class ForgotPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: props.email || "",
            modalIsOpen: props.showResetPassword
        }
    };

    render() {
        const {
            // fields: {email},
            showResetPassword,
            onPasswordResetComplete,
            onRequestPasswordReset,
            pending,
            authError,
            errorMessage,
            submitting,
            handleSubmit
        } = this.props;

        return (

            <Modal
                className="Modal__Bootstrap modal-dialog shtum--modal"
                closeTimeoutMS={150}
                isOpen={showResetPassword}
                onRequestClose={this.handleModalCloseRequest}
            >
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h4 className="modal-title">Reset Password</h4>
                    </div>
                    <div className="modal-body errMessage">
                        <form className="shtum--form-reset-password"
                              onSubmit={handleSubmit(data => onRequestPasswordReset(data.email))}>
                            <div className="modal-body">
                                <Field
                                    name="email"
                                    component={field => {
                                        return (<>
                                            <div className="form-group has-error shtum--form-error">
                                                {field.meta.touched && field.meta.error &&
                                                <div className="help-block">{field.meta.error}</div>}
                                                {authError && errorMessage &&
                                                <div className="help-block">{errorMessage}</div>}
                                            </div>
                                            <div className="form-group">
                                                <input type="email"
                                                       className="form-control shtum--form-input shtum--form-input_dark-gray shtum--font-bold"
                                                       id="password-email"
                                                       placeholder="Enter email" {...field.input} name="email"/>
                                            </div>
                                        </>)
                                    }}
                                    type="text"/>
                            </div>
                            <div className="modal-footer errMessage">
                                <div className="form-group">
                                    <button type="button" className="btn shtum--btn shtum--btn_red" data-dismiss="modal"
                                            onClick={onPasswordResetComplete}>Close
                                    </button>
                                    <button type="submit"
                                            className="btn shtum--btn shtum--btn_green_2 shtum--btn-reset-password"
                                            disabled={submitting || pending}>
                                        {(submitting || pending) ? <i className="fa fa-cog fa-spin"/> :
                                            <i className="fa fa-unlock-alt"/>} Reset Password
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

ForgotPassword = reduxForm({
    form: 'ResetPasswordForm',
    // fields: ['email'],
    validate
})(ForgotPassword);

const mapStateToProps = (state) => {
    let auth = state.auth;
    return {
        email: auth.email,
        showResetPassword: auth.forgotPassword,
        pending: auth.pending,
        authError: auth.error,
        errorMessage: auth.errorMessage
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onPasswordResetComplete: () => {
            dispatch(passwordResetComplete())
        },
        onRequestPasswordReset: (email) => {
            dispatch(requestPasswordReset(email))
            dispatch(reset('ResetPasswordForm'))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgotPassword);
