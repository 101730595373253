import React from 'react'
import {connect} from 'react-redux'
import Modal from 'react-modal'
import loadImage from 'blueimp-load-image'

import PostUrlPreview from './PostUrlPreview'
import {createPost, updatePost} from '../actions/postActions'
import {createPostComplete} from '../actions/threadActions'
import {findFirstLinkInText} from '../lib/linkifyState'
import {readImageInfo} from '../lib/image'
import toast from "../lib/toast";

import moment from 'moment'
import * as fileUtil from "../lib/fileUtil";

class PostEditModal extends React.Component {
    constructor(props) {
        super(props)

        this.state = this.getDefaultState(props)
        this.setEditContent.bind(this)
    }

    getDefaultState = (props = this.props) => {
        return {
            parentPostId: props.postId,
            subscribeChecked: true,//TODO: replace with default or preset value for this thread
            modalOpened: false,
            mediaObject: 'none',
            mediaLocation: null,
            postMediaId: `${Date.now()}-media`,
            lastLinkEnteredOn: 0,
            currentFirstLink: null,
            lastLinkEntered: null,
            errorLink: null,
            linkErrorInfo: null,
            embedData: null,
            editorSubject: "",
            editorBody: "",
            newImagePreview: false,
            loadingUrlPreview: false
        }
    }

    handleOnChange = (event) => {

        const textBoxValue = event.target.value
        const controlName = event.target.id

        if (controlName === "editorSubject")
            this.setState({editorSubject: textBoxValue})
        else if (controlName === "editorBody")
            this.setState({editorBody: textBoxValue})

        if (this.state.mediaLocation) return

        const firstLink = findFirstLinkInText(textBoxValue)

        /*If the mediaLocation is null but the firstLink is the same as the last entered link, this means
         the user has probably deleted the embedly preview and doesn't want it in the post so ignore it*/
        if (this.state.lastLinkEntered === firstLink) return

        if (firstLink) {
            if (firstLink !== this.state.currentFirstLink) {
                this.setState({currentFirstLink: firstLink})
                this.setState({lastLinkEnteredOn: moment().valueOf()})
            }

            //Allow the user to stop typing before setting the link
            window.setTimeout(() => {
                    const timeSinceLastLinkEntered = moment().diff(this.state.lastLinkEnteredOn, 'milliseconds')
                    if (timeSinceLastLinkEntered > 1000) {
                        if (firstLink !== this.state.mediaLocation) {

                            console.log(`New mediaLocation is: ${firstLink}`)
                            this.setState({mediaObject: "Embed", mediaLocation: firstLink, lastLinkEntered: firstLink})

                        }
                    }
                },
                1000
            )
        }
    }

    raiseEmbedError = (url, errInfo) => {
        //Raised when the PostUrlPreview has an error (e.g a 404 issue)
        this.setState({errorLink: url, linkErrorInfo: errInfo})
        this.setState({mediaObject: "none", mediaLocation: null})

        console.log("raiseEmbedError")
    }

    updateEmbedData = (embedData) => {
        this.setState({embedData})
    }

    handleOpenModal = () => {
        if (this.state.modalOpened) return

        let pageOffset = $(window).scrollTop()
        if (pageOffset === 0)
            $("body").scrollTop()

        this.setState({pageOffset})

        //Set the focus if this is NOT an iPhone due to layout bug
        if (navigator.userAgent.indexOf('CPU iPhone') === -1) {
            if (this.refs.editorSubject)
                this.refs.editorSubject.focus()
            else
                this.refs.editorBody.focus()
        }

        if (this.props.isEdit)
            this.setEditContent()
        else
            this.checkForNewPost()

        this.bindImageEvents()
        this.initAutocomplete()
    }

    initAutocomplete = () => {
        const that = this,
            editorSubject = $('#editorSubject'),
            editorBody = $('#editorBody')

        editorSubject.atwho({
            at: "@",
            data: this.props.memberNames
        })

        editorSubject.on("inserted.atwho", function (event, flag, query) {
            that.setState({editorSubject: editorSubject.val()})
        })

        editorBody.atwho({
            at: "@",
            data: this.props.memberNames
        })

        editorBody.on("inserted.atwho", function (event, flag, query) {
            that.setState({editorBody: editorBody.val()})
        })
    }


    bindImageEvents = () => {
        $('.fileinput').on('change.bs.fileinput', this.handleImageChange.bind(this))
    }

    setEditContent = () => {
        const rawPost = this.props.postRaw
        this.setState({editorSubject: rawPost.subject, editorBody: rawPost.body})
        this.setEditMediaContent()
    }

    setEditMediaContent = () => {
        const {mediaObject, mediaLocation, embedData, originalFileName} = this.props

        if (!mediaObject || mediaObject === "none") {
            this.setState({mediaObject: "none"})
        }
        else if (mediaObject === "Embed") {
            this.setState(
                {
                    mediaObject: "Embed",
                    mediaLocation,
                    embedData
                })
        }
        else if (mediaObject === "Image") {
            //TODO: Fix the "Edit" state for images
            this.setState(
                {
                    mediaObject: "Image",
                    mediaLocation,
                    postEditImagePreview: true,
                    postEditImageLocation: mediaLocation,
                    newImagePreview: false
                })
        } else if (mediaObject === "File") {
            this.setState(
                {
                    mediaObject: "File",
                    mediaLocation,
                    postEditImagePreview: false,
                    postEditImageLocation: null,
                    newImagePreview: false,
                    filePreview: true,
                    displayFileName: originalFileName,
                    editFileName: originalFileName,
                    editFileLocation: mediaLocation
                })
        }
    }

    checkForNewPost = () => {
        if (!this.props.isEditingSamePost) {
            //console.log("checkForNewPost")
            this.clearEditorState()
        }
    }

    clearEditorState = () => {
        this.setState({
            editorSubject: "",
            editorBody: "",
            mediaObject: 'none',
            mediaLocation: null,
            lastLinkEntered: null,
            newImagePreview: false
        })
    }

    handleCancelPost = (e) => {
        console.log("handleCancelPost")

        if (e)
            e.preventDefault()

        this.setState(
            {
                modalOpened: false,
                postEditImagePreview: false,
                postEditImageLocation: null,
                newImagePreview: false
            })

        const {isNewPost, postId, threadId} = this.props

        this.props.cancelPost()
        /*
         console.log(isNewPost)
         console.log(postId)*/

        const postKey = postId + '-' + threadId

        if (!isNewPost)
            $('html, body')
                .animate({
                    scrollTop: this.state.pageOffset // $(`#${postKey}`).offset().top - 100
                }, 100)
    }


    handleSubmitPost = (e) => {
        e.preventDefault();

        const preparedContent = this.prepareEditorContentForSubmission()
        const rawContent = preparedContent.rawContent
        const mentionMembers = preparedContent.mentionMembers

        const mediaContent = this.prepareMediaContent()

        const {boardId, threadId, postId, isEdit, post} = this.props

        if (isEdit)
            this.props.updatePost(post, this.state.subscribeChecked, rawContent, mentionMembers, mediaContent)
        else
            this.props.submitPost(boardId, threadId, postId, this.state.subscribeChecked, rawContent, mentionMembers, mediaContent)

        this.clearEditorState();
        this.setState({modalOpened: false})
    }

    prepareEditorContentForSubmission = () => {
        const rawContent = {
            subject: this.refs.editorSubject ? this.refs.editorSubject.value : null,
            body: this.refs.editorBody.value
        }
        const mentionMembers = this.identifyMentions(rawContent)

        return {rawContent, mentionMembers}
    }

    identifyMentions = (rawContent) => {
        const txt = `${rawContent.subject} ${rawContent.body}`
        const matches = txt.match(/\B@[a-z0-9_.-]+/gi)

        const memberNames = this.props.memberNames
        const mentionMembers = []

        if (matches) {
            matches.forEach(id => {
                const toMatch = id.replace('@', '')
                const member = memberNames.find(member => member.name === toMatch)
                if (member) {
                    mentionMembers.push({key: member.key, name: member.name})
                }
            })
        }

        return mentionMembers
    }

    prepareMediaContent = () => {
        let mediaFile = null, mediaObject = this.state.mediaObject,
            mediaLocation = this.state.mediaLocation,
            embedData = this.state.embedData, mediaDataURL = null,
            mediaWidth = null, mediaHeight = null,
            originalFileName = this.state.displayFileName;

        if (this.state.postEditImagePreview) {
            mediaObject = "RetainImage"
        }
        else if (this.state.mediaObject === "File") {
            if (this.state.mediaLocation === this.state.editFileLocation) {
                mediaObject = "RetainFile"
            }
            else {
                mediaFile = document.getElementById('fileInput').files[0]
            }
        }
        else if (this.state.mediaObject === "Image") {
            mediaFile = document.getElementById('imageInput').files[0]
            mediaDataURL = $('#mediaImageLoaded')[0].toDataURL()
            mediaWidth = this.state.mediaObjectXDimension
            mediaHeight = this.state.mediaObjectYDimension
        }

        return ({
            mediaObject,
            mediaLocation,
            mediaFile,
            embedData,
            mediaDataURL,
            mediaWidth,
            mediaHeight,
            originalFileName
        })
    }

    handleSubscribeChanged = (e) => {
        this.setState({subscribeChecked: e.target.checked});
    }

    createMarkup = (src) => {
        return {__html: src};
    }

    handleFileChange = (e) => {

        const file = e.target.files[0]

        if ((file.size / 1048576) > 10) {
            toast.error("File too big!", "File is over 10MB")
            e.target.value = null
            return
        }

        const filePath = e.target.value
        const fileName = fileUtil.extractFilename(filePath)
        const displayFileName = this.getDisplayFileName(fileName)

        if (fileName.endsWith(".exe")) {
            toast.error("Not allowed", "Executables are not allowed")
            e.target.value = null
            return
        }

        if (filePath) {
            this.setState(
                {
                    mediaObject: "File",
                    mediaLocation: fileName,
                    postEditImagePreview: false,
                    postEditImageLocation: null,
                    newImagePreview: false,
                    filePreview: true,
                    displayFileName
                })
        }
    }

    getDisplayFileName = (fileName) => {
        if (fileName.length < 50) {
            return fileName
        }

        return `${fileName.substring(0, 25)}...${fileName.substring(fileName.length - 22)}`
    }

    handleImageChange = (e) => {

        if (!this.refs.imageInput) return

        const imagePath = this.refs.imageInput.value
        const imageFile = this.refs.imageInput.files[0]

        //console.log(imageFile)

        const that = this
        loadImage(
            imageFile,
            function (img, data) {
                img.id = 'mediaImageLoaded'
                $('#mediaPreview').empty().append(img)

                readImageInfo(imageFile).then((imageInfo) => {
                    const exifData = data.exif ? data.exif.getAll() : {}

                    that.setState({
                        mediaObjectXDimension: exifData.PixelXDimension || imageInfo.width,
                        mediaObjectYDimension: exifData.PixelYDimension || imageInfo.height
                    })
                })
            },
            {
                orientation: true,
                canvas: true,
                maxHeight: 200
            }
        )

        if (imagePath) {
            this.setState(
                {
                    mediaObject: "Image",
                    mediaLocation: imagePath,
                    postEditImagePreview: false,
                    postEditImageLocation: null,
                    newImagePreview: true
                })
        }
    }

    removeMediaObject = () => {
        $('#mediaPreview').empty()

        this.setState(
            {
                mediaObject: "none",
                mediaLocation: null,
                postEditImagePreview: false,
                postEditImageLocation: null,
                newImagePreview: false,
                filePreview: false
            })
    }

    handleEditorSubjectKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault()
            this.refs.editorBody.focus()
            this.refs.editorBody.selectionStart = 0
            this.refs.editorBody.selectionEnd = 0
        }
    }

    handleEditorBodyKeyDown = (event) => {
        if (event.target.selectionStart === 0 && event.target.selectionEnd === 0) {
            //console.log(event.keyCode)

            if (event.keyCode === 8 || event.keyCode === 37) {
                event.preventDefault()
                this.refs.editorSubject.focus()

                const len = this.refs.editorSubject.value.length
                this.refs.editorSubject.selectionStart = len
                this.refs.editorSubject.selectionEnd = len
            }
        }
    }

    handleLoadingUrlPreview = (loadingPreview) => {
        console.log("loadingPreview", loadingPreview)
        this.setState({loadingUrlPreview: loadingPreview})
    }

    render() {
        let isSmallScreen = (screen.width <= 480 || screen.height <= 800)
        let modalTop = isSmallScreen ? 30 : 60

        const customStyles = {
            content: {
                top: modalTop + "px",
            }
        };

        const {
            showCreatePost,
            pending,
            post,
            isNewPost,
            isComplete,
            isReply,
            isEdit,
            postHTML,
            userAlias
        } = this.props;

        let title = isComplete ? '' :
            (isNewPost ? 'Start a new conversation' :
                (isReply ? 'Create a reply' :
                    (isEdit ? 'Edit post' : '')));


        if (pending)
            console.log("Create Post is pending")

        const editorRows = isNewPost ? 4 : 5;
        const loadingUrlPreview = this.state.loadingUrlPreview

        console.log("render method, loadingUrlPreview = ", loadingUrlPreview)

        return (
            <Modal
                className="Modal__Bootstrap modal-dialog shtum--modal shtum--modal-thread"
                closeTimeoutMS={150}
                isOpen={showCreatePost}
                onRequestClose={this.handleCancelPost}
                style={customStyles}
                onAfterOpen={this.handleOpenModal.bind(this)}
                shouldCloseOnOverlayClick={false}
                contentLabel="New Post"
            >
                <div className="modal-content">
                    <div className="modal-header text-center hidden-xs">
                        <h4 className="modal-title">{title}</h4>
                        {(isNewPost || isEdit) ? '' :
                            <div className="replyToPostText shtum--modal-thread-reply-to-container" dangerouslySetInnerHTML={this.createMarkup(postHTML)}/>}
                    </div>
                    <div className="modal-body errMessage">

                        <form onSubmit={this.handleSubmitPost.bind(this)}>
                            <div className="modal-body postEditModalBody shtum--modal-thread-body">
                                <div className="well editWell">
                                    <div className="newPostEditor" ref="newPostEditor">
                                        {this.state.editorSubject || isNewPost ?
                                            <div>
                                                <textarea
                                                    id="editorSubject"
                                                    ref="editorSubject"
                                                    className="editorSubject"
                                                    placeholder="Subject"
                                                    rows="1"
                                                    onKeyDown={this.handleEditorSubjectKeyDown}
                                                    onChange={this.handleOnChange}
                                                    value={this.state.editorSubject}
                                                />
                                            </div> : ""}
                                        <div>
                                            <textarea
                                                id="editorBody"
                                                ref="editorBody"
                                                className="editorBody"
                                                placeholder="Text..."
                                                rows={editorRows}
                                                onKeyDown={this.handleEditorBodyKeyDown}
                                                onChange={this.handleOnChange}
                                                value={this.state.editorBody}
                                            />
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer errMessage">
                                <div className="form-group action-area">
                                    <div className="no-wrap">
                                        <div className="postActionButtons fileinput fileinput-new" data-provides="fileinput" role="toolbar">
                                            <span id="imageInputBtn" type="button" className="btn-file mediaButton shtum--modal-thread-img-btn shtum--btn-svg">
                                                <span className="fileinput-new"><svg className="shtum--svg shtum--svg-lg shtum--svg_green"><use xlinkHref="#icon-image"></use></svg></span>
                                                <span className="fileinput-exists"><svg className="shtum--svg shtum--svg-lg shtum--svg_green"><use xlinkHref="#icon-image"></use></svg></span>
                                                <input type="file" id="imageInput" ref="imageInput" name="imageInput" onChange={this.handleImageChange.bind(this)}/>
                                            </span>
                                        </div>

                                        <div className="postActionButtons" role="toolbar">
                                            <span id="fileInputBtn" type="button" className="btn-file mediaButton shtum--modal-thread-file-btn shtum--btn-svg">
                                                <span className="fileinput-new" onClick={() => $('#fileInput').click()}><svg className="shtum--svg shtum--svg-lg shtum--svg_green"><use xlinkHref="#icon-paperclip"></use></svg></span>
                                                {/*<span className="fileinput-exists"><svg className="shtum--svg shtum--svg-lg shtum--svg_green"><use xlinkHref="#icon-paperclip"></use></svg></span>*/}
                                                <input type="file" style={{display: "hidden"}} id="fileInput" ref="fileInput" name="fileInput" onChange={this.handleFileChange.bind(this)}/>
                                            </span>
                                        </div>

                                        {(isNewPost || isReply) ?
                                            <span className="subscribeCheckBox shtum--modal-thread-checkbox-container">
                                                <input type="checkbox" id="subscribeCheckBox"
                                                       checked={this.state.subscribeChecked}
                                                       onChange={this.handleSubscribeChanged.bind(this)}
                                                       title="Subscribe to notifications for this conversation"/>
                                                <label htmlFor="subscribeCheckBox" className="subscribeCheckBoxLabel shtum--modal-thread-checkbox-label shtum--btn-svg">
                                                    <svg className={`shtum--svg shtum--svg-lg ${ this.state.subscribeChecked ? "shtum--svg_green" : "shtum--svg_white-stroke_green" }`}><use xlinkHref="#icon-flash"></use></svg>
                                                </label>
                                            </span> : ''}
                                        <button type="button" className="btn shtum--btn shtum--btn_red" onClick={this.handleCancelPost} disabled={pending}>
                                            Cancel
                                        </button>
                                        <button type="submit" className="btn shtum--btn shtum--btn_green_2" disabled={pending || loadingUrlPreview}>
                                            {(pending || loadingUrlPreview) ? <i className="fa fa-cog fa-spin"/> : <i className="fa fa-paper-plane-o"/>} Post
                                        </button>
                                    </div>
                                    <div className="form-group postingAsGroup">
                                        <span className="postingAs">Posting as <strong>{userAlias}</strong></span>
                                    </div>
                                    <div className="mediaPreview">
                                        {this.state.mediaObject === "Embed" ?
                                            <div className="postEditPreviewContainer shtum--modal-thread-preview-container">
                                                <PostUrlPreview url={this.state.mediaLocation} embedData={this.state.embedData} isPostEdit={true}
                                                                updateEmbedData={this.updateEmbedData} raiseEmbedError={this.raiseEmbedError}
                                                                loadingUrlPreviewHandler={this.handleLoadingUrlPreview}
                                                />
                                                <div className="mediaRemoveButton">
                                                    <a onClick={this.removeMediaObject} href="#" className="btn btn-xs btn-danger">CLEAR</a>
                                                </div>
                                            </div>
                                            : ""}

                                        {this.state.postEditImagePreview ?
                                            <div>
                                                <div className="mediaPreview fileinput-new">
                                                    <img id="mediaPreviewImage" className="mediaPreview" src={this.state.postEditImageLocation}/>
                                                </div>
                                                <div className="mediaRemoveButton">
                                                    <a onClick={this.removeMediaObject} className="btn btn-xs btn-danger fileinput-new">CLEAR</a>
                                                </div>
                                            </div>
                                            : ''}

                                        {this.state.newImagePreview ?
                                            <div id="mediaPreview" ref="mediaPreview" className="thumbnail mediaPreview fileinput-exists" data-trigger="fileinput"/>
                                            : ''}

                                        {this.filePreview || this.state.mediaObject === "File" ?

                                            <div className="shtum--modal-file-attachment">
                                                <svg className="shtum--svg shtum--svg-lg shtum--svg_green">
                                                    <use xlinkHref="#icon-paperclip"></use>
                                                </svg>
                                                <div className="file-name" data-toggle="tooltip" title={this.state.mediaLocation}>
                                                    {this.state.displayFileName}
                                                </div>
                                            </div>
                                            : ''}

                                        {this.state.postEditImagePreview || this.state.newImagePreview || this.filePreview || this.state.mediaObject === "File" ?
                                            <div className="mediaRemoveButton">
                                                <a href="#" onClick={this.removeMediaObject} className="btn btn-xs btn-danger fileinput-exists" data-dismiss="fileinput">CLEAR</a>
                                            </div>
                                            : ''}

                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

            </Modal>
        )
            ;
    }
}

const mapStateToProps = (state) => {
    let boardAction = state.board.action
    let activeBoard = state.board.activeBoard

    if (!boardAction) return {}

    if (boardAction.type !== "CREATE_POST" && boardAction.type !== "REPLY_TO_POST" && boardAction.type !== "EDIT_POST")
        return {
            showCreatePost: boardAction.showPostEditModal
        }

    let post = boardAction.post;
    let isNewPost = boardAction.type === "CREATE_POST";
    let isReply = boardAction.type === 'REPLY_TO_POST';
    let isEdit = boardAction.type === 'EDIT_POST';
    let isComplete = boardAction.type === 'NONE';
    let boardId = boardAction.boardId;
    let threadId = boardAction.threadId;
    let postId = isNewPost ? 0 : post.postId;
    let isEditingSamePost = postId === boardAction.lastPostRepliedToId
    let lastPostRepliedToId = boardAction.lastPostRepliedToId
    let postHTML = isNewPost ? '' : post.htmlContent
    let postRaw = isNewPost ? '' : post.rawContent
    let mediaObject = isNewPost ? '' : post.mediaObject
    let mediaLocation = isNewPost ? '' : post.mediaLocation
    let originalFileName = isNewPost ? '' : post.originalFileName
    let userAlias = activeBoard.userAlias
    let memberNames = state.board.memberNames
    let embedData = isNewPost ? '' : post.embedData

    return {
        showCreatePost: boardAction.showPostEditModal,
        action: boardAction.type, // == "CREATE_POST" || boardAction.type == "REPLY_TO_POST"),
        pending: boardAction.pending,
        createPostError: boardAction.error,
        errorMessage: boardAction.errorMessage,
        post,
        isNewPost,
        isReply,
        isEdit,
        boardId,
        threadId,
        postId,
        postHTML,
        postRaw,
        isEditingSamePost,
        lastPostRepliedToId,
        userAlias,
        mediaObject,
        mediaLocation,
        originalFileName,
        memberNames,
        embedData
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        updatePost: (post, subscribe, rawData, mentionMembers, mediaContent) => {
            dispatch(updatePost(post, subscribe, rawData, mentionMembers, mediaContent));
        },
        submitPost: (boardId, threadId, parentPostId, subscribe, rawData, mentionMembers, mediaContent) => {
            dispatch(createPost(boardId, threadId, parentPostId, subscribe, rawData, mentionMembers, mediaContent));
        },
        cancelPost: () => {
            dispatch(createPostComplete());
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PostEditModal);
