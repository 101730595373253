import React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import moment from 'moment';

import {startCreateBoard, listenToBoardList, setBoardSortOrder} from '../actions/userBoardsActions';
import CreateBoard from '../components/CreateBoard';
import {log} from '../lib/logging';

import BoardMenuListItem from './BoardMenuListItem';

class BoardMenu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            boards: null,
            searchValue: ""
        }
    }

    componentDidMount = () => {
        this.props.onStartListeningToBoardList();
    }

    handleSearchChange = (event) => {
        this.setState({searchValue: event.target.value});
    }

    handleBoardClick = () => {
        window.scrollTo(0, 0)
    }

    render() {
        const {
            onCreateBoard,
            onSetBoardSortOrder,
            sortOrder,
            receivedLastPostDatesOn, //Pulse to force update
            pulseValue //TODO: fix this pulse to update dates
        } = this.props;

        var boardLinks = '';
        var boardList = this.props.boardList;
        if (boardList) {
            boardLinks = Object.keys(boardList).map(function (key, index) {
                let brd = boardList[key]
                let searchVal = this.state.searchValue

                if (searchVal) {
                    let name = boardList[key].name.toLowerCase()
                    if (!name.includes(searchVal.trim().toLowerCase()))
                        return

                    //console.log(searchVal)
                }

                return (
                    <li key={key} className="shtum--boards-menu-boards-list-item">
                        <Link key={key + '_1'} to={"/board/" + key}
                              onClick={() => this.handleBoardClick()}
                              className="list-group-item visible-xs-block visible-sm-block visible-md-block"
                              data-toggle="offcanvas"
                              data-target="#boardMenu"
                              data-canvas="body"><BoardMenuListItem board={brd}/></Link>
                        <Link key={key + '_2'} to={"/board/" + key}
                              onClick={() => window.scrollTo(0, 0)}
                              className="list-group-item visible-lg-block"><BoardMenuListItem board={brd}/></Link>
                    </li>
                )
            }, this)
        }

        return (
            <nav id="boardMenu" className="navmenu navmenu-default navmenu-fixed-left offcanvas-md shtum--boards-menu" role="navigation">
                <span className="navmenu-brand hidden">
                    <span className="boardsTitle">BOARDS</span>
                    <button className="newBoardBtn btn btn-primary btn-xs visible-xs-inline visible-sm-inline visible-md-inline"
                            title="Create New Board"
                            data-toggle="offcanvas" data-target="#boardMenu" data-canvas="body"
                            onClick={onCreateBoard}>new board</button>
                    <button className="newBoardBtn visible-lg-inline btn btn-primary btn-xs" title="Create New Board"
                            onClick={onCreateBoard}>new board</button>
                </span>
                <form>
                    <input type="text" className="form-control searchBoardBox shtum--boards-menu-search-box"
                           onChange={this.handleSearchChange} placeholder="Filter boards..."/>
                </form>
                <div className="dropdown shtum--boards-menu-sort-by">
                    <a href="#" className="dropdown-toggle" data-toggle="dropdown">Sort by <b className="caret"/>
                        <span className="sortOrder">{sortOrder}</span></a>
                    <ul className="dropdown-menu navmenu-nav" role="menu">
                        <li><a href="#" onClick={() => onSetBoardSortOrder("A-Z")}>A-Z</a></li>
                        <li><a href="#" onClick={() => onSetBoardSortOrder("Most Recent Post")}>Most Recent Post</a>
                        </li>
                        <li><a onClick={() => onSetBoardSortOrder("My Boards")}>My Boards</a></li>
                        <li><a onClick={() => onSetBoardSortOrder("Date Joined")}>Date Joined</a></li>

                    </ul>
                </div>
                <ul className="nav navmenu-nav shtum--boards-menu-boards-list">
                    {boardLinks}
                </ul>
                <ul className="list list-horizontal shtum--boards-menu-footer-list">
                    <li>
                        <a href="https://medium.com/shtum/about-shtum-2beb0f152ac0#.vmdxpc34a" target="_blank">About</a>
                    </li>
                    <li>
                        <a href="mailto:contact@shtum.com">Contact</a>
                    </li>
                </ul>
                <ul className="list list-horizontal shtum--boards-menu-copyright">
                    <li>
                        &copy; {(new Date()).getFullYear()}
                    </li>
                </ul>
                <ul className="list list-horizontal shtum--boards-menu-remember-list">
                    <li>
                        <span>HM012</span>
                    </li>
                    <li>
                        <span>HM104</span>
                    </li>
                </ul>
                <CreateBoard />
            </nav>);
    }

}

const mapStateToProps = (state) => {
    let userBoards = state.userBoards
    let global = state.global
    return {
        boardList: userBoards.boardList,
        sortOrder: userBoards.sortOrder,
        receivedLastPostDatesOn: userBoards.receivedLastPostDatesOn,
        pulseValue: global.pulseValue
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCreateBoard: (email) =>
            dispatch(startCreateBoard()),
        onStartListeningToBoardList: () =>
            dispatch(listenToBoardList()),
        onSetBoardSortOrder: (sortOrder) =>
            dispatch(setBoardSortOrder(sortOrder))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardMenu);
