import React from 'react'
import {connect} from "react-redux";

import {prepareToAcceptInvite} from "../actions/boardActions";

class AcceptInvite extends React.Component {

    constructor(props) {
        super(props)
        let inviteCode = props.params.code
        props.prepareToAcceptInvite(inviteCode)
    }


    render() {
        console.log('render AcceptInvite')

        return (
            <div className="shtum--page-center-wide">
                <i className="fa fa-cog fa-spin spinnerIcon"/>
            </div>
        );
    }
}


const mapStateToProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        prepareToAcceptInvite: (inviteCode) =>
            dispatch(prepareToAcceptInvite(inviteCode))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AcceptInvite);
