import Immutable from 'immutable'
import {push} from 'react-router-redux'
import moment from 'moment'

import {firebase} from '../services/dataSvc'
import {logEvent} from "../lib/logging";

export const initNotifications = () => {
    var _dispatch, _getState
    var notificationsRef

    return function (dispatch, getState) {
        _dispatch = dispatch;
        _getState = getState;

        loadNotificationSettings().then(() => {
            loadNotifications()
        })
    }

    function loadNotificationSettings() {

        return new Promise((resolve, reject) => {
            const uid = firebase.auth().currentUser.uid
            const notificationsSettingsRefPath = '/users/' + uid + '/notificationSettings'
            const notificationsSettingsRef = firebase.database().ref(notificationsSettingsRefPath)

            notificationsSettingsRef.once("value")
                .then(snapshot => {
                    const settings = snapshot.val()

                    //var lastNotifiedOn = settings ? settings.lastNotifiedOn || 0 : 0;
                    var notifyFrequency = settings ? settings.notifyFrequency : 15; //minutes
                    //var lastNotificationKey = settings ? settings.lastNotificationKey || "" : "";
                    var emailAllMentions = settings ? settings.emailAllMentions : true;

                    var showDesktopNotifications = false
                    if ("Notification" in window) {
                        if (Notification.permission === "granted") {
                            showDesktopNotifications = true
                        }
                        else if (Notification.permission != "denied") {
                            showDesktopNotifications = null
                        }
                    }

                    _dispatch({
                        type: 'NOTIFICATION_SETTINGS',
                        notifyFrequency,
                        emailAllMentions,
                        showDesktopNotifications
                    })

                    resolve()
                })
        })
    }


    function loadNotifications() {
        const uid = firebase.auth().currentUser.uid;
        const notificationsRefPath = '/users/' + uid + '/notifications';
        notificationsRef = firebase.database().ref(notificationsRefPath)

        notificationsRef.orderByKey()
            .limitToLast(100)
            .once('value')
            .then(function (snapshot) {

                const unsortedNotifications = snapshot.val()

                const notifications = unsortedNotifications ? sortNotifications(snapshot.val()) : {};
                let aggregateItems = unsortedNotifications ? aggregateNotifications(notifications) : {}

                _dispatch({
                    type: 'INIT_NOTIFICATIONS',
                    notifications: notifications,
                    aggregateNotifications: aggregateItems
                })

                const lastKey = unsortedNotifications ? Immutable.fromJS(Object.keys(notifications)).max() : "";

                listenForNewNotifications(lastKey)
                listenForNotificationsChanges(lastKey)
            })

    }

    function sortNotifications(notifications) {
        return Immutable.fromJS(notifications).sort(function (n1, n2) {
            let date1 = n1.get("date");
            let date2 = n2.get("date");
            return date2 - date1;
        }).toJS()

    }

    function listenForNewNotifications(lastKey) {
        notificationsRef
            .orderByKey()
            .startAt(lastKey)
            .on('child_added', function (snapshot) {
                const key = snapshot.key;
                if (lastKey == key) return;

                var notification = {}
                notification[key] = snapshot.val()

                const {merged, aggregateItems} = mergeNotification(notification);

                _dispatch({
                    type: 'NEW_NOTIFICATION',
                    notification,
                    notifications: merged,
                    aggregateNotifications: aggregateItems
                })

                //console.log(notification)

                if (notification[key].type == "newPost" || notification[key].type == "mention")
                    displayDesktopNewPostNotification(notification)
            })
    }

    function mergeNotification(notification) {
        let notifications = Immutable.fromJS(_getState().notifications.items)
        let newNotification = Immutable.fromJS(notification)
        let merged = notifications.mergeDeep(newNotification).toJS()
        let aggregateItems = aggregateNotifications(merged)
        return {merged, aggregateItems}
    }

    function aggregateNotifications(notifications) {

        const groups = Immutable.fromJS(notifications)
            .groupBy((notification, key, notfs) => {
                let seenOn = notification.get("seenOn") || 0
                let isRecent = ((moment().diff(seenOn, 'hours') <= 3) && !notification.get("acknowledged"))

                return (
                    notification.get("boardId") + "||" +
                    notification.get("threadId") + "||" +
                    notification.get("type") + "||" +
                    notification.get("seen").toString() + "||" +
                    notification.get("acknowledged").toString() + "||" +
                    notification.get("boardName") + "||" +
                    isRecent.toString()
                )

            }).map((notificationGroup, key, itr) => {
                //console.log("key = " + key)

                const keyParts = key.split("||")
                const boardId = keyParts[0]
                const threadId = keyParts[1]
                const type = keyParts[2]
                const seen = (keyParts[3] == "true")
                const acknowledged = (keyParts[4] == "true")
                const boardName = keyParts[5]
                const recent = (keyParts[6] == "true")

                //console.log(notificationSummary)

                return notificationGroup.reduce((reduction, ngroup, notificationId) => {
                        reduction.notifications.push(notificationId)
                        reduction.postIds.push(ngroup.get("newPostId"))

                        const alias = ngroup.get("fromUserAlias")
                        if (!reduction.participantAliases.includes(alias))
                            reduction.participantAliases.push(alias)

                        const date = ngroup.get("date")
                        reduction.latestDate = date > reduction.latestDate ? date : reduction.latestDate

                        return reduction
                    },
                    {
                        type,
                        participantAliases: [],
                        notifications: [],
                        postIds: [],
                        boardId,
                        boardName,
                        threadId,
                        seen,
                        acknowledged,
                        recent,
                        latestDate: 0
                    })
            })

        return groups.toJS()
    }

    //https://developer.mozilla.org/en/docs/Web/API/notification
    function displayDesktopNewPostNotification(notification) {

        // Let's check if the browser supports notifications
        if (!("Notification" in window)) {
            //alert("This browser does not support desktop notifications");
            return
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
            // If it's okay let's create a notification
            displayNewPostNotification(notification)
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    displayNewPostNotification(notification)
                }
            });
        }

    }

    function displayNewPostNotification(notf) {
        const notificationId = Object.keys(notf)[0] //There's got to be an easier way to do this!
        const notification = notf[notificationId]

        let msg
        if (notification.type == "newPost")
            msg = `${notification.fromUserAlias} replied to a thread you contributed to on ${notification.boardName}`
        else if (notification.type == "mention")
            msg = `${notification.fromUserAlias} mentioned you in a post on ${notification.boardName}`

        const options = {
            body: msg,
            icon: '/images/icon-300px.png',
            tag: notification.type
        }
        let newPostNotification = new Notification("shtum", options)
        newPostNotification.onclick = function (event) {
            window.focus()
            _dispatch(push(`/board/${notification.boardId}/${notification.threadId}?p=${notification.newPostId}`))
            _dispatch(markNotificationAcknowledged(notificationId))
            newPostNotification.close.bind(newPostNotification)
        }
        setTimeout(newPostNotification.close.bind(newPostNotification), 8000);
    }

    function listenForNotificationsChanges(lastKey) {
        notificationsRef
            .on('child_changed', function (snapshot) {
                const key = snapshot.key;
                //if (lastKey == key) return;

                var notification = {}
                notification[key] = snapshot.val()

                const {merged, aggregateItems} = mergeNotification(notification);

                _dispatch({
                    type: 'NEW_NOTIFICATION',
                    notification,
                    notifications: merged,
                    aggregateNotifications: aggregateItems
                })
            })
    }

}

export const showNotifications = () => {
    return {
        type: 'SHOW_NOTIFICATIONS'
    }
}

export const closeNotifications = () => {
    return {
        type: 'CLOSE_NOTIFICATIONS'
    }
}

export const markNotificationAcknowledged = (notificationId) => {

    var _dispatch, _getState

    return function (dispatch, getState) {
        _dispatch = dispatch;
        _getState = getState;

        //console.log("markNotificationAcknowledged = " + notificationId)

        acknowledge()
    }

    function acknowledge() {

        var uid = firebase.auth().currentUser.uid
        var updates = {}

        let notifications = _getState().notifications.aggregateItems[notificationId].notifications

        for (var n in notifications) {
            let notfId = notifications[n]
            updates['/users/' + uid + '/notifications/' + notfId + '/acknowledged'] = true
            updates['/users/' + uid + '/notifications/' + notfId + '/acknowledgedOn'] = firebase.database.ServerValue.TIMESTAMP
        }

        //console.log(updates)

        firebase.database().ref().update(updates).then(function () {
            _dispatch({
                type: 'NOTIFICATION_ACKNOWLEDGED',
                notificationId
            })

            logEvent("Notification", "acknowledged")
        })
    }
}

export const markAllNotificationAsSeen = () => {
    var _dispatch, _getState

    return function (dispatch, getState) {
        _dispatch = dispatch;
        _getState = getState;

        markAsSeen()
    }

    function markAsSeen() {
        var uid = firebase.auth().currentUser.uid
        var notifications = _getState().notifications.items
        var update = {}

        for (var notificationId in notifications) {
            if (!notifications[notificationId].seen) {
                update['/users/' + uid + '/notifications/' + notificationId + '/seen'] = true
                update['/users/' + uid + '/notifications/' + notificationId + '/seenOn'] = firebase.database.ServerValue.TIMESTAMP
            }
        }

        firebase.database().ref().update(update).then(function () {
            _dispatch({
                type: 'ALL_NOTIFICATIONS_MARKED_AS_SEEN'
            })
        })
    }
}


export const showNotificationSettings = () => {
    return {
        type: 'SHOW_NOTIFICATIONS_SETTINGS'
    }
}

export const closeNotificationSettings = () => {
    return {
        type: 'CLOSE_NOTIFICATIONS_SETTINGS'
    }
}

export const updateNotificationSettings = (notifyFrequency, emailAllMentions, showDesktopNotifications) => {
    var _dispatch, _getState

    return function (dispatch, getState) {
        _dispatch = dispatch;
        _getState = getState;

        updateSettings()
    }

    function updateSettings() {

        var uid = firebase.auth().currentUser.uid
        var update = {}

        update['/users/' + uid + '/notificationSettings/notifyFrequency'] = notifyFrequency
        update['/users/' + uid + '/notificationSettings/emailAllMentions'] = emailAllMentions
        //update['/users/' + uid + '/notificationSettings/showDesktopNotifications'] = showDesktopNotifications

        firebase.database().ref().update(update).then(function () {
            _dispatch({
                type: 'NOTIFICATIONS_SETTINGS_UPDATED'
            })
        }).catch((error) => {
            console.log("Error updating notification settings: " + JSON.stringify(error))
        })
    }
}