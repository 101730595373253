import React from 'react'
import { user, firebase } from '../services/dataSvc'
import MessageModal from './MessageModal'

export default class ChangePassword extends React.Component {
    constructor (props) {
        super(props)
        this.state = {
            newPassword: "",
            confirmPassword: "",
            isPasswordChanged: null
        }
    }
    openPasswordChangeModal = (forceChange) => {
        var modal = $('#changePasswordModal');
        modal.modal();
        this.setState({newPassword: ""});
        this.setState({confirmPassword: ""});
    }
    handleNewPasswordChange = (e) => {
        this.setState({newPassword: e.target.value});
    }
    handleConfirmPasswordEmailChange = (e) => {
        this.setState({confirmPassword: e.target.value});
    }
    changePassword = () => {
        var newPassword = this.state.newPassword.trim();
        var confirmPassword = this.state.confirmPassword.trim();

        //TODO: Handle validation properly
        if (!newPassword || !confirmPassword){
            this.refs.messageModal.showAlert({
                detail: "Please enter all fields"
            });

            return;
        }

        if(newPassword !== confirmPassword){
            this.refs.messageModal.showAlert({
                detail: "Passwords do not match"
            });

            return;
        }

        if(newPassword.length < 6){
            this.refs.messageModal.showAlert({
                detail: "Passwords must be at least 6 characters"
            });

            return;
        }

        var that = this;

        firebase.auth().currentUser.updatePassword(newPassword).
            then(
                function () {
                    that.setState({isPasswordChanged: true});
                    that.refs.messageModal.showAlert({detail: "User password changed successfully!"});
                },
                function(error) {
                    if (error) {
                        that.setState({isPasswordChanged: false});
                        switch (error.code) {
                            case "auth/weak-password":
                                that.refs.messageModal.showAlert({detail: "The password is not strong enough, please try again."});
                                break;
                            default:
                                that.refs.messageModal.showAlert({detail: "Error changing password: " + error});
                        }
                    }
                }
        );

    }
    handleIsPasswordChangedMessage = (isPasswordChanged) => {
        if(isPasswordChanged){
            $('#changePasswordModal').modal('hide');
            this.resetState();
        }
    }
    resetState = () => {
        this.setState({
            newPassword: "",
            confirmPassword: "",
            isPasswordChanged: null});
    }
    render () {
        return (
        <div className="modal fade shtum--modal" id="changePasswordModal" tabIndex="-1" role="dialog" data-backdrop="static" data-keyboard="false">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h4 className="modal-title">Change Password</h4>
                    </div>
                    <div className="modal-body errMessage">
                        <form autoComplete="off">
                            <div className="form-group">
                                <input type="password" className="form-control shtum--form-input shtum--form-input_dark-gray shtum--font-bold" id="new-password" placeholder="Enter new password"
                                       name="new-password" onChange={this.handleNewPasswordChange} value={this.state.newPassword}/>
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control shtum--form-input shtum--form-input_dark-gray shtum--font-bold" id="confirm-password" placeholder="Confirm new password"
                                       name="confirm-password" onChange={this.handleConfirmPasswordEmailChange} value={this.state.confirmPassword}/>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn shtum--btn shtum--btn_red" data-dismiss="modal">Close</button>
                        <button type="button" className="btn shtum--btn shtum--btn_green_2"onClick={this.changePassword}>Reset Password</button>
                    </div>
                </div>
            </div>
            <MessageModal ref='messageModal' handleCloseMessageModal={this.handleIsPasswordChangedMessage} stateObj={this.state.isPasswordChanged} />
        </div>);
    }
}
