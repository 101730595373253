import React from 'react'
import {connect} from 'react-redux'
import Post from './Post'
import {
    subscribeToThread, unsubscribeFromThread,
    pinThread, unpinThread
} from '../actions/threadActions'

class Thread extends React.Component {

    shouldComponentUpdate = (nextProps, nextState) => {
        return !this.props.threadsLoading;
    }

    getFirstHighlightedKey = (parentPost) => {
        if (parentPost.data && parentPost.data.isHighlighted)
            return parentPost.data.postId + '-' + parentPost.data.threadId

        for (let post of parentPost.children) {
            if (post.data.isHighlighted) {
                return post.data.postId + '-' + post.data.threadId
            } else {
                let key = this.getFirstHighlightedKey(post)
                if (key) return key
            }
        }
    }


    render() {
        const {
            threadId,
            boardId,
            posts,
            following,
            onSubscribeToThread,
            onUnsubscribeFromThread,
            onPinThread,
            onUnpinThread,
            isPinned,
            role,
        } = this.props;

        //Render can be called when Threads have been retrieved but before Posts haven't.
        if (posts.length == 0)
            return <div/>;

        let sortedThread = toThread(posts)

        let firstHighlightedKey = this.getFirstHighlightedKey(sortedThread)

        if (!sortedThread || !sortedThread.children[0])
            debugger

        const postId = sortedThread.children[0].data.postId
        const postKey = 'post-' + postId + '-' + threadId

        const isAdmin = (role === "owner" || role === "admin")

        return (
            <div>
                {/*<hr className="threadDivider"/>*/}
                <div className="dropdown threadDropDown shtum--thread-follow-post">
                    <a className="dropdown-toggle" type="button" id="dropdownMenu2"
                       data-toggle="dropdown">
                        <svg
                            className={`shtum--svg shtum--svg-lg ${following ? "shtum--svg_green" : isPinned ? "shtum--svg_green_light" : "shtum--svg_white-stroke_green"}`}>
                            {!isPinned ?
                                <use xlinkHref="#icon-flash"></use> :
                                <use xlinkHref="#icon-pin"></use>
                            }
                        </svg>
                    </a>
                    <ul className="dropdown-menu dropdown-menu-right">
                        <li>{
                            following ?
                                <a onClick={() => onUnsubscribeFromThread(boardId, threadId)}>Turn <strong>off</strong> notifications
                                    for this thread</a>
                                :
                                <a onClick={() => onSubscribeToThread(boardId, threadId)}>Turn <strong>on</strong> notifications
                                    for this thread</a>
                        }</li>
                        {
                            isAdmin ?
                                <li>
                                    {!isPinned ?
                                        <a onClick={() => onPinThread(boardId, threadId)}><strong>Pin</strong> this
                                            thread to the board</a>
                                        :
                                        <a onClick={() => onUnpinThread(boardId)}><strong>Unpin</strong> this
                                            thread from the board</a>}
                                </li>
                                : null
                        }
                    </ul>
                </div>
                <ul className="media-list post shtum--thread-post">
                    <Post key={postKey}
                          postId={postId}
                          threadId={sortedThread.children[0].data.threadId}
                          isPinnedThread={isPinned}
                          boardId={boardId}
                          author={this.props.author}
                          postData={sortedThread.children[0].data}
                          children={sortedThread.children[0].children}
                          firstHighlightedKey={firstHighlightedKey}
                    />
                </ul>
            </div>
        );
    }
}

/**** PostNode Functions
 From http://stackoverflow.com/questions/8241342/sorting-data-into-a-tree
 ****/
function PostNode(postData) {
    this.data = postData;
    this.postId = postData ? postData.postId : null;
    this.threadId = postData ? postData.threadId : null;
    this.parent = null;
    this.children = [];
}

PostNode.comparer = function (a, b) {
    return a.data.postedOn < b.data.postedOn ? 0 : 1;
}

PostNode.prototype.sortRecursive = function () {
    this.children.sort(PostNode.comparer)
    for (let i = 0, l = this.children.length; i < l; i++) {
        this.children[i].sortRecursive()
    }
    return this
}

function toThread(data) {

    let nodeById = {}, i = 0, l = data.length, node;

    nodeById[0] = new PostNode(); // that's the root node

    for (i = 0; i < l; i++) {  // make PostNode objects for each item
        nodeById[data[i].postId] = new PostNode(data[i]);
    }
    for (i = 0; i < l; i++) {  // link all PostNode objects
        node = nodeById[data[i].postId];
        node.parent = nodeById[node.data.parentPostId];
        node.parent.children.push(node);
    }
    const sortedThread = nodeById[0].sortRecursive();

    return sortedThread;
}

/**** PostNode Functions End ****/


const mapStateToProps = (state) => {
    let board = state.board;
    let activeBoard = board.activeBoard

    return {
        threadsLoading: board.activeBoard.threadsLoading || false,
        role: activeBoard.role,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubscribeToThread: (boardId, threadId, userId) =>
            dispatch(subscribeToThread(boardId, threadId, userId)),
        onUnsubscribeFromThread: (boardId, threadId, userId) =>
            dispatch(unsubscribeFromThread(boardId, threadId, userId, false)),
        onPinThread: (boardId, threadId) =>
            dispatch(pinThread(boardId, threadId)),
        onUnpinThread: (boardId) =>
            dispatch(unpinThread(boardId))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Thread);
