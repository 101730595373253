import React, {Component, PropTypes} from 'react';
import Modal from 'react-modal'
import {connect} from 'react-redux'
import Immutable from 'immutable'
import {push} from 'react-router-redux'

import {getDisplayDate} from '../lib/date'
import {closeNotifications, markNotificationAcknowledged, markAllNotificationAsSeen} from '../actions/notificationActions'

class Notifications extends Component {
    constructor(props) {
        super(props);
    };

    onClickNotification = (notificationId, boardId, threadId, postIds) => {
        this.props.onViewPostNotification(notificationId, boardId, threadId, postIds)
    }

    renderAggregateNotifications = (aggregateNotifications) => {

        const sortedNotifications = Immutable.fromJS(aggregateNotifications).sort(function (n1, n2) {
            let date1 = n1.get("latestDate");
            let date2 = n2.get("latestDate");
            return date2 - date1;
        });

        const rendered = sortedNotifications.map((notification, key, notifications) => {
            const className =
                !notification.get('seen') ? "unseenNotification" :
                    notification.get('recent') && !notification.get('acknowledged') ? "recentNotification" :
                        notification.get('acknowledged') ? "acknowledgedNotification" : "unacknowledgedNotification";
            const date = notification.get('latestDate');
            const boardId = notification.get('boardId');
            const boardName = notification.get('boardName');
            const threadId = notification.get('threadId');
            const type = notification.get('type');
            const postIds = notification.get('postIds');
            const participantAliases = notification.get("participantAliases");
            const notificationCounts = notification.get("notifications").count();
            let participants, message, symbol;

            switch (participantAliases.count()) {
                case 1:
                    participants = participantAliases.get(0)
                    break
                case 2:
                    participants = participantAliases.get(0) + " and " + participantAliases.get(1)
                    break
                case 3:
                    participants = participantAliases.get(0) + ", " + participantAliases.get(1) + " and " + participantAliases.get(2)
                    break
                default:
                    participants = participantAliases.get(0) + ", " + participantAliases.get(1) + " and " + participantAliases.count() + " others"
            }


            switch (type) {
                case "mention":
                    message = <span> mentioned you {notificationCounts > 1 ? notificationCounts + " times" : ""} in a thread on {boardName}</span>
                    symbol = <svg className="shtum--svg shtum--svg-md shtum--svg_green">
                        <use xlinkHref="#icon-quote"></use>
                    </svg>
                    break
                case "newPost":
                    message = <span> posted {notificationCounts} message{notificationCounts > 1 ? "s" : ""} to a thread you're following on {boardName}</span>
                    symbol = <svg className="shtum--svg shtum--svg-md shtum--svg_green">
                        <use xlinkHref="#icon-pencil"></use>
                    </svg>
                    break
                case "react":
                    message = <span> liked your post on {boardName}</span>
                    symbol = <svg className="shtum--svg shtum--svg-md shtum--svg_green">
                        <use xlinkHref="#icon-heart"></use>
                    </svg>
                    break
            }

            return (
                <div key={key} className={"notification " + className} onClick={() => this.onClickNotification(key, boardId, threadId, postIds)}>
                    <div className="notificationSymbol shtum--notification-symbol">
                        {symbol}
                    </div>
                    <div className="notificationText">
                        <span>{participants}{message}</span>
                        <br/>
                        <span>{getDisplayDate(date)}</span>
                    </div>
                </div>)


        }, [], this);

        return Array.from(rendered.values());
    }

    render() {
        const {
            showNotifications,
            notifications,
            aggregateNotifications,
            onCloseNotifications
        } = this.props;

        const customStyles = {
            content: {
                top: '60px'
            }
        };

        return (

            <Modal
                className="Modal__Bootstrap modal-dialog shtum--modal shtum--modal-notifications"
                closeTimeoutMS={150}
                isOpen={showNotifications}
                style={customStyles}
                shouldCloseOnOverlayClick={true}
                onRequestClose={onCloseNotifications}
                contentLabel="Notifications"
            >
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h4 className="modal-title">Notifications</h4>
                    </div>
                    <div className="modal-body notifications-body">
                        <div className="notificationContainer">
                            {this.renderAggregateNotifications(aggregateNotifications)}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={onCloseNotifications} className="btn shtum--btn shtum--btn_red">Close</button>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    let notifications = state.notifications;

    return {
        showNotifications: notifications.showNotifications,
        notifications: notifications.items,
        aggregateNotifications: notifications.aggregateItems
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseNotifications: () => {
            dispatch(closeNotifications())
            setTimeout(() => dispatch(markAllNotificationAsSeen()), 2000)

        },
        onViewPostNotification: (notificationId, boardId, threadId, postIds) => {
            dispatch(closeNotifications())
            dispatch(push(`/board/${boardId}/${threadId}?p=${postIds.join(',')}`))
            dispatch(markNotificationAcknowledged(notificationId))
            setTimeout(() => dispatch(markAllNotificationAsSeen()), 2000)
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notifications);
