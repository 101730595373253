export const shorten = (longUrl) => {

    const settings = {
        url: "https://www.googleapis.com/urlshortener/v1/url?key=AIzaSyAA0bM6S5ZaCFyyB4N8SSWFO0-DVKglmCI",
        data: `{"longUrl":"${longUrl}"}`,
        contentType: "application/json"
    }

    const shortenUrlCall = $.post(settings);

    return new Promise((resolve, reject) => {
        shortenUrlCall.done(
            (data) => {
                resolve(data.id)
            }).fail(
            function (jqXHR, textStatus, errorThrown) {
                reject({err: textStatus, longUrl})
            })
    })
}