import React, {Component, PropTypes} from 'react';
import Modal from 'react-modal'
import {connect} from 'react-redux'
import {toastr} from 'react-redux-toastr'
import Immutable from 'immutable'

import {getDisplayDate} from '../lib/date'
import {closeManageMembers, removeMember, reinstateMember, changeMemberRole} from '../actions/userBoardsActions'

class BoardMembers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: props.showBoardMembers
        }
    }

    removeMember = (member, memberAlias) => {
        const toastrConfirmOptions = {
            onOk: () => this.props.onRemoveMember(member, memberAlias),
            onCancel: () => console.log('CANCEL: clicked'),
            okText: 'YES',
            cancelText: 'NO'
        }

        toastr.confirm('Are you sure you want to remove ' + memberAlias + ' from this board?', toastrConfirmOptions)
    }

    reinstateMember = (member, memberAlias) => {
        const toastrConfirmOptions = {
            onOk: () => this.props.onReinstateMember(member, memberAlias),
            onCancel: () => console.log('CANCEL: clicked'),
            okText: 'YES',
            cancelText: 'NO'
        }

        toastr.confirm('Are you sure you want to reinstate ' + memberAlias + ' to this board?', toastrConfirmOptions)
    }

    changeRole = (event) => {
        const roleControl = event.target
        const id = roleControl.id, role = roleControl.value

        this.props.onChangeMemberRole(id, role)

        //console.log(`id=${id}, role=${role}`)
    }


    renderMembers = () => {
        const {members, changeRolePending, changeRoleMemberId} = this.props
        if (!members) return []

        const currentUserAlias = this.props.currentUserAlias

        const sortedMembers = Immutable.fromJS(members).sort(function (m1, m2) {
            const m1Alias = m1.get("alias").toLowerCase()
            const m2Alias = m2.get("alias").toLowerCase()

            return m1Alias < m2Alias ? -1 : 1
        }).toJS();

        const list = [];
        for (let member in sortedMembers) {

            const m = member, alias = members[member].alias, role = members[member].role

            if (role == "none") continue
            let selectStyle = changeRolePending && changeRoleMemberId == member ? {color: "red"} : {color: "black"};

            if (selectStyle == {color: "red"})
                console.log("PENDING!!")

            list.push(
                <tr key={member}>
                    <td>{members[member].alias}</td>
                    <td>{getDisplayDate(members[member].joinedOn)}</td>
                    <td>{getDisplayDate(members[member].lastPostOn)}</td>
                    <td>{members[member].totalPostCount}</td>
                    <td>
                        {role === "owner" ? "Owner" :
                            <select style={selectStyle} id={member} onChange={this.changeRole} defaultValue={role} disabled={changeRolePending}>
                                <option value="admin">Admin</option>
                                <option value="moderator">Moderator</option>
                                <option value="member">Member</option>
                            </select>
                        }
                    </td>
                    <td>
                        {alias == currentUserAlias || role === "owner" ? role === "owner" ? "Owner" : "You" :
                            <button className="btn btn-danger btn-xs" onClick={() => this.removeMember(m, alias)}>Remove</button>}
                    </td>
                </tr>)
        }

        list.push(
            <tr key="RemoveSubRow">
                <td className="removedSubheading" colSpan="6">Removed</td>
            </tr>)

        for (let member in sortedMembers) {

            const m = member, alias = members[member].alias, role = members[member].role

            if (role != "none") continue

            list.push(
                <tr key={member} className="removedMembers">
                    <td>{members[member].alias}</td>
                    <td>{getDisplayDate(members[member].joinedOn)}</td>
                    <td>{getDisplayDate(members[member].lastPostOn)}</td>
                    <td>{members[member].totalPostCount}</td>
                    <td>
                        <button className="btn btn-success btn-xs" onClick={() => this.reinstateMember(m, alias)}>Reinstate</button>
                    </td>
                </tr>)
        }

        return list
    }


    render() {
        const {
            showBoardMembers,
            onCloseManageMembers,
            changeRolePending
        } = this.props;

        const customStyles = {
            content: {
                top: '60px'
            }
        };

        return (

            <Modal
                className="Modal__Bootstrap modal-dialog shtum--modal shtum--modal-board-members"
                closeTimeoutMS={150}
                isOpen={showBoardMembers}
                style={customStyles}
                onRequestClose={onCloseManageMembers}
                contentLabel="Board Members"
            >
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h4 className="modal-title">Board Members</h4>
                    </div>
                    <div className="modal-body boardMembersContainerModalBody">
                        <div className="table-responsive boardMembersContainer">
                            <table className="table table-condensed table-hover">
                                <thead>
                                <tr>
                                    <th>Member</th>
                                    <th>Joined On</th>
                                    <th>Last Post</th>
                                    <th>Total Posts</th>
                                    <th>Role</th>
                                    <th>Action</th>
                                </tr>
                                </thead>
                                <tbody className="boardMembersContainer">
                                {this.renderMembers()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer errMessage">
                        <div className="form-group">
                            <button type="button" onClick={onCloseManageMembers}
                                    className="btn shtum--btn shtum--btn_red" data-dismiss="modal">
                                {changeRolePending ? <i className="fa fa-cog fa-spin"/> : ""} Close
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    let boardAction = state.board.action
    let activeBoard = state.board.activeBoard
    let changeRolePending = state.userBoards.changeRolePending
    let changeRoleMemberId = state.userBoards.changeRoleMemberId

    if (!boardAction) return {}

    return {
        showBoardMembers: boardAction.showManageMembers,
        members: boardAction.members,
        currentUserAlias: activeBoard.userAlias,
        changeRolePending,
        changeRoleMemberId
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        onCloseManageMembers: () => {
            dispatch(closeManageMembers())
        },
        onRemoveMember: (memberId, memberAlias) => {
            dispatch(removeMember(memberId, memberAlias))
        },
        onReinstateMember: (memberId, memberAlias) => {
            dispatch(reinstateMember(memberId, memberAlias))
        },
        onChangeMemberRole: (memberId, role) => {
            dispatch(changeMemberRole(memberId, role))
        }
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(BoardMembers)
