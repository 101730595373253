import React from 'react'
import Post from "./Post"
import Pagination from 'rc-pagination'
import Select from 'rc-select'

const SearchField = ({numberOfResults, posts, query, onPageSearchResults, offset = 0, limit = 10}) => {

    const renderedResults = posts ? posts.map(p => {
        const postKey = p.postId + '-' + p.threadId
        return (<Post key={postKey} postId={p.postId}
                      boardId={p.boardId} threadId={p.threadId}
                      postData={p} children={[]} isSearchResult={true}/>
        )
    }) : null

    const en_US = {
        // Options.jsx
        items_per_page: '/ page',
        jump_to: 'Goto',
        page: '',

        // Pagination.jsx
        prev_page: 'Previous Page',
        next_page: 'Next Page',
        prev_5: 'Previous 5 Pages',
        next_5: 'Next 5 Pages',
        prev_3: 'Previous 3 Pages',
        next_3: 'Next 3 Pages',
    }

    const isSmallScreen = (screen.width <= 480 || screen.height <= 800)


    const handlePageOrSizeChange = (currentPage, pageSize) => {
        //console.log(currentPage, pageSize)
        onPageSearchResults(query, currentPage, pageSize)
    }

    const current = offset > 0 ? (offset / limit) + 1 : 1
/*
    console.log(`sr current = ${current}`)
    console.log(`sr offset = ${offset}`)
    console.log(`sr limit = ${limit}`)
*/

    return (
        <div>
            <div className="shtum--post-search-header">
                <Pagination
                    selectComponentClass={Select}
                    showSizeChanger
                    simple={isSmallScreen}
                    defaultPageSize={limit}
                    pageSize={limit}
                    showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total > 100 ? 'approx' : ''} ${total} posts`}
                    total={numberOfResults}
                    locale={en_US}
                    onChange={handlePageOrSizeChange}
                    onShowSizeChange={handlePageOrSizeChange}
                    current={current}
                />
            </div>
            <ul className="media-list post shtum--thread-post shtum--post-search-results">
                {renderedResults}
            </ul>
            {numberOfResults > 0 ?
                <div className="shtum--post-search-header">
                    <Pagination
                        selectComponentClass={Select}
                        showSizeChanger
                        simple={isSmallScreen}
                        defaultPageSize={limit}
                        pageSize={limit}
                        showTotal={(total, range) => `${range[0]} - ${range[1]} of ${total} items`}
                        total={numberOfResults}
                        locale={en_US}
                        onChange={handlePageOrSizeChange}
                        onShowSizeChange={handlePageOrSizeChange}
                        current={current}
                    />
                </div> : ''}
        </div>)
}

export default SearchField;
