import React from 'react';
import {getDisplayDate} from '../lib/date';

const BoardMenuListItem = ({board}) => {

    let boardRole = board.role == "owner" ? "shtum--board-menu-item_owner" : "shtum--board-menu-item";
    const boardOwnerIcon = (
        <svg className="shtum--svg shtum--svg-sm shtum--svg_orange shtum--svg-board_owner" title="You own this board">
            <use xlinkHref="#icon-fingerprint"></use>
        </svg>
    );
    let boardOwnerName = (
        <span>
            <span> {getDisplayDate(board.lastPostOn)} by </span>
            <span className="shtum--board-menu-last-post-by">@{board.lastPostByAlias}</span>
        </span>
    );

    return(
        <div>
            <span className={boardRole}>{board.name}</span>
            <div className="shtum--board-menu-last-post">
                Last post: { board.lastPostOn ? boardOwnerName : ' Unknown' }
                { boardRole == "shtum--board-menu-item_owner" ? boardOwnerIcon : "" }
            </div>
        </div>
    );
}

export default BoardMenuListItem;
