import React from 'react'

import {connect} from 'react-redux'
import {loggedOut} from '../actions/authActions'
import {processInvite} from '../actions/boardActions'
import {logOut} from '../services/authSvc'
import {firebase} from '../services/dataSvc'

import BoardMenu from './BoardMenu'
import Navigation from './Navigation'
import JoinBoard from './JoinBoard'

class App extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            // from the path '/boards/:boardId'
            boardId: props.params.boardId,

        }

        const {
            invitePending,
            processPendingInvite,
            inviteCode
        } = props;

        if (invitePending)
            processPendingInvite(inviteCode)
    }

    render() {

        const {
            onLogOut
        } = this.props;

        var user = firebase.auth().currentUser;
        if (!user) {
            return (
                <div className="container pageCenter">
                    <i className="fa fa-cog fa-spin spinnerIcon"/>
                </div>
            )
        }

        return (
            <div>
                <Navigation onLogOut={onLogOut}/>
                <div className="appFrame shtum--app-container">
                    <BoardMenu />
                    <JoinBoard/>
                    {this.props.children}
                </div>
            </div>)
            ;
    }
}

function checkIfInviteIsPending(state) {
    let board = state.board

    if (!board.invite || !board.invite.invitePending)
        return ({isInvitePending: false})

    return (
    {
        isInvitePending: true,
        inviteCode: board.invite.inviteCode
    })
}


const mapStateToProps = (state) => {
    let auth = state.auth
    var invite = checkIfInviteIsPending(state)
    return {
        email: auth.email,
        uid: auth.uid,
        invitePending: invite.isInvitePending,
        inviteCode: invite.isInvitePending ? invite.inviteCode : null
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onLogOut: () => {
            logOut()
            dispatch(loggedOut())
        },
        processPendingInvite: (inviteCode) => {
            dispatch(processInvite(inviteCode))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
