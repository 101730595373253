import React, {Component, PropTypes} from 'react';
import Modal from 'react-modal'
import {reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux'

import {closeNotificationSettings, updateNotificationSettings} from '../actions/notificationActions'

const validate = values => {
    return {}
};


class NotificationSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: props.showNotificationSettings
        }
    };

    render() {
        const {
            // fields: {notifyFrequency, emailAllMentions, showDesktopNotifications},
            showNotificationSettings,
            onNotificationSettingsComplete,
            onNotificationSettings,
            pending,
            NotificationSettingsError,
            errorMessage,
            submitting,
            handleSubmit
        } = this.props;

        const customStyles = {
            content: {
                top: '60px'
            }
        };

        console.log({showNotificationSettings})

        return (

            <Modal
                className="Modal__Bootstrap modal-dialog shtum--modal shtum--modal-notification-settings"
                closeTimeoutMS={150}
                isOpen={showNotificationSettings}
                onRequestClose={onNotificationSettingsComplete}
                style={customStyles}
                contentLabel="Notification Settings"
            >
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h4 className="modal-title">Notification Settings</h4>
                    </div>
                    <div className="modal-body">
                        <form className="form-horizontal"
                              onSubmit={handleSubmit(data => onNotificationSettings(data.notifyFrequency, data.emailAllMentions, data.showDesktopNotifications))}>
                            <div className="modal-body">
                                <Field
                                    name="notifyFrequency"
                                    component={field => {
                                        return (
                                            <div className="form-group">
                                                <div className="col-sm-3">
                                                    <label htmlFor="notifyFrequency" className="control-label">Email
                                                        notifications</label>
                                                </div>
                                                <div className="col-sm-9">
                                                    <div className="notifyFrequency">
                                                        <select className="form-control" {...field.input}
                                                                id="notifyFrequency" name="notifyFrequency">
                                                            <option value="0">Immediately</option>
                                                            <option value="15">Maximum every 15 minutes</option>
                                                            <option value="30">Maximum every 30 minutes</option>
                                                            <option value="60">Maximum every hour</option>
                                                            <option value="120">Maximum every 2 hours</option>
                                                            <option value="180">Maximum every 3 hours</option>
                                                            <option value="360">Maximum every 6 hours</option>
                                                            <option value="720">Maximum every 12 hours</option>
                                                            <option value="1440">Maximum once a day</option>
                                                            <option value="-1">Never</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>)
                                    }}
                                    type="text"/>
                                <Field
                                    name="emailAllMentions"
                                    component={field => {
                                        return (
                                            <div className="form-group">
                                                <div className="col-sm-9 col-sm-offset-3">
                                                    <div className="checkbox shtum--form-login-checkbox">
                                                        <div className="shtum--checkbox">
                                                            <input type="checkbox" {...field.input}
                                                                   id="emailAllMentions"
                                                                   name="emailAllMentions" value="emailAllMentions"/>
                                                            <span></span>
                                                        </div>
                                                        <label htmlFor="emailAllMentions">Email mentions
                                                            immediately</label>
                                                    </div>
                                                </div>
                                            </div>)
                                    }}
                                    type="checkbox"/>
                                <Field
                                    name="showDesktopNotifications"
                                    component={field => {
                                        return (
                                            <div className="form-group">
                                                <div className="col-sm-9 col-sm-offset-3">
                                                    <div className="checkbox shtum--form-login-checkbox">
                                                        <div className="shtum--checkbox">
                                                            <input disabled={true} type="checkbox"
                                                                   // ref="showDesktopNotifications"
                                                                   {...field.input}
                                                                   id="showDesktopNotifications"
                                                                   name="showDesktopNotifications"/>
                                                            <span></span>
                                                        </div>
                                                        <label htmlFor="showDesktopNotifications">Show desktop
                                                            notifications <svg
                                                                className="shtum--svg shtum--svg-sm shtum--svg_green"
                                                                title="These settings can only be controlled via your browser">
                                                                <use xlinkHref="#icon-info-with-circle"></use>
                                                            </svg></label>
                                                    </div>
                                                </div>
                                            </div>)
                                    }}
                                    type="checkbox"/>
                            </div>
                            <div className="modal-footer errMessage">
                                <div className="form-group">
                                    <div className="col-sm-12">
                                        <button type="button" className="btn shtum--btn shtum--btn_red"
                                                data-dismiss="modal" onClick={onNotificationSettingsComplete}>
                                            Cancel
                                        </button>
                                        <button type="submit" className="btn shtum--btn shtum--btn_green_2"
                                                disabled={submitting || pending}>
                                            Update
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

NotificationSettings = reduxForm({
    form: 'NotificationSettingsForm',
    // fields: ['email', 'password'],
    validate
})(NotificationSettings);

const mapStateToProps = (state) => {
    let notifications = state.notifications;
    return {
        showNotificationSettings: notifications.showNotificationSettings,
        initialValues: {
            notifyFrequency: notifications.notifyFrequency || 15,
            emailAllMentions: notifications.emailAllMentions || true,
            showDesktopNotifications: notifications.showDesktopNotifications || true
        }
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        onNotificationSettingsComplete: () => {
            dispatch(closeNotificationSettings())
        },
        onNotificationSettings: (notifyFrequency, emailAllMentions, showDesktopNotifications) => {
            dispatch(updateNotificationSettings(notifyFrequency, emailAllMentions, showDesktopNotifications))
        }
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotificationSettings);


// //NotificationSettings =
// export default reduxForm({
//         form: 'NotificationSettingsForm',
//         // fields: ['notifyFrequency', 'emailAllMentions', 'showDesktopNotifications']
//     },
//     state => ({
//         showNotificationSettings: state.notifications.showNotificationSettings,
//         initialValues: {
//             notifyFrequency: state.notifications.notifyFrequency || 15,
//             emailAllMentions: state.notifications.emailAllMentions || true,
//             showDesktopNotifications: state.notifications.showDesktopNotifications || true
//         }
//     }),
//     dispatch => ({
//         onNotificationSettingsComplete: () => {
//             dispatch(closeNotificationSettings())
//         },
//         onNotificationSettings: (notifyFrequency, emailAllMentions, showDesktopNotifications) => {
//             dispatch(updateNotificationSettings(notifyFrequency, emailAllMentions, showDesktopNotifications))
//         }
//     })
// )(NotificationSettings)
