import {push} from 'react-router-redux'
import toast from '../lib/toast'
import {getPostByPostId} from '../lib/postUtils'
import {log} from '../lib/logging'
import {firebase} from '../services/dataSvc'
import {initBoard} from './boardActions'

export const listenToThread = (boardId, threadId, createdOn, startedBy, onPostsLoaded) => {

    let _dispatch, _getState;

    return function (dispatch, getState) {
        _dispatch = dispatch;
        _getState = getState;

        const postsRef = firebase.database().ref('boards/' + boardId + '/posts/' + threadId);
        loadExistingThreadPosts(postsRef);
    }

    function loadExistingThreadPosts(postsRef) {
        postsRef
            .once('value', function (snapshot) {

                const posts = snapshot.val()
                let lastPostId
                const postCollection = Object.keys(posts).map(function (postId) {
                    const post = posts[postId]

                    lastPostId = postId;
                    return getPostData(post, postId);
                })

                _dispatch({
                    type: 'POSTS_LOADED',
                    posts: postCollection
                })

                if (onPostsLoaded)
                    onPostsLoaded()

                listenForNewPosts(postsRef, lastPostId)
                listenForEditedPosts(postsRef)
                listenForUserThreadNotificationSettings()
                dispatchThreadListenersAttached(postsRef)
            })
    }

    function listenForNewPosts(postsRef, lastPostId) {

        postsRef
            .orderByKey()
            .startAt(lastPostId)
            .on('child_added', function (snapshot) {

                const postId = snapshot.key;
                if (lastPostId === postId) return;

                log("New post received: " + postId);

                const postData = snapshot.val();

                const dispatchMessage = {
                    type: 'POST_RECEIVED',
                    ...getPostData(postData, postId)
                }
                _dispatch(dispatchMessage)

            })
    }

    function listenForEditedPosts(postsRef) {
        postsRef
            .on('child_changed', function (snapshot) {

                const postId = snapshot.key;

                log("Updated post received: " + postId);

                const postData = snapshot.val();

                const dispatchMessage = {
                    type: 'UPDATED_POST_RECEIVED',
                    ...getPostData(postData, postId)
                }
                _dispatch(dispatchMessage)

            })
    }

    function getPostData(post, postId) {
        return {
            boardId: post.boardId,
            postId: postId,
            threadId: post.threadId,
            parentPostId: post.parentPostId,
            postedByAlias: post.postedByAlias,
            postedById: post.postedById,
            updatedOn: post.updatedOn,
            updatedById: post.updatedById,
            postedOn: post.postedOn,
            originallyPostedById: post.originallyPostedById,
            deletedById: post.deletedById,
            isDeleted: post.isDeleted,
            htmlContent: post.htmlContent,
            rawContent: post.rawContent,
            mediaObject: post.mediaObject,
            mediaLocation: post.mediaLocation,
            originalFileName: post.originalFileName,
            reactions: post.reactions,
            embedData: post.embedData,
            imageWidth: post.imageWidth,
            imageHeight: post.imageHeight
        }
    }

    function listenForUserThreadNotificationSettings() {
        const currentUserId = firebase.auth().currentUser.uid;
        const userThreadNotificationsRefPath = '/boards/' + boardId + '/threads/' + threadId + '/participants/' + currentUserId;
        const userThreadNotificationsRef = firebase.database().ref(userThreadNotificationsRefPath);

        userThreadNotificationsRef.on('value', function (snapshot) {
            const value = snapshot.val()
            _dispatch({
                    type: 'THREAD_NOTIFICATION_SETTING',
                    boardId,
                    threadId,
                    userId: currentUserId,
                    value,
                    userThreadNotificationsRef
                }
            )
        })
    }

    function dispatchThreadListenersAttached(postsRef) {
        _dispatch({
            type: 'THREAD_LISTENER_ATTACHED',
            boardId,
            threadId,
            createdOn,
            startedBy,
            postsRef
        })
    }

}

export const startCreatePost = (isReply, boardId, replyToPostId) => {
    return function (dispatch, getState) {

        const action = isReply ? {
            type: 'REPLY_TO_POST',
            replyToPost: getPostByPostId(getState().board.posts, replyToPostId)[0],
            pending: false
        } : {
            type: 'CREATE_POST',
            pending: false,
            boardId
        }

        dispatch(action)
    }
};

export const editPost = (boardId, editPostId) => {
    return function (dispatch, getState) {

        const posts = getState().board.posts;
        const post = getPostByPostId(posts, editPostId)[0];

        const action = {
            type: 'EDIT_POST',
            editPost: post,
            pending: false
        };

        dispatch(action);
    }
};

export const createPostComplete = () => {
    return {
        type: 'CREATE_POST_COMPLETE'
    }
}

export const unsubscribeFromThread = (boardId, threadId, redirectToHome) => {
    return function (dispatch, getState) {

        toggleThreadNotification(boardId, threadId, false,
            function () {
                toast.success("Unsubscribed from notifications for the thread")
                if (redirectToHome)
                    dispatch(push('/home'))
            },
            function (error) {
                if (!firebase.auth().currentUser)
                    toast.error("Failed to unsubscribe because you're not logged in. Please login and try again")
                else
                    console.log('unsubscribeFromThread failed! ' + error);
            })
    }
}

export const subscribeToThread = (boardId, threadId) => {
    return function (dispatch, getState) {

        toggleThreadNotification(boardId, threadId, true,
            function () {
                toast.success("Subscribed to notifications for this thread")
                console.log('subscribeToThread succeeded')
            },
            function (error) {
                console.log('subscribeToThread failed! ' + error);
            })
    }
}

const toggleThreadNotification = (boardId, threadId, toggleState, next, err) => {

    const userId = firebase.auth().currentUser.uid

    const threadNotificationsRefPath = '/boards/' + boardId + '/threads/' + threadId + '/participants/';
    const threadNotificationsRef = firebase.database().ref(threadNotificationsRefPath);

    const data = {};
    data[userId] = toggleState;

    threadNotificationsRef.update(data)
        .then(next())
        .catch(function (error) {
                err(error)
            }
        )

}

export const pinThread = (boardId, threadId) => {
    return function (dispatch, getState) {

        setPinnedThread(boardId, threadId, dispatch,
            function () {
                toast.success(`Thread pinned to board`)
                console.log(`Thread ${threadId} pinned to board`)
            },
            function (error) {
                console.log('Pinning thread failed! ' + error);
            })
    }
}

export const unpinThread = (boardId) => {
    return function (dispatch, getState) {

        setPinnedThread(boardId, null, dispatch,
            function () {
                toast.success("Thread unpinned from board")
                console.log('Unpinning thread succeeded')
            },
            function (error) {
                console.log('Unpinning thread failed! ' + error);
            })
    }
}

const setPinnedThread = (boardId, threadId, dispatch, next, err) => {

    const threadNotificationsRefPath = '/boards/' + boardId + '/info/';
    const threadNotificationsRef = firebase.database().ref(threadNotificationsRefPath);
    const data = {pinnedThreadId: threadId}

    threadNotificationsRef.update(data)
        .then(() => {
            next();
            dispatch(initBoard(boardId));
            // dispatch(push('/board/' + boardId))
        })
        .catch(function (error) {
                err(error)
            }
        )

}
