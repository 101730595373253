import React from 'react'
import {connect} from 'react-redux'
import {push} from 'react-router-redux'
import ReactTooltip from 'react-tooltip'
import PostUrlPreview from './PostUrlPreview'
import {startCreatePost, editPost} from '../actions/threadActions'
import {deletePost, reactToPost, unreactToPost} from '../actions/postActions'
import {getDisplayDate} from '../lib/date'
import {newDisplayPostSecs} from '../config'
import {closeSearch} from "../actions/boardActions";


class Post extends React.Component {
    constructor(props) {
        super(props);

        const {threadId, postId, postData} = props
        const diffSecs = this.postedOnDiffSecsToNow(postData)
        const isNew = diffSecs < newDisplayPostSecs
        const isJustPosted = diffSecs < 10
        const isByCurrentUser = props.currentUserId === postData.postedById

        this.state = {
            postKey: postId + '-' + threadId,
            postedOnDiff: diffSecs,
            isNew,
            prePulseValue: 0,
            postIndicator: props.isPinnedThread ? '+' : '-',
            childPostsCollapsed: !!props.isPinnedThread,
            isJustPosted,
            isByCurrentUser,
            mobileContainerShow: false,
            childCount: 0
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        return !this.props.threadsLoading;
    }

    postedOnDiffSecsToNow = (postData) => {
        const postedOnDate = postData.postedOn / 1000
        const now = Date.now().valueOf() / 1000
        return (now - postedOnDate)
    }

    static convertEpochDate = (epochDate) => {
        const myDate = new Date(epochDate * 1000)
        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour12: false,
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        }
        return myDate.toLocaleString(navigator.language, options)
    }

    renderChildren = (childPosts, boardId, userAlias, firstHighlightedKey) => {
        const posts = [];
        childPosts.forEach(function (post) {
            const postKey = post.data.postId + '-' + post.data.threadId
            posts.push(<PostWrapper key={postKey} postId={post.data.postId}
                                    boardId={boardId} threadId={post.data.threadId}
                                    postData={post.data} children={post.children}
                                    firstHighlightedKey={firstHighlightedKey}
            />)
        })

        return (
            <ul>
                {posts}
            </ul>
        )
    }

    componentDidMount = () => {
        const postId = this.state.postKey;

        const bgClass = this.postBgClass(this.props.postData)
        if (bgClass === 'displayNone') {
            $("#" + postId).toggle('highlight')
        }

        if (this.state.isNew) {
            const isNewId = '#isNew-' + postId
            const fadePercent = 1 - (this.state.postedOnDiff / newDisplayPostSecs)
            $(isNewId).fadeTo('slow', fadePercent, () => {
                $(isNewId).fadeOut((newDisplayPostSecs - this.state.postedOnDiff) * 1000)
            })
        }

        const childPostsId = `${postId}-Children`
        const postIndicatorId = `${postId}-PostIndicator`
        const postResponseId = `${postId}-PostResponse`

        this.setState({childPostsId, postIndicatorId, postResponseId})

        //There must be a way to avoid this!
        const that = this

        $(document).ready(function () {
            const childPosts = $(`#${childPostsId}`)

            //console.log($(childPosts[0].id));//.data('events') )

            if (that.props.isPinnedThread) {
                that.countChildren(that.props.children);
            }

            childPosts.on('hide.bs.collapse', function (event) {
                event.stopPropagation();

                that.setState({childCount: 0})
                that.countChildren(that.props.children)

                that.setState({postIndicator: '+', childPostsCollapsed: true})
            })

            childPosts.on('show.bs.collapse', function (event) {
                event.stopPropagation();
                that.setState({postIndicator: '-', childPostsCollapsed: false})
            })

            let isFirstHighlightedPost = false
            if (that.state.postKey === that.props.firstHighlightedKey)
                isFirstHighlightedPost = true

            if ((that.state.isJustPosted && that.state.isByCurrentUser) || isFirstHighlightedPost) {
                const postKey = that.state.postKey

                $('html, body')
                    .animate({
                        scrollTop: $(`#${postKey}`).offset().top - 100
                    }, 1000)
            }
        })
    }

    reply = (boardId, postId) => {
        this.props.replyToPost(boardId, postId)
    }

    edit = (boardId, postId) => {
        this.props.editPost(boardId, postId)
    }

    deletePost = (post) => {
        this.props.deletePost(post)
    }

    createMarkup = (src) => {
        return {__html: src}
    };

    postBgClass = () => {
        return 'bgPost'// this.state.postedOnDiff >= 15 ? 'bgPost' : 'displayNone'
    }

    renderLikedBy = (postId, likeReaction) => {
        const likes = [], reactors = likeReaction.reactors
        for (let i = 0; i < reactors.length; i++) {
            if (i < 10)
                likes.push(<div key={postId + "-" + i}>{reactors[i]}</div>)
            else
                likes.push("...")
        }

        return likes
    }

    countChildren = (children) => {
        children.forEach((c) => {
            const childCount = this.state.childCount + 1
            this.setState({childCount})

            this.countChildren(c.children)
        })
    }

    handleResultsClick = () => {
        if (!this.props.isSearchResult) return

        const {boardId, threadId, postId, viewSearchResult} = this.props
        viewSearchResult(boardId, threadId, postId)
    }

    render() {

        //Just referencing the pulse value updates the Post (for the displayDate) even if the value isn't used
        const {
            postData, boardId, role,
            postId, children, userAlias,
            pulseValue, likePost, unlikePost,
            firstHighlightedKey, isSearchResult,
            isPinnedThread
        } = this.props

        const postKey = this.state.postKey
        const postClass = '#' + postKey
        const imgId = 'img' + postKey

        const bgClass = this.postBgClass()
        const displayDate = getDisplayDate(postData.postedOn)

        const hasReactions = (postData.reactions && postData.reactions !== "none" && postData.reactions["Like"]) //Can remove this
        const userHasReacted = !!(hasReactions && postData.reactions["Like"].reactors.indexOf(userAlias) >= 0)

        const reactionsClass = hasReactions ? (userHasReacted ? "react-action-including-user" : "react-action") : "react-action-none"

        const postIndicatorClassName = postData.parentId === 0 ? "firstPostIndicator" : "postIndicator shtum--thread-container-post-indicator"
        const postIndicatorAltText = children.length > 0 ? "Collapse" : ""
        //const childPostsId = postId + "Children"

        const replyCountTerm = this.state.childCount === 1 ? "reply" : "replies"
        const isModerator = (role === "owner" || role === "admin" || role === "moderator")

        const isEdited = postData.updatedOn, isDeleted = postData.isDeleted
        let isModerated = false, editLabel = ""

        if (isEdited) {
            isModerated = postData.updatedById && (postData.updatedById !== postData.postedById)
            editLabel = isModerated ? "moderated" : "edited"
        }

        if (isDeleted) {
            isModerated = postData.deletedById !== postData.originallyPostedById
            editLabel = isModerated ? "moderated" : "deleted"
        }

        let containerState = (this.state.mobileContainerShow && "show") || "";

        const isHighlightedClass = postData.isHighlighted ? "highlight-post" : "";
        const isPinnedClass = isPinnedThread ? "pinned-thread" : "";

        let maxImageHeight = 0, imageHeight = null, imageWidth = null
        if (postData.mediaObject === "Image" && postData.imageHeight) {
            maxImageHeight = Math.min(250, screen.height / 5)
            imageHeight = Math.min(postData.imageHeight, maxImageHeight)
            imageWidth = `${(imageHeight / postData.imageHeight) * postData.imageWidth}px`
            imageHeight += 'px'
        }

        const displayAlias = `${postData.postedByAlias !== "N\A" ? "@" : ""}${postData.postedByAlias}`

        return (
            <li className={"media post-media shtum--thread-post-container " + bgClass} id={postKey}
                onClick={this.handleResultsClick}>

                <div className="media-right media-top post-media-right" title={postIndicatorAltText}>
                    {children.length > 0 ?
                        <a className="postIndicatorLink" id={this.state.postIndicatorId}
                           data-toggle="collapse" href={`#${this.state.childPostsId}`}>
                            <span className={`${postIndicatorClassName}`}>{this.state.postIndicator}</span>
                        </a> :
                        <span className={postIndicatorClassName}></span>
                    }
                </div>

                <div className={"media-body post-media-body shtum--thread-post-media-body"}>

                    <div className={"postBody " + isHighlightedClass + " " + isPinnedClass}
                         dangerouslySetInnerHTML={this.createMarkup(postData.htmlContent)}/>

                    {postData.mediaObject === "Image" || postData.mediaObject === "YouTube" || postData.mediaObject === "Embed" || postData.mediaObject === "File" ?
                        <div className="mediaPreview">
                            {postData.mediaObject === "Image" ?
                                <a href={postData.mediaLocation} data-lightbox={imgId}>
                                    <img id={imgId} className="mediaPreview imageZoom" width={imageWidth}
                                         height={imageHeight}
                                         src={postData.mediaLocation}/>
                                </a> : ''}
                            {postData.mediaObject === "Embed" ?
                                <div className="embedInBoardFrame">
                                    <PostUrlPreview embedData={postData.embedData}/>
                                </div> : ''}
                            {postData.mediaObject === "File" ?
                                <a href={postData.mediaLocation} target="_new" download>
                                    <div className="shtum--post-file-attachment">
                                        <div className="file-icon">
                                            <svg className="shtum--svg shtum--svg-md shtum--svg_green">
                                                <use xlinkHref="#icon-paperclip"></use>
                                            </svg>
                                        </div>
                                        <div className="file-name" data-toggle="tooltip"
                                             title={postData.originalFileName}>
                                            {postData.originalFileName}
                                        </div>
                                        <div className="download-message">
                                            Click to download
                                        </div>
                                    </div>
                                </a> : ''}
                            {postData.mediaObject === "YouTube" ?
                                <div className="embed-responsive embed-responsive-16by9 youTubePostEditFrame">
                                    <iframe className="embed-responsive-item" src={postData.mediaLocation}></iframe>
                                </div> : ''}
                        </div> : ''}

                    {isSearchResult && postData.originalFileName ?
                        <div className="mediaPreview">
                            <div className="shtum--post-file-attachment">
                                <div className="file-icon">
                                    <svg className="shtum--svg shtum--svg-md shtum--svg_green">
                                        <use xlinkHref="#icon-paperclip"></use>
                                    </svg>
                                </div>
                                <div className="file-name" data-toggle="tooltip" title={postData.originalFileName}>
                                    {postData.originalFileName}
                                </div>
                            </div>
                        </div> : ''}

                    <div className="postFooter shtum--thread-post-footer">
                        <ul className="list list-horizontal shtum--thread-post-footer-list shtum--thread-post-footer-list-info">
                            <li><span className="shtum--thread-post-footer-posted-by">{displayAlias}</span>
                            </li>
                            <li><span
                                className="postDisplayDate shtum--thread-post-footer-posted-date"> {displayDate} </span>
                            </li>
                        </ul>
                        {!isSearchResult ?
                            <ul className="list list-horizontal shtum--thread-post-footer-list shtum--thread-post-footer-list-links">
                                {(userAlias === postData.postedByAlias || isModerator) && !isDeleted ?
                                    <li className="shtum--thread-post-footer-link shtum--thread-post-footer-link_green shtum--btn-svg hidden-xs">
                                        <a onClick={() => this.edit(boardId, postId)} title="Edit Post">
                                            <svg className="shtum--svg shtum--svg-xs shtum--svg_green">
                                                <use xlinkHref="#icon-pencil"></use>
                                            </svg>
                                            <span>Edit</span>
                                        </a>
                                    </li> : null}
                                {(userAlias === postData.postedByAlias || isModerator) && !isDeleted ?
                                    <li className="shtum--thread-post-footer-link shtum--thread-post-footer-link_green shtum--btn-svg hidden-xs">
                                        <a title="Delete Post"
                                           onClick={(e) => {
                                               e.preventDefault();
                                               this.deletePost(postData)
                                           }}>
                                            <svg className="shtum--svg shtum--svg-xs shtum--svg_green">
                                                <use xlinkHref="#icon-erase"></use>
                                            </svg>
                                            <span>Delete</span>
                                        </a>
                                    </li> : null}
                                {(userAlias === postData.postedByAlias || isModerator) && !isDeleted ?
                                    <li className="shtum--thread-post-footer-link shtum--thread-post-footer-link_green shtum--btn-svg hidden-sm hidden-md hidden-lg">
                                        <a className="shtum--thread-post-mobile-container-btn"
                                           onClick={() => this.setState({mobileContainerShow: !this.state.mobileContainerShow})}>
                                            <svg className="shtum--svg shtum--svg-xs shtum--svg_green">
                                                <use xlinkHref="#icon-dots-three-horizontal"></use>
                                            </svg>
                                        </a>
                                    </li> : null}
                                <li className="shtum--thread-post-footer-link shtum--thread-post-footer-link_green shtum--btn-svg">
                                    <a onClick={() => this.reply(boardId, postId)} title="Reply">
                                        <svg className="shtum--svg shtum--svg-xs shtum--svg_green">
                                            <use xlinkHref="#icon-reply"></use>
                                        </svg>
                                        <span className="hidden-xs">Reply</span>
                                    </a>
                                </li>
                                <li className={`shtum--thread-post-footer-link shtum--btn-svg ${reactionsClass}`}>
                                    <a title="Like"
                                       onClick={(e) => {
                                           e.preventDefault();
                                           (userHasReacted) ? unlikePost(userAlias, postData) : likePost(userAlias, postData);
                                       }}>
                                        <svg className="shtum--svg shtum--svg-xs shtum--svg_green">
                                            <use xlinkHref="#icon-heart"></use>
                                        </svg>
                                    </a>
                                    {hasReactions ? <span className="reactionCount" data-tip
                                                          data-for={"likedByTooltip-" + postId}>{"" + postData.reactions["Like"].count}</span> : ""}
                                </li>
                            </ul> : ''}
                        <ul className="list list-horizontal shtum--thread-post-footer-list">
                            {isEdited && !isDeleted ? <li><span
                                className="label label-success shtum--thread-post-label shtum--thread-post-label-edit">{editLabel}</span>
                            </li> : null}
                            {isDeleted ? <li><span
                                className="label label-danger shtum--thread-post-label shtum--thread-post-label-delete">{editLabel}</span>
                            </li> : null}
                            {this.state.isNew ?
                                <li id={'isNew-' + postKey}>
                                    <span
                                        className="label label-warning shtum--thread-post-label shtum--thread-post-label-new">New</span>
                                </li> : null}
                        </ul>

                        {(userAlias === postData.postedByAlias || isModerator) && !isDeleted && !isSearchResult ?
                            <div className={`shtum--thread-post-footer-mobile-container ${containerState}`}>
                                <button className="shtum--close"
                                        onClick={() => this.setState({mobileContainerShow: false})}>
                                    <svg className="shtum--svg shtum--svg-md shtum--svg_green">
                                        <use xlinkHref="#icon-cross"></use>
                                    </svg>
                                </button>
                                <ul className="list list-horizontal shtum--thread-post-footer-list shtum--thread-post-footer-list-links">
                                    <li className="shtum--thread-post-footer-link shtum--thread-post-footer-link_green shtum--btn-svg">
                                        <a onClick={() => this.edit(boardId, postId)} title="Edit Post">
                                            <svg className="shtum--svg shtum--svg-sm shtum--svg_green">
                                                <use xlinkHref="#icon-pencil"></use>
                                            </svg>
                                            <span>Edit</span>
                                        </a>
                                    </li>
                                    <li className="shtum--thread-post-footer-link shtum--thread-post-footer-link_green shtum--btn-svg">
                                        <a title="Delete Post"
                                           onClick={(e) => {
                                               e.preventDefault();
                                               this.deletePost(postData)
                                           }}>
                                            <svg className="shtum--svg shtum--svg-sm shtum--svg_green">
                                                <use xlinkHref="#icon-erase"></use>
                                            </svg>
                                            <span>Delete</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                            : null}

                    </div>
                </div>
                {hasReactions ?
                    <ReactTooltip id={"likedByTooltip-" + postId} effect="solid" multiline={true}
                                  getContent={() => this.renderLikedBy(postId, postData.reactions["Like"])}/>
                    : ""}
                {this.state.childCount > 0 ?
                    <div
                        className={"morePostsIndicatorContainer " + (this.state.childPostsCollapsed ? "" : "displayNone")}>
                        <a data-toggle="collapse" href={`#${this.state.childPostsId}`}><span
                            className="morePostsIndicator">{`(${this.state.childCount} ${replyCountTerm}...)`}</span></a>
                    </div>
                    : ""}
                <div className={"collapse " + (isPinnedThread ? '' : 'in')} id={this.state.childPostsId}>
                    {this.renderChildren(children, boardId, userAlias, firstHighlightedKey)}
                </div>
            </li>
        )
    }
}


const mapStateToProps = (state) => {
    let activeBoard = state.board.activeBoard
    let global = state.global
    let auth = state.auth
    let board = state.board;

    return {
        userAlias: activeBoard.userAlias,
        pulseValue: global.pulseValue,
        role: activeBoard.role,
        currentUserId: auth.uid,
        threadsLoading: board.activeBoard.threadsLoading || false
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        replyToPost: (boardId, postId) => {
            dispatch(startCreatePost(true, boardId, postId))
        },
        editPost: (boardId, postId) => {
            dispatch(editPost(boardId, postId))
        },
        deletePost: (post) => {
            if (window.confirm('Are you sure you want to delete this post?'))
                dispatch(deletePost(post))
        },
        likePost: (userAlias, post) => {
            dispatch(reactToPost("Like", userAlias, post))
        },
        unlikePost: (userAlias, post) => {
            dispatch(unreactToPost("Like", userAlias, post))
            //console.log("Unliked!" + userAlias + " - " + post.postId)
        },
        viewSearchResult: (boardId, threadId, postId) => {
            dispatch(closeSearch())
            dispatch(push(`/board/${boardId}/${threadId}?p=${postId}&s=1`))
        }
    }
};

const PostWrapper = connect(
    mapStateToProps,
    mapDispatchToProps
)(Post)

export default PostWrapper;
