import moment from 'moment'

export const epochToString = (epoch) => {
    let date = new Date(epoch)
    return date.toGMTString()
}

export const getDisplayDate = (dateVal) => {

    if(!dateVal) return "Unknown"

    let displayDate;

    const postedOnDiffDays = moment().diff(dateVal, 'days')
    const postedOnDiffHours = moment().diff(dateVal, 'hours')
    const yesterday = (moment().date() - moment(dateVal).date() == 1 )
    const previousYear = (moment().year() - moment(dateVal).year() >= 1 )

    if (previousYear)
        displayDate = moment(dateVal).calendar(null, {
            sameElse: 'D MMM YYYY [at] hh:mm'
        })
    else if (postedOnDiffDays > 4)
        displayDate = moment(dateVal).calendar(null, {
            lastWeek: '[Last] ddd [at] HH:mm',
            sameElse: 'D MMM [at] HH:mm'
        })
    else if (yesterday || postedOnDiffDays >= 1)
        displayDate = moment(dateVal).calendar(null, {
            lastDay: '[Yesterday at ] HH:mm',
            lastWeek: 'ddd [at] HH:mm'
        })
    else if (postedOnDiffHours > 12)
        displayDate = moment(dateVal).calendar(null, {
            sameDay: '[Today at ] HH:mm',
        })
    else
        displayDate = moment(dateVal).from()

    //Deal with mismatch between client and server time
    if(displayDate == "in a few seconds") {
        displayDate = "a few seconds ago"
    }

    return displayDate
}