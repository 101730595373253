const authenticate = (state = {isAuthorised: false, forgotPassword: false}, action) => {
    switch (action.type) {
        case 'USER_AUTHORISED':
            return {
                isAuthorised: true,
                email: action.email,
                uid: action.uid,
                forgotPassword: false
            };
        case 'SESSION_CREATED':
            return {
                ...state,
                sessionId: action.sessionId
            };
        case 'AUTH_ERROR':
            return {
                error: true,
                errorCode: action.errorCode,
                errorMessage: action.errorMessage
            }
        case 'USER_LOGGED_OUT':
            return {isAuthorised: false,
                    forgotPassword: false};
        case 'FORGOT_PASSWORD':
            return {
                ...state,
                isAuthorised: false,
                email: action.email,
                forgotPassword: true,
                error: false
            };
        case 'PASSWORD_RESET_REQUESTED':
            if(action.pending){
                return{
                    ...state,
                    error: false,
                    pending: true
                };
            }
            else if(action.error)
                return{
                    ...state,
                    error: true,
                    errorMessage: action.errorMessage,
                    pending: false
                };
            else
                return{
                    ...state,
                    pending: false
                };
        case 'PASSWORD_RESET_COMPLETE':
            return {
                ...state,
                isAuthorised: false,
                forgotPassword: false,
                error: false,
                pending: false
            };
        default:
            return state;
    }
};

export default authenticate;
