import ReactGA from 'react-ga'

export const log = (text) => {
    console.log(text);
}

export const logEvent = (category, action, label, value, nonInteraction = false) => {
    ReactGA.event({category, action, label, value, nonInteraction})
    log("logEvent = " + category + ", " + action + (", " + label || "") + (", " + value || "") + ", " + (", " + nonInteraction || "") + ", ")
}
