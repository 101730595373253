import React from 'react'

class EmptyState extends React.Component {
    render() {
        return (
            <div className="threadContainer shtum--thread-container emptyState">
                <h3>Hey there... select a board, or create one!</h3>
            </div>
        );
    }
}

export default EmptyState
