import {firebase} from '../services/dataSvc'

import {serviceRoot} from '../config'
import {logEvent, log} from '../lib/logging'
import * as urlUtils from "../lib/urlUtils";

export const reactToPost = (reaction, userAlias, post) => {

    let _dispatch, _getState;
    const currentUserId = firebase.auth().currentUser.uid;

    return function (dispatch, getState) {

        _dispatch = dispatch
        _getState = getState

        /*console.log("reaction = " + reaction)
         console.log(JSON.stringify(post))*/

        react()
    }

    function react() {
        const postId = post.postId;
        const boardName = _getState().userBoards.boardList[post.boardId].name;

        const postParams =
            {
                userId: currentUserId,
                userAlias,
                reaction,
                boardName,
                postedById: post.postedById
            };

        /*console.log("postParams = ")
         console.log(postParams)*/

        const reactToPostCall = $.post(`${serviceRoot}boards/${post.boardId}/threads/${post.threadId}/posts/${postId}/react`, postParams);

        reactToPostCall.done(
            function (data) {
                logEvent("Post", "react", reaction)
            }).fail(
            function (jqXHR, textStatus, errorThrown) {
                _dispatch({
                    type: 'POST_REACT_ERROR',
                    pending: false,
                    error: true,
                    errorMessage: "There was an error reacting to a post: " + textStatus
                })
            })
    }

}

export const unreactToPost = (reaction, userAlias, post) => {

    let _dispatch, _getState;
    const currentUserId = firebase.auth().currentUser.uid;

    return function (dispatch, getState) {

        _dispatch = dispatch
        _getState = getState

        /*console.log("reaction = " + reaction)
         console.log(JSON.stringify(post))*/

        unreact()
    }

    function unreact() {
        const postId = post.postId;

        const postParams =
            {
                userId: currentUserId,
                userAlias,
                reaction
            };

        /*console.log("postParams = ")
         console.log(postParams)*/

        const unreactToPostCall = $.post(`${serviceRoot}boards/${post.boardId}/threads/${post.threadId}/posts/${postId}/unreact`, postParams);

        unreactToPostCall.done(
            function (data) {
                logEvent("Post", "unreact", reaction)
            }).fail(
            function (jqXHR, textStatus, errorThrown) {
                _dispatch({
                    type: 'POST_UNREACT_ERROR',
                    pending: false,
                    error: true,
                    errorMessage: "There was an error unreacting to a post: " + textStatus
                })
            })
    }

}

export const createPost = (boardId, threadId, parentPostId, subscribe, rawContent,
                           mentionMembers, mediaContent) /*mediaObject, mediaLocation, mediaFile, embedData)*/ => {

    let _dispatch, _getState;
    const currentUserId = firebase.auth().currentUser.uid

    return function (dispatch, getState) {

        _dispatch = dispatch
        _getState = getState

        dispatch({
            type: 'CREATE_POST_PENDING'
        })

        if (mediaContent.mediaObject === "Image") {
            uploadImageThenSubmitPost(boardId, currentUserId, mediaContent, submitPost)
        }
        else if (mediaContent.mediaObject === "File") {
            uploadFileThenSubmitPost(boardId, currentUserId, mediaContent.mediaFile, submitPost)
        }
        else
            submitPost()
    }

    function submitPost(fileData = {}) {
        const postParams =
            {
                userId: currentUserId,
                userAlias: _getState().board.activeBoard.userAlias,
                boardName: _getState().board.activeBoard.name,
                parentPostId,
                subscribe,
                rawContent: JSON.stringify(rawContent),
                mentionMembers: JSON.stringify(mentionMembers),
                mediaObject: mediaContent.mediaObject,
                mediaLocation: fileData.downloadURL || mediaContent.mediaLocation,
                originalFileName: fileData.originalFileName || null,
                embedData: JSON.stringify(mediaContent.embedData),
                originalImageUrl: fileData.originalImageUrl || null,
                imageWidth: fileData.width || null,
                imageHeight: fileData.height || null
            }

        const isThreadView = !!_getState().board.activeBoard.threadId
        const isNewThread = threadId === 'NewThread';

        //TODO: add the originalFileName to the server code
        const createPostCall = $.post(`${serviceRoot}boards/${boardId}/threads/${threadId}/posts`, postParams)

        createPostCall.done(
            function (data) {

                if (isThreadView && isNewThread)
                    _dispatch(push('/board/' + boardId))

                _dispatch({
                    type: 'CREATE_POST_COMPLETE',
                    pending: false,
                    postId: data.postId
                })

                if (isNewThread)
                    logEvent("Thread", "start")

                logEvent("Post", "create")
            }).fail(
            function (jqXHR, textStatus, errorThrown) {
                _dispatch({
                    type: 'POST_CREATION_ERROR',
                    pending: false,
                    error: true,
                    errorMessage: "There was an error creating the post: " + jqXHR.responseText
                })
            })
    }
}


function uploadFileThenSubmitPost(boardId, currentUserId, file, submitPost) {

    const metadata = {
        contentType: file.type
    }

    const filePath = `${boardId}-${Date.now()}`

    /*console.log("filePath = " + filePath)
     console.log("file.type = " + file.type)*/

    const storageRef = firebase.storage().ref();
    const uploadTask = storageRef.child(`files/${filePath}/${file.name}`).put(file, metadata);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
        function (snapshot) {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log('Upload is ' + progress + '% done')
            switch (snapshot.state) {
                case firebase.storage.TaskState.PAUSED: // or 'paused'
                    console.log('Upload is paused')
                    break;
                case firebase.storage.TaskState.RUNNING: // or 'running'
                    console.log('Upload is running')
                    break;
            }
        }, function (error) {
            switch (error.code) {
                case 'storage/unauthorized':
                    // User doesn't have permission to access the object
                    console.log('error: storage/unauthorized')
                    break;

                case 'storage/canceled':
                    // User canceled the upload
                    console.log('error: storage/canceled')
                    break;

                case 'storage/unknown':
                    // Unknown error occurred, inspect error.serverResponse
                    console.log('error: storage/unknown')
                    break;
            }
        }, function () {
            // Upload completed successfully, now we can get the download URL
            // const downloadURL = uploadTask.snapshot.downloadURL;
            // console.log('downloadURL = ' + downloadURL)
            let fileData = {
                downloadURL: uploadTask.snapshot.downloadURL,
                originalFileName: file.name
            }

            urlUtils.shorten(uploadTask.snapshot.downloadURL)
                .then((shortUrl) => {
                    console.log(`shortUrl = ${shortUrl}`)
                    fileData.downloadURL = shortUrl;
                    submitPost(fileData)
                })
                .catch(data => {
                    console.log(`Shorten error: ${JSON.stringify(data)}`)
                    submitPost(fileData)
                })
        });
}


function uploadImageThenSubmitPost(boardId, currentUserId, mediaContent, submitPost) {

    const file = mediaContent.mediaFile

    const form = new FormData()
    form.append("file", file)
    form.append("upload_preset", "board_photos")

    $.ajax({
        url: 'https://api.cloudinary.com/v1_1/shtum/image/upload',
        type: 'POST',
        data: form,
        cache: false,
        dataType: 'json',
        processData: false, // Don't process the files
        contentType: false, // Set content type to false as jQuery will tell the server its a query string request
        success: function (data, textStatus, jqXHR) {
            if (typeof data.error === 'undefined') {
                //const data = JSON.parse(uploadData)
                const downloadURL = data.eager[0].secure_url
                const originalImageUrl = data.secure_url
                const width = data.width
                const height = data.height

                //console.log((`uploadData = ${JSON.stringify(uploadData)}`))

                //TODO: this should use a promise
                submitPost({downloadURL, originalImageUrl, width, height})
            }
            else {
                // Handle errors here
                console.log((`uploadData.error = ${JSON.stringify(data.error)}`))
            }
        },
        error: function (jqXHR, textStatus, errorThrown) {
            // Handle errors here
            console.log('ERRORS: ' + textStatus);
            // STOP LOADING SPINNER
        }
    });


}

export const updatePost = (post, subscribe, rawContent, mentionMembers, mediaContent) => {

    let _dispatch, _getState;
    const currentUserId = firebase.auth().currentUser.uid

    return function (dispatch, getState) {

        _dispatch = dispatch
        _getState = getState

        dispatch({
            type: 'UPDATE_POST_PENDING'
        })

        if (mediaContent.mediaObject === "Image") {
            uploadImageThenSubmitPost(post.boardId, currentUserId, mediaContent, submitPost)
        }
        else if (mediaContent.mediaObject === "File") {
            uploadFileThenSubmitPost(post.boardId, currentUserId, mediaContent.mediaFile, submitPost)
        }
        else {
            if (mediaContent.mediaObject === "RetainImage")
                mediaContent.mediaObject = "Image"

            if (mediaContent.mediaObject === "RetainFile")
                mediaContent.mediaObject = "File"

            const fileData = {
                width: mediaContent.mediaWidth,
                height: mediaContent.mediaHeight,
                downloadURL: mediaContent.mediaLocation,
                originalFileName: mediaContent.originalFileName
            }

            submitPost(fileData)
        }
    }

    function submitPost(fileData = {}) {
        const postParams =
            {
                parentPostId: post.parentPostId,
                boardName: _getState().board.activeBoard.name,
                postedOn: post.postedOn,
                postedById: post.postedById,
                updatedById: currentUserId,
                postedByAlias: post.postedByAlias,
                subscribe,
                rawContent: JSON.stringify(rawContent),
                mentionMembers: JSON.stringify(mentionMembers),
                mediaObject: mediaContent.mediaObject,
                mediaLocation: fileData.downloadURL || mediaContent.mediaLocation,
                originalFileName: fileData.originalFileName || null,
                reactions: JSON.stringify(post.reactions),
                embedData: JSON.stringify(mediaContent.embedData),
                originalImageUrl: fileData.originalImageUrl || null,
                imageWidth: fileData.width || null,
                imageHeight: fileData.height || null
            }

        //const updatePostCall = $.post(serviceRoot + "update-post", postParams)

        const postId = post.postId,
            boardId = post.boardId,
            threadId = post.threadId

        const updatePostCall = $.post(`${serviceRoot}boards/${boardId}/threads/${threadId}/posts/${postId}`, postParams)

        updatePostCall.done(
            function (data) {

                _dispatch({
                    type: 'UPDATE_POST_COMPLETE',
                    pending: false,
                    postId: data.postId
                })

                logEvent("Post", "update")
            }).fail(
            function (jqXHR, textStatus, errorThrown) {
                _dispatch({
                    type: 'POST_UPDATE_ERROR',
                    pending: false,
                    error: true,
                    errorMessage: "There was an error updating the post: " + jqXHR.responseText
                })
            })
    }
}

export const deletePost = (post) => {

    const updateData = {};
    const currentUserId = firebase.auth().currentUser.uid;

    return function (dispatch, getState) {

        const postData = getPostData(post);
        setUpPostInfo(post, postData);
        updateFirebaseAndDispatchPostDeletedAction(dispatch, post.postId, postData);
    }

    function setUpPostInfo(post, postData) {
        updateData['/boards/' + post.boardId + '/posts/' + post.threadId + '/' + post.postId] = postData;
    }

    function getPostData(post) {
        return {
            parentPostId: post.parentPostId,
            threadId: post.threadId,
            boardId: post.boardId,
            postedOn: post.postedOn,
            deletedOn: firebase.database.ServerValue.TIMESTAMP,
            isDeleted: true,
            originallyPostedById: post.postedById,
            deletedById: currentUserId,
            postedById: -1,
            postedByAlias: 'N/A',
            htmlContent: '<p class="deleted-post-message">This post has been CHEGGERSED</p>'
        };
    }

    function updateFirebaseAndDispatchPostDeletedAction(dispatch, postId, postData) {

        log("Deleting post: " + postId);

        firebase.database().ref().update(updateData)
            .then(
                function () {
                    dispatch({
                        type: 'DELETE_POST_COMPLETE',
                        pending: false,
                        postId,
                        postData
                    });

                    logEvent("Post", "delete")
                })
            .catch(function (error) {
                dispatch({
                    type: 'DELETE_UPDATE_ERROR',
                    pending: false,
                    error: true,
                    errorMessage: "There was an error deleting the post: " + error.message
                });
            });
    }

};
