import React from 'react'
import {connect} from 'react-redux'
import Immutable from 'immutable'

import ChangePassword from './ChangePassword'
import InviteUsers from './InviteUsers'
import Notifications from './Notifications'
import BoardMembers from './BoardMembers'
import NotificationSetting from './NotificationSettings'
import NavigationSubMenu from './NavigationSubMenu'

import {closeSearch, startInviteUsers, startSearch} from '../actions/boardActions'
import {showNotifications, showNotificationSettings} from '../actions/notificationActions'
import {startManageMembers, startCreateBoard} from '../actions/userBoardsActions'

//import CreateBoard from '../components/CreateBoard'

class Navigation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            onLogOut: props.onLogOut
        }
    }

    /*handleNotificationSettings = () => {

    }*/

    handleLogOut = (e) => {
        this.state.onLogOut();
    }
    handleChangePassword = () => {
        if (this.refs.PasswordChange)
            this.refs.PasswordChange.openPasswordChangeModal();
    }

    render() {
        const {
            boardName,
            onInviteUsers,
            onShowNotifications,
            onShowNotificationSettings,
            onStartManageMembers,
            onCreateBoard,
            onStartSearch,
            onCloseSearch,
            role,
            unseenNotificationCount,
            showSearchField,
            showSearchResults
        } = this.props;

        const titleName = boardName ? boardName : "shtum";
        const isAdmin = role === "owner" || role === "admin";
        document.title = (unseenNotificationCount > 0) ? "(" + unseenNotificationCount + ") " + titleName : titleName;



        return (
            <div>
                <ChangePassword ref="PasswordChange"/>
                <nav className="navbar navbar-default navbar-fixed-top container shtumNav shtum--navigation">
                    <div className="shtum--navigation-container">
                        <div className="navbar-header">
                            <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                                    data-target="#shtum-navbar" aria-expanded="false">
                                <span className="sr-only">Toggle navigation</span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                                <span className="icon-bar"></span>
                            </button>
                            <div className="navbar-brand">
                                <a href="#" className="visible-xs-inline visible-sm-inline visible-md-inline"
                                   data-toggle="offcanvas" data-target="#boardMenu" data-canvas="body" data-placement="left">
                                   <svg className="shtum--svg shtum--svg-md shtum--svg_green boardMenuIndicator"><use xlinkHref="#icon-list"></use></svg>
                                </a>
                                <h1 className="shtum--navigation-title">shtum</h1>
                            </div>
                        </div>
                        <div className="collapse navbar-collapse navItems" id="shtum-navbar">
                            <ul className="nav navbar-nav">
                                <li className="notificationMenu">
                                    <a href="#" onClick={onShowNotifications} data-toggle="collapse" data-target=".navbar-collapse.in">
                                        <svg className="shtum--svg shtum--svg-md shtum--svg_green"><use xlinkHref="#icon-new"></use></svg>
                                        Notifications <span className="badge">{unseenNotificationCount > 0 ? unseenNotificationCount : null}</span>
                                    </a>
                                </li>
                                <li className="dropdown">
                                    <a href="#" className="dropdown-toggle"
                                       data-toggle="dropdown" role="button"
                                       aria-haspopup="true" aria-expanded="false">
                                        <svg className="shtum--svg shtum--svg-md shtum--svg_green"><use xlinkHref="#icon-gear"></use></svg>
                                        Account
                                    </a>
                                    <ul className="dropdown-menu dropdown-menu-right">
                                        <li><a href="#" data-toggle="collapse" data-target=".navbar-collapse.in" onClick={onShowNotificationSettings}>Notification Settings</a></li>
                                        <li><a href="#" data-toggle="collapse" data-target=".navbar-collapse.in" onClick={this.handleChangePassword}>Change Password</a></li>
                                        <li><a href="#" data-toggle="collapse" data-target=".navbar-collapse.in" onClick={this.handleLogOut}>Logout</a></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <NavigationSubMenu
                        boardName={this.props.boardName}
                        isAdmin={isAdmin}
                        onCreateBoard={onCreateBoard}
                        onInviteUsers={onInviteUsers}
                        onStartManageMembers={onStartManageMembers}
                        onStartSearch={onStartSearch}
                        onCloseSearch={onCloseSearch}
                        showSearchField={showSearchField}
                        showSearchResults={showSearchResults}
                    />
                </nav>

                <InviteUsers/>
                <Notifications/>
                <BoardMembers />
                <NotificationSetting />

            </div>);
    }
}


const mapStateToProps = (state) => {
    let activeBoard = state.board.activeBoard
    let notifications = state.notifications
    let unseenNotificationCount = countUnseenNotifications(notifications.aggregateItems)
    let showSearchField = state.board.action.showSearchField
    let showSearchResults = state.board.action.showSearchResults;

    return {
        boardName: activeBoard.name,
        role: activeBoard.role,
        unseenNotificationCount,
        showSearchField,
        showSearchResults
    }
}

const countUnseenNotifications = (notifications) => {
    return Immutable.fromJS(notifications)
        .filter(function (notification, key, iter) {
            return !notification.get("seen")
        }).count()
}

const mapDispatchToProps = (dispatch) => {
    return {
        onInviteUsers: () =>
            dispatch(startInviteUsers()),
        onShowNotifications: () =>
            dispatch(showNotifications()),
        onShowNotificationSettings: () =>
            dispatch(showNotificationSettings()),
        onStartManageMembers: () =>
            dispatch(startManageMembers()),
        onCreateBoard: (email) =>
            dispatch(startCreateBoard()),
        onStartSearch: () =>
            dispatch(startSearch()),
        onCloseSearch: () => {
            dispatch(closeSearch())
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Navigation);
