import Cookies from 'js-cookie'
import {push} from 'react-router-redux'
import moment from 'moment'
import {v4} from 'uuid';
import {firebase} from '../services/dataSvc'
import {resetPassword as sendTempPassword} from '../services/authSvc'
import toast from '../lib/toast'
import {initNotifications} from './notificationActions'

export const initAuthState = () => {

    const currentUser = firebase.auth().currentUser;

    if (!currentUser)
        return {
            isAuthorised: false,
            forgotPassword: false
        };
    else
        return {
            isAuthorised: true,
            email: currentUser.email,
            uid: currentUser.uid
        };
}

export const loginWithPassword = (email, password) => {
    let _dispatch, _getState

    return function (dispatch, getState) {
        _dispatch = dispatch
        _getState = getState

        login()
            .then(() => {/*Do nothing, handled by firebase auth watcher*/
            })
            .catch(function (err) {
                _dispatch({
                    type: "AUTH_ERROR",
                    errorCode: err.errorCode,
                    errorMessage: err.errorMessage
                })
            })
    }

    function login() {
        return new Promise((resolve, reject) => {
                firebase.auth()
                    .signInWithEmailAndPassword(email, password)
                    .then(function () {
                        Cookies.set('lastLoginEmail', email, {expires: 365});
                        resolve();
                    })
                    .catch(function (error) {
                        reject({
                            errorCode: error.code,
                            errorMessage: error.message
                        });
                    });
            }
        );
    }
}

export const startListeningToAuth = () => {
        return function (dispatch, getState) {

            firebase.auth().onAuthStateChanged(function (user) {
                    const pathName = window.location.pathname

                    if (user) {
                        if (pathName == '/' || pathName.startsWith('/auth') || pathName.startsWith('/accept'))
                            dispatch(push('/home'));

                        dispatch(userAuthorised(user.email, user.uid))
                        dispatch(initNotifications())
                    } else {
                        dispatch(push('/auth'));
                        dispatch(loggedOut());
                    }
                }
            )
            ;
        }
    }
;


export const loggedOut = () => {
    return {
        type: 'USER_LOGGED_OUT'
    }
}

export const userAuthorised = (email, uid) => {
    return function (dispatch, getState) {
        dispatch({
            type: 'USER_AUTHORISED',
            isAuthorised: true,
            email,
            uid
        })
    }
}

export const createSessionId = () => {

    return new Promise((resolve, reject) => {

        const uid = firebase.auth().currentUser.uid
        const userSessionRef = firebase.database().ref(`sessions/${uid}`)
        const sessionId = (userSessionRef.push().key + v4().replace(/-/g, ''))
        const sessionData = {sessionId, startTime: firebase.database.ServerValue.TIMESTAMP}

        userSessionRef.set(sessionData)
            .then(() => {
                //console.log(`createSessionId sessionId = ${sessionId}`)
                resolve(sessionId)
            })
            .catch((err) => {
                console.log("There's an error creating the session:")
                console.log(err)
                reject(err)
            })
    })
}

export const forgotPassword = (email) => {
    return {
        type: 'FORGOT_PASSWORD',
        email
    }
};


export const passwordResetComplete = () => {
    return {
        type: 'PASSWORD_RESET_COMPLETE'
    }
};

export const requestPasswordReset = (email) => {
    return function (dispatch, getState) {
        dispatch({type: 'PASSWORD_RESET_REQUESTED', pending: true, email});
        return new Promise(function (resolve, reject) {
            sendTempPassword(email, (er) => {
                    if (er) {
                        switch (er.code) {
                            case "auth/user-not-found":
                                dispatch({
                                    type: 'PASSWORD_RESET_REQUESTED',
                                    pending: false,
                                    email,
                                    error: true,
                                    errorMessage: "The specified email address is not registered."
                                });
                                break;
                            default:
                                dispatch({
                                    type: 'PASSWORD_RESET_REQUESTED',
                                    pending: false,
                                    email,
                                    error: true,
                                    errorMessage: "Error resetting password: " + er.message
                                });
                        }
                        return resolve();
                    } else {
                        toast.success("Password reset", "Please check your email for instructions")
                        dispatch(passwordResetComplete());
                        resolve();
                    }
                }
            );
        })
    }
};

