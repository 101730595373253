import React from 'react'
import Cookies from 'js-cookie'
import {connect} from 'react-redux'
import toast from '../lib/toast'

import MessageModal from './MessageModal'
import Login from './Login'
import ForgotPassword from '../components/ForgotPassword'
import {loggedOut, userAuthorised, forgotPassword} from '../actions/authActions'
import {logOut} from '../services/authSvc'
import {saveNewUser} from "../services/authSvc";
import {firebase} from '../services/dataSvc'

//TODO: Change form handling to use Redux Forms
class Authenticate extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            emailReg: "",
            passwordReg: "",
            passwordConfirmReg: "",
            email: Cookies.get('lastLoginEmail') || "",
            password: "",
            resetPasswordEmail: "",
            recaptchaVerified: false
        }
    }

    componentDidMount = () => {
        const {isInvitePending} = this.props;

        if (isInvitePending) {
            toast.success('Welcome to shtum', 'Please register or login to accept the invite.')
        }

        const that = this;
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
            'size': 'normal',
            'callback': function(response) {
                that.setState({recaptchaVerified: true})
            },
            'expired-callback': function() {
                that.setState({recaptchaVerified: false})
            }
        });

        recaptchaVerifier.render()
            .then(function(widgetId) {
                window.recaptchaWidgetId = widgetId;
            });
    }
    handleRegEmailChange = (e) => {
        this.setState({emailReg: e.target.value});
    }
    handleRegPasswordChange = (e) => {
        this.setState({passwordReg: e.target.value});
    }
    handleRegPasswordConfirmChange = (e) => {
        this.setState({passwordConfirmReg: e.target.value});
    }
    handleRegister = (e) => {
        e.preventDefault();

        var emailReg = this.state.emailReg.trim();
        var passwordReg = this.state.passwordReg.trim();
        var passwordConfirmReg = this.state.passwordConfirmReg.trim();

        //TODO: Handle validation properly
        if (!emailReg || !passwordReg || !passwordConfirmReg) {
            this.refs.messageModal.showAlert({
                detail: "Please enter all fields"
            });

            return;
        }

        if (passwordReg !== passwordConfirmReg) {
            this.refs.messageModal.showAlert({
                detail: "Passwords do not match"
            });

            return;
        }

        if (passwordReg.length < 6) {
            this.refs.messageModal.showAlert({
                detail: "Passwords must be at least 6 characters"
            });

            return;
        }

        this.createUser(emailReg, passwordReg);

    };


    createUser(email, password) {
        var that = this;
        //Creates the user and auto logs in if successful
        firebase.auth().createUserWithEmailAndPassword(email, password)
            .then(function () {
                var uid = firebase.auth().currentUser.uid;
                saveNewUser(uid, email);
            })
            .catch(function (error) {
                that.refs.messageModal.showAlert({
                    title: error.code,
                    detail: error.message,
                    className: 'alert-danger'
                });
            });
    }

    render() {

        let valid = this.state.recaptchaVerified;

        return (
            <div className="shthum--auth-page authenticateContainer">
                <h1 className="brandTitleLogin shthum--auth-page-title text-center">shtum</h1>
                <div className="row">
                    <Login />
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <p className="shtum--auth-page-divider text-center">or</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-8 col-sm-6 col-md-4 col-xs-offset-2 col-sm-offset-3 col-md-offset-4 shthum--auth-page-register">
                        <form id="frmRegister" className="shtum--form-register" role="form" onSubmit={this.handleRegister}>
                            <div className="form-group">
                                <input type="email" className="form-control shtum--form-input" id="emailReg" placeholder="Enter email"
                                       name="emailReg" onChange={this.handleRegEmailChange}/>
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control shtum--form-input" id="passwordReg" placeholder="Password"
                                       name="passwordReg" onChange={this.handleRegPasswordChange}/>
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control shtum--form-input" id="confirmPasswordReg"
                                       placeholder="Confirm Password" name="confirmPasswordReg"
                                       onChange={this.handleRegPasswordConfirmChange}/>
                            </div>
                            <div className="form-group">
                                <div id="recaptcha-container" />
                            </div>
                            <div className="text-right">
                                <button type="submit" disabled={!valid} className="btn shtum--btn shtum--btn_green">Sign Up</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <ul className="list list-horizontal shtum--auth-page-list">
                            <li><a href="">unfollow</a></li>
                            <li><a href="">defriend</a></li>
                            <li><a href="">keep Shtum</a></li>
                        </ul>
                    </div>
                </div>

                <div className="modal fade shtum--modal" id="messageModal" tabIndex="-1" role="dialog">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-body errMessage">
                                <h4 id="message" className="text-center"></h4>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn shtum--btn shtum--btn_red" data-dismiss="modal">Close</button>
                            </div>
                        </div>
                    </div>
                </div>

                <MessageModal ref='messageModal'/>

                <ForgotPassword />

            </div>)
    }
}

const mapStateToProps = (state) => {
    let auth = state.auth

    return {
        isAuthorised: auth.isAuthorised,
        email: auth.email,
        uid: auth.uid,
        isInvitePending: checkIfInviteIsPending(state)
    }
};

function checkIfInviteIsPending(state) {
    let board = state.board

    var isInvitePending = false
    if (board.invite)
        isInvitePending = board.invite.invitePending
    return isInvitePending;
}

const mapDispatchToProps = (dispatch) => {
    return {
        onLogOut: () => {
            logOut();
            dispatch(loggedOut());
        },
        onUserAuthorised: (email, uid) => {
            dispatch(userAuthorised(email, uid));
        },
        onForgotPassword: (email) =>
            dispatch(forgotPassword(email))
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Authenticate);
