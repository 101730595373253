window.URL = window.URL || window.webkitURL;
const useBlob = false && window.URL; // set to `true` to use Blob instead of Data-URL

export const readImageInfo = (file) => {

    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.addEventListener("load", function () {
            const image = new Image();

            image.addEventListener("load", function () {
                const imageInfo =
                    {
                        fileName: file.name,
                        width: image.width,
                        height: image.height,
                        fileType: file.type,
                        fileSize: Math.round(file.size / 1024) //'KB'
                    }

                if (useBlob) {
                    // Free some memory
                    window.URL.revokeObjectURL(image.src);
                }

                resolve(imageInfo)
            });
            image.src = useBlob ? window.URL.createObjectURL(file) : reader.result;
        });

        reader.readAsDataURL(file)
    })
}