/*
 From https://gist.github.com/jarsbe/af55f3705add9d9ec34fd0cb25ef2f67
 Referenced by https://github.com/draft-js-plugins/draft-js-plugins/issues/302
 */
//import {EditorState, Modifier, Entity, SelectionState} from 'draft-js'
import linkifyIt from 'linkify-it'
import tlds from 'tlds'

const linkify = linkifyIt()

linkify.tlds(tlds)

export const findFirstLinkInText = (text) => {
    const links = linkify.match(text)
    if (typeof links !== 'undefined' && links !== null)
        return links[0].url
    else
        return null
}
