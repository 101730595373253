import React from 'react'
import {Link} from 'react-router'
// import InfiniteScroll from 'redux-infinite-scroll'
import InfiniteScroll from 'react-infinite-scroller';

import {connect} from 'react-redux'
import Cookies from 'js-cookie'

import SearchField from './SearchField'
import Thread from './Thread'
import PostEditModal from './PostEditModal'
import Spinner from './Spinner'
import {
    initBoard,
    loadMoreThreads,
    closeSearch,
    searchBoard,
    backToSearchResults,
    pageSearchBoard
} from '../actions/boardActions'
import {startCreatePost} from '../actions/threadActions'

import {log} from '../lib/logging'
import SearchResults from "./SearchResults";

class Board extends React.Component {
    constructor(props) {
        super(props)

        /*let highlightedPosts = [];
         if (props.location.query.p) {
         highlightedPosts = props.location.query.p.split(',')
         }*/
        this.state = {
            boardId: props.params.boardId,
            threadId: props.params.threadId,
            highlightedPosts: props.location.query.p ? props.location.query.p.split(',') : [],
            isFromSearch: props.location.query.s,
            boardIsInitialising: true,
            boardJustInitialised: false,
        }
    }

    componentDidMount = () => {
        const boardId = this.props.params.boardId
        const threadId = this.props.params.threadId
        this.initialiseTheBoard(boardId, threadId)
    }


    componentDidUpdate = (prevProps, prevState) => {
        let oldBoardId = prevProps.params.boardId
        let oldThreadId = prevProps.params.threadId
        let newBoardId = this.props.params.boardId
        let newThreadId = this.props.params.threadId

        // console.log(`boardIsInitialising = ${this.state.boardIsInitialising}, prevProps.threadsLoading=${prevProps.threadsLoading}, props.threadsLoading=${this.props.threadsLoading}`)
        // console.log(`THREADS COUNT=prevProps.threads.length=${prevProps.threads.length}, this.props.threads.length=${this.props.threads.length}`)
        // console.log(`boardIsInitialising = ${this.state.boardIsInitialising}`)

        if (prevState.boardIsInitialising !== this.state.boardIsInitialising) {
            // log('Setting boardJustInitialised: true')
            this.setState({boardJustInitialised: true})
        }

        if ((!this.state.boardIsInitialising && newBoardId !== oldBoardId)
            || (!this.state.boardIsInitialising && newThreadId !== oldThreadId)) {
            this.setState({boardId: newBoardId, threadId: newThreadId})
            this.initialiseTheBoard(newBoardId, newThreadId)
            //log("CDU - initialising board")
        } else if (!this.state.boardIsInitialising && (prevProps.threads.length > 0 && this.props.threads.length === 0)) {
            // After re-pinning a thread and reloading the board set boardIsInitialising to true without dispatching the initBoard action again
            this.setState({boardIsInitialising: true})
        } else if (this.state.boardIsInitialising && !prevProps.threadsLoading) {
            this.setState({boardIsInitialising: false})
            this.setState({isFromSearch: this.props.location.query.s})
            //log("CDU - boardIsInitialising: false")
        } else if (prevProps.threadsLoading !== this.props.threadsLoading && this.state.boardJustInitialised) {
            //If the board's just been initialised and the threads have finished loading then scroll to the top
            // log(`Board - threads finished loading, this.state.boardIsInitialising=${this.state.boardIsInitialising}, this.state.boardJustInitialised=${this.state.boardJustInitialised}`)
            this.setState({boardJustInitialised: false})
            window.scrollTo(0, 0);
        }
    }

    initialiseTheBoard = (boardId, threadId) => {
        this.props.initialiseBoard(boardId, threadId);
        this.setState({boardIsInitialising: true})

        Cookies.set('lastBoardVisited', boardId, {expires: 365})

        if (this.props.location.query.p) {
            const highlightedPosts = this.props.location.query.p.split(',')
            this.setState({highlightedPosts})
        } else {
            this.setState({highlightedPosts: []})
        }
    }

    filterPostsByThread(posts, threadId) {
        return posts.filter(function (v, i) {
            return v.threadId === threadId;
        });
    }

    loadMoreThreads = () => {
        if (!this.state.threadId && this.props.posts.length > 0 && !this.state.boardIsInitialising && !this.props.threadsLoading) {
            console.log("Getting more threads...")
            this.props.loadMoreThreads(this.state.boardId);
        }
    }

    renderLoader = () => {
        if (this.props.posts && this.props.posts.length > 0 && !this.props.params.threadId)

            return (
                <div className="loadingSmall"><i className="fa fa-circle-o-notch fa-spin fa-3x fa-fw spinnerIconSmall"/>
                </div>)

        /*return (<div className="loadingSmall"><img app="/images/LoadingRingSmall.gif"/></div>)*/

        return ""
    }

    toSearchResults = () => {
        this.props.onBackToSearchResults()
    }

    renderThreads() {
        const {threads, posts, siteLoading, threadsLoading, pinnedThreadId} = this.props;

        threads.sort(function (threadA, threadB) {
            return threadB.createdOn - threadA.createdOn
        });

        if (posts.length > 0) {

            //markHighlightedPosts highlightedPosts
            this.state.highlightedPosts.forEach(pid => {
                let post = posts.find(pst => pst.postId === pid);
                if (post) {
                    //console.log(`Highlight found post id ${pid}`)
                    post.isHighlighted = true;
                } else {
                    //console.log(`Not found post id ${pid}`)
                }
            })

            let renderedThreads = threads
                .filter(t => t.threadId !== pinnedThreadId)
                .map(function (thread) {
                    const threadPosts = this.filterPostsByThread(posts, thread.threadId);
                    return <Thread threadId={thread.threadId} boardId={thread.boardId}
                                   isPinned={thread.threadId === pinnedThreadId}
                                   key={thread.threadId} posts={threadPosts} following={thread.following}/>
                }, this);

            if (pinnedThreadId && threads.length > 0 && !renderedThreads.some(t => t.threadId === pinnedThreadId)) {
                let thread = threads.find(t => t.threadId === pinnedThreadId);

                if (thread) {
                    let threadPosts = this.filterPostsByThread(posts, pinnedThreadId);
                    let pinnedThread = <Thread threadId={thread.threadId} boardId={thread.boardId}
                                               isPinned={thread.threadId === pinnedThreadId}
                                               key={thread.threadId} posts={threadPosts} following={thread.following}/>

                    renderedThreads = [pinnedThread, ...renderedThreads];
                }
            }

            return renderedThreads;
        }

        if (!siteLoading && posts.length === 0 && !threadsLoading) {
            return (
                [<div className="shtum--page-center-wide" key="emptyState">
                    <h2>Hit the "+" button to<br/>start the conversation.</h2>
                </div>])
        }


        if (siteLoading) {
            return (
                [<div className="shtum--page-center-wide" key="emptyState">
                    <i className="fa fa-spinner fa-pulse fa-3x fa-fw spinnerIcon"/>
                </div>])
        }


        return ([<Spinner key="spinner"/>]);
    }


    render() {
        const boardId = this.props.params.boardId;
        const threadId = this.props.params.threadId;
        const {
            startNewPost, threadsLoading, allThreadsLoaded,
            showSearchField, onCloseSearch, onSearchBoard,
            showSearchResults, searchResults, searchIsPending,
            query, offset, limit, onPageSearchResults
        } = this.props;

        const isFromSearch = this.state.isFromSearch

        const items = this.renderThreads();

        const hasMore = !allThreadsLoaded && !threadId

        return (
            <div className="threadContainer shtum--thread-container">

                {showSearchField ?
                    <SearchField onSearchBoard={onSearchBoard} onCloseSearch={onCloseSearch}
                                 searchIsPending={searchIsPending} query={query}/>
                    : ''}

                {showSearchResults ? <SearchResults numberOfResults={searchResults.numberOfResults}
                                                    posts={searchResults.posts} query={searchResults.query}
                                                    onPageSearchResults={onPageSearchResults}
                                                    offset={offset} limit={limit}/> :
                    <div>
                        <PostEditModal/>

                        {threadId && !isFromSearch ?
                            <div className="shtum--thread-container-view-whole-container">
                                <Link className="shtum--thread-container-view-whole-link shtum--btn-svg"
                                      to={'/board/' + boardId}>
                                    <svg className="shtum--svg shtum--svg-xs shtum--svg_green">
                                        <use xlinkHref="#icon-reply-all"></use>
                                    </svg>
                                    <span>View Whole Board</span>
                                </Link>
                            </div> : ''}

                        {isFromSearch ?
                            <div className="shtum--thread-container-view-whole-container">
                                <div className="shtum--link-left-from-search">
                                    <a className="shtum--thread-container-view-whole-link shtum--btn-svg"
                                       onClick={this.toSearchResults}>
                                        <svg className="shtum--svg shtum--svg-xs shtum--svg_green">
                                            <use xlinkHref="#icon-reply-all"></use>
                                        </svg>
                                        <span>Back to search results</span>
                                    </a>
                                </div>
                                <div className="shtum--link-right-from-search">
                                    <Link className="shtum--thread-container-view-whole-link shtum--btn-svg"
                                          to={'/board/' + boardId}>
                                        <span>View Whole Board</span>
                                    </Link>
                                </div>
                            </div> : ''}

                        <InfiniteScroll
                            pageStart={0}
                            loadMore={this.loadMoreThreads.bind(this)}
                            // loadingMore={threadsLoading}
                            threshold={100}
                            // elementIsScrollable={false}
                            hasMore={!allThreadsLoaded}
                            loader={this.renderLoader()}
                        >
                            {items}
                        </InfiniteScroll>


                        <a className="shtum--btn-add-post" title="New thread" onClick={() => startNewPost(boardId)}>
                            <svg className="shtum--svg shtum--svg-xl shtum--svg_white">
                                <use xlinkHref="#icon-plus"></use>
                            </svg>
                        </a>
                    </div>
                }
                <div className="page-padding"></div>
            </div>

        );
    }
}

const mapStateToProps = (state) => {
    let board = state.board;
    let showSearchField = board.action.showSearchField;
    let showSearchResults = board.action.showSearchResults;
    let searchResults = board.action.searchResults;
    let searchIsPending = board.action.searchPending
    let query = "", offset = 0, limit = 10
    if (searchResults) {
        query = searchResults.query
        offset = searchResults.offset
        limit = searchResults.limit
    }


    return {
        threads: board.threads,
        posts: board.posts,
        threadsLoading: board.activeBoard.threadsLoading || false,
        allThreadsLoaded: board.activeBoard.allThreadsLoaded || false,
        siteLoading: board.activeBoard.siteLoading,
        pinnedThreadId: board.activeBoard.pinnedThreadId,
        showSearchField,
        showSearchResults,
        searchResults,
        searchIsPending,
        query,
        offset,
        limit,
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        initialiseBoard: (boardId, threadId) =>
            dispatch(initBoard(boardId, threadId)),
        startNewPost: (boardId) => {
            dispatch(startCreatePost(false, boardId))
        },
        loadMoreThreads: (boardId) => {
            dispatch(loadMoreThreads(boardId))
        },
        onCloseSearch: () => {
            dispatch(closeSearch())
        },
        onSearchBoard: (query) => {
            dispatch(searchBoard(query))
        },
        onBackToSearchResults: () => {
            dispatch(backToSearchResults())
        },
        onPageSearchResults: (query, currentPage, pageSize) => {
            dispatch(pageSearchBoard(query, currentPage, pageSize))
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Board);
