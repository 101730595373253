import {firebase} from '../services/dataSvc'
import {push} from 'react-router-redux'
import Cookies from 'js-cookie'
import moment from 'moment'
import {serviceRoot} from "../config";
import toast from "../lib/toast";
import {logEvent} from '../lib/logging'
import {createSessionId} from "./authActions";

export const startCreateBoard = () => {
    return {
        type: 'CREATE_BOARD'
    }
};

export const createBoardComplete = () => {
    return {
        type: 'CREATE_BOARD_COMPLETE'
    }
}

export const createBoard = (name, ownerAlias) => {

    return function (dispatch, getState) {

        const newBoardKey = initialiseBoardAndReturnKey();
        const currentUserId = firebase.auth().currentUser.uid;
        const boardData = getBoardData(currentUserId);
        const userBoardInfo = getUserBoardInfo();
        const boardInfo = setUpBoardInfo(newBoardKey, boardData, currentUserId, userBoardInfo);

        updateFirebaseAndDispatchBoardCreatedAction(boardInfo, dispatch, newBoardKey, currentUserId);
    }

    function initialiseBoardAndReturnKey() {
        return firebase.database().ref().child('boards').push().key;
    }

    function getBoardData(currentUserId) {
        const boardInfo = {
            info: {
                name: name,
                ownerId: currentUserId,
                createdOn: firebase.database.ServerValue.TIMESTAMP,
                isPrivate: true
            },
            members: {}
        };

        boardInfo.members[currentUserId] = {
            alias: ownerAlias,
            role: "owner",
            joinedOn: firebase.database.ServerValue.TIMESTAMP
        };
        return boardInfo;
    }

    function getUserBoardInfo() {
        return {
            name: name,
            role: "owner",
            alias: ownerAlias,
            joinedOn: firebase.database.ServerValue.TIMESTAMP
        };
    }

    function setUpBoardInfo(newBoardKey, boardData, currentUserId, userBoardInfo) {
        const boardInfo = {};
        boardInfo['/boards/' + newBoardKey] = boardData
        //TODO: de-normalised board name to be stored with each user for speed but remember to update de-normalised value here if a change is observed when board details are retrieved
        boardInfo['/users/' + currentUserId + '/boards/' + newBoardKey] = userBoardInfo
        return boardInfo
    }


    function updateFirebaseAndDispatchBoardCreatedAction(boardInfo, dispatch, newBoardKey, boardOwnerId) {
        firebase.database().ref().update(boardInfo)
            .then(
                function () {
                    dispatch(push('/board/' + newBoardKey));
                    dispatch({
                        type: 'BOARD_CREATED',
                        pending: false,
                        name: name,
                        error: false,
                        boardKey: newBoardKey,
                        ownerId: boardOwnerId
                    })
                    logEvent("Board", "create")
                })
            .catch(function (error) {
                dispatch({
                    type: 'BOARD_CREATION_ERROR',
                    pending: false,
                    name: name,
                    error: true,
                    boardKey: newBoardKey,
                    errorMessage: "There was an error creating the board: " + error.message
                })
            })
    }
};

export const listenToBoardList = () => {

    let _dispatch, _getState;

    return function (dispatch, getState) {
        _dispatch = dispatch
        _getState = getState

        let currentUser = firebase.auth().currentUser;
        if (!currentUser) {
            waitForAuthAndStartToListenForBoards(dispatch);
            return {type: 'IGNORE'};
        }

        const userBoardsRef = firebase.database().ref('users/' + currentUser.uid + '/boards');

        userBoardsRef.on('value', function (snapshot) {
            const boards = snapshot.val() || {};
            checkIfUserRemovedFromActiveBoard(boards)

            const data = {
                type: 'BOARDS_LIST_UPDATED',
                boards
            };
            dispatch(data);
            listenToLastPostedOn(boards);
        })
        return {
            type: 'BOARDS_LISTEN_STARTED'
        }
    }

    function checkIfUserRemovedFromActiveBoard(boards) {
        const activeBoardId = _getState().board.activeBoard.boardId
        if (!activeBoardId) return //No active board

        let activeBoard = boards[activeBoardId];

        if (!activeBoard)
            removeFromActiveBoard()
    }

    function removeFromActiveBoard() {
        Cookies.remove('lastBoardVisited')
        const activeBoardName = _getState().board.activeBoard.name
        _dispatch(push('/home'))
        toast.error("Removed", "You have been removed from the board " + activeBoardName, 0)
    }

    function listenToLastPostedOn(boards) {
        for (let boardId in boards) {

            boards[boardId].boardId = boardId
            const boardRef = firebase.database().ref('/boards/' + boardId + '/info')

            boardRef.on('value', function (snapshot) {
                const boardInfo = snapshot.val()
                _dispatch({
                    type: 'LAST_POST_ON_RECEIVED',
                    lastPostOn: boardInfo.lastPostOn || 0,
                    lastPostByAlias: boardInfo.lastPostByAlias,
                    boardId: this.boardId,
                    pinnedThreadId: boardInfo.pinnedThreadId
                })

                _dispatch({
                    type: 'PINNED_THREAD_ID_RECEIVED',
                    boardId: this.boardId,
                    pinnedThreadId: boardInfo.pinnedThreadId
                })

                if ((_getState().userBoards.sortOrder || Cookies.get('sortOrder')) == "Most Recent Post")
                    _dispatch({
                        type: 'BOARD_SORT_ORDER'
                    })
            }, boards[boardId])
        }
    }

    function waitForAuthAndStartToListenForBoards(dispatch) {
        const observer = function (user) {
            if (user) {
                dispatch(listenToBoardList());
                unsubscribe();
            }
        }
        const unsubscribe = firebase.auth().onAuthStateChanged(observer)
    }
};

export const setBoardSortOrder = (sortOrder) => {
    Cookies.set('sortOrder', sortOrder, {expires: 365})
    return ({
        type: 'BOARD_SORT_ORDER',
        sortOrder
    })
}

export const startManageMembers = () => {
    let _dispatch, _getState;

    return function (dispatch, getState) {
        _dispatch = dispatch
        _getState = getState

        loadMembers()
    }

    function loadMembers() {
        const boardId = _getState().board.activeBoard.boardId
        const boardMembersRef = firebase.database().ref('boards/' + boardId + '/members')

        boardMembersRef.once('value', function (snapshot) {
            const members = snapshot.val();
            const data = {
                type: 'MANAGE_MEMBERS',
                members
            }
            _dispatch(data)
        })

    }
}

export const changeMemberRole = (memberId, role) => {
    let _dispatch, _getState

    return function (dispatch, getState) {
        _dispatch = dispatch
        _getState = getState

        _dispatch({
            type: 'CHANGE_ROLE_PENDING',
            pending: true,
            memberId
        })

        createSessionId().then((sessionId) => {
            changeRole(sessionId)
        })
    }

    function changeRole(sessionId) {
        const currentUserId = firebase.auth().currentUser.uid
        const postParams = {role, sessionId, currentUserId}
        const boardId = _getState().board.activeBoard.boardId
        const changeMemberRollCall = $.post(`${serviceRoot}boards/${boardId}/members/${memberId}/role`, postParams)

        changeMemberRollCall.done(
            function (data) {
                _dispatch({
                    type: 'CHANGE_ROLE_COMPLETE',
                    pending: false,
                    memberId,
                    boardId,
                    role
                })

                _dispatch(startManageMembers())
            }).fail(
            function (jqXHR, textStatus, errorThrown) {
                //TODO: handle failure - this means some kind of db failure or unauthorised access

                toast.error('Change role failed', 'Please check you are authorised to perform this action')

                _dispatch({
                    type: 'CHANGE_ROLE_ERROR',
                    pending: false,
                    error: true,
                    errorMessage: "There was an error changing the member role: " + jqXHR.responseText
                });
            })
    }

}


export const closeManageMembers = () => {
    return {
        type: 'CLOSE_MANAGE_MEMBERS'
    }
}

export const removeMember = (memberId, memberAlias) => {
    let _dispatch, _getState;

    return function (dispatch, getState) {
        _dispatch = dispatch
        _getState = getState

        console.log("---removeMember---")

        _dispatch({
            type: 'REMOVE_MEMBER_PENDING',
            pending: true
        })

        const boardId = _getState().board.activeBoard.boardId
        const postParams = {memberAlias}

        const removeMemberCall = $.post(`${serviceRoot}boards/${boardId}/members/${memberId}/remove`, postParams)

        removeMemberCall.done(
            function (data) {
                _dispatch({
                    type: 'REMOVE_MEMBER_COMPLETE',
                    pending: false,
                    memberId,
                    memberAlias
                })

                _dispatch(startManageMembers())

            }).fail(
            function (jqXHR, textStatus, errorThrown) {
                _dispatch({
                    type: 'REMOVE_MEMBER_ERROR',
                    pending: false,
                    error: true,
                    errorMessage: "There was an error removing the member: " + jqXHR.responseText
                });
            })
    }
}

export const reinstateMember = (memberId, memberAlias) => {
    let _dispatch, _getState

    return function (dispatch, getState) {
        _dispatch = dispatch
        _getState = getState

        console.log("---reinstateMember---")

        _dispatch({
            type: 'REINSTATE_MEMBER_PENDING',
            pending: true
        })

        const boardId = _getState().board.activeBoard.boardId
        const postParams =
            {
                memberAlias,
                boardName: _getState().board.activeBoard.name
            }

        const reinstateMemberCall = $.post(`${serviceRoot}boards/${boardId}/members/${memberId}/reinstate`, postParams)

        reinstateMemberCall.done(
            function (data) {
                _dispatch({
                    type: 'REINSTATE_MEMBER_COMPLETE',
                    pending: false,
                    memberId,
                    memberAlias
                })

                _dispatch(startManageMembers())
            }).fail(
            function (jqXHR, textStatus, errorThrown) {
                _dispatch({
                    type: 'REINSTATE_MEMBER_ERROR',
                    pending: false,
                    error: true,
                    errorMessage: "There was an error reinstating the member: " + jqXHR.responseText
                });
            })
    }
}
