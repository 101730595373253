import {toastr} from 'react-redux-toastr'

export default class toast {

    static error(title, message, timeOut = 7000) {
        const toastrOptions = {
            timeOut,
            icon: 'icon-exclamation-alert',
            removeOnClick: true
        }

        toastr.error(title, message, toastrOptions)
    }

    static success(title, message, timeOut = 8000) {
        const toastrOptions = {
            timeOut,
            icon: 'icon-information-circle',
            removeOnClick: true
        }

        toastr.success(title, message, toastrOptions)
    }
}