

//https://www.w3.org/TR/html5/forms.html#file-upload-state-(type=file)
export const extractFilename = (path) => {
    if (path.substr(0, 12) === "C:\\fakepath\\")
        return path.substr(12); // modern browser
    let x;
    x = path.lastIndexOf('/');
    if (x >= 0) // Unix-based path
        return path.substr(x+1);
    x = path.lastIndexOf('\\');
    if (x >= 0) // Windows-based path
        return path.substr(x+1);
    return path; // just the filename
}
