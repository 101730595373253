export const startPulse = (secs = 60) => {

    var _dispatch, _getState;

    return function (dispatch, getState) {
        _dispatch = dispatch
        _getState = getState
        pulse()
    }

    function pulse() {
        setInterval(() => {
            _dispatch({
                type: 'PULSE',
                value: Date.now().valueOf()
            })
        }, secs * 1000)
    }
}