import React from 'react'

const NavigationSubMenu = ({boardName, isAdmin, onCreateBoard, onInviteUsers, onStartManageMembers, onStartSearch, onCloseSearch, showSearchField, showSearchResults}) => {

    const searchToggleFunc = showSearchField ? onCloseSearch : onStartSearch

    return (
        <div className="shtum--navigation-submenu-container">
            <div className="shtum--navigation-submenu-container_left">
                <ul className="list list-horizontal">
                    <li>
                        <button className="shtum--navigation-new-board-btn shtum--btn-svg" title="Create New Board"
                                onClick={onCreateBoard}>
                            <svg className="shtum--svg shtum--svg-sm shtum--svg_pink">
                                <use xlinkHref="#icon-circle-with-plus"></use>
                            </svg>
                        </button>
                    </li>
                    <li>
                        <h3>Your Boards</h3>
                    </li>
                </ul>
            </div>
            <div className="shtum--navigation-submenu-container_right">
                <h3>{boardName} {showSearchResults ? <span className="shtum--navigation-submenu-search-title">SEARCH RESULTS</span> : ''}</h3>

                <ul className="list list-horizontal shtum--navigation-submenu-list-invite-info">
                    <li>
                        <a href="#" className="shtum--btn-svg" onClick={searchToggleFunc} data-toggle="collapse" data-target=".navbar-collapse.in" title="Search">
                            <i className="fa fa-search nav-bar-icon"/>
                            <span>Search</span>
                        </a>
                    </li>
                    {isAdmin ?
                        <li>
                            <a href="#" className="shtum--btn-svg" onClick={onInviteUsers} data-toggle="collapse" data-target=".navbar-collapse.in" title="Invite">
                                <svg className="shtum--svg shtum--svg-xs shtum--svg_white">
                                    <use xlinkHref="#icon-paper-plane"></use>
                                </svg>
                                <span>Invite</span>
                            </a>
                        </li> : ''}
                    {isAdmin ?
                        <li>
                            <a href="#" className="shtum--btn-svg" onClick={onStartManageMembers} data-toggle="collapse" data-target=".navbar-collapse.in" title="Info">
                                <svg className="shtum--svg shtum--svg-xs shtum--svg_white">
                                    <use xlinkHref="#icon-info-with-circle"></use>
                                </svg>
                                <span>Info</span>
                            </a>
                        </li>
                        : ''}
                </ul>
            </div>
        </div>
    );
}

export default NavigationSubMenu;
