import React from 'react'
import {render} from 'react-dom'
import {Provider} from 'react-redux'
import {createStore, applyMiddleware, compose, combineReducers} from 'redux'
import thunk from 'redux-thunk'
import ReduxToastr from 'react-redux-toastr'
import {Router, Route, browserHistory, IndexRoute} from 'react-router'
import {syncHistoryWithStore, routerMiddleware, push} from 'react-router-redux'
import Lightbox from 'lightbox2'
import Cookies from 'js-cookie'
import ReactGA from 'react-ga'
import Modal from 'react-modal';


import "script-loader!./scripts/jquery-ui.min.js";
import "script-loader!../node_modules/jquery.caret/dist/jquery.caret.min"
import "script-loader!../node_modules/at.js/dist/js/jquery.atwho.min";


import reducers from './src/reducers'
import {gaTrackingCode} from './src/config'
import {initAuthState, startListeningToAuth, loggedOut} from './src/actions/authActions'
import App from './src/components/App'
import EmptyState from './src/components/EmptyState'
import Spinner from './src/components/Spinner'
import Authenticate from './src/components/Authenticate'
import Board from './src/components/Board'
import AcceptInvite from './src/components/AcceptInvite'
import Unsubscribe from './src/components/Unsubscribe'
import {startPulse} from "./src/actions/globalActions"

import 'react-select/dist/react-select.css'
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
// import 'rc-select/assets/index.less'
//import 'rc-pagination/assets/index.css'

import './css/rc-pagination.css'

import './css/custom.css'
import './css/fonts.css'
import './css/lightbox.css'
import './css/jquery.atwho.min.css'
import './stylus/main.styl'
import 'react-widgets/dist/css/react-widgets.css'

//var css = require('./stylus/main.styl');

ReactGA.initialize(gaTrackingCode)

let initialState = {auth: initAuthState()}
const middlewareForRouter = routerMiddleware(browserHistory)

//https://github.com/zalmoxisus/redux-devtools-extension#1-with-redux
const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            actionsBlacklist: ['PULSE']
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(thunk, middlewareForRouter)
);

/*
 See https://github.com/zalmoxisus/redux-devtools-extension if we add any middleware
 */
const store = createStore(
                    reducers,
                    initialState,
                    enhancer
                );

//https://github.com/reactjs/react-router-redux
const history = syncHistoryWithStore(browserHistory, store)

function fireTracking() {
    //console.log("Tracking pageview, location = " + window.location.href)
    ReactGA.pageview(window.location.href)
}

function checkForPreviousBoard(nextState, replace, callback) {
    const lastBoardId = Cookies.get("lastBoardVisited");
    if (lastBoardId)
        replace('/board/' + lastBoardId)

    callback()
}

const __svg__ = {path: './svg/**/*.svg'};
require('webpack-svgstore-plugin/src/helpers/svgxhr')(__svg__);

// http://reactcommunity.org/react-modal/accessibility/
Modal.setAppElement('#root');

render(
    <Provider store={store}>
        <div>
            <Router onUpdate={fireTracking} history={history}>
                <Route path="/">
                    <IndexRoute component={Spinner}/>
                    <Route path="/accept/:code" component={AcceptInvite}/>
                    <Route path="/unsubscribe/:entity(/:entityId1)(/:entityId2)(/:entityId3)" component={Unsubscribe}/>
                    <Route path="/auth" component={Authenticate}/>
                    <Route path="/home" component={App}>
                        <IndexRoute component={EmptyState} onEnter={checkForPreviousBoard}/>
                    </Route>
                    <Route component={App}>
                        <Route path="/board/:boardId(/:threadId)" component={Board}/>
                    </Route>
                </Route>
            </Router>
            <ReduxToastr position="top-center"/>
        </div>
    </Provider>,
    document.getElementById('root')
)

setTimeout(function () {
    store.dispatch(startListeningToAuth())
    store.dispatch(startPulse(60))
})
