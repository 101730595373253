export const getPostByPostId = (posts, postId) => {
    return posts.filter(function (v, i) {
        return v.postId === postId;
    });
}

export const getPostIndexByPostId = (posts, postId) => {
    return posts.findIndex((post, index, posts) => {
        return post.postId == postId;
    });
}