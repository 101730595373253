import React, {Component, PropTypes} from 'react';
import Modal from 'react-modal'
import {reduxForm, Field} from 'redux-form';
import {connect} from 'react-redux'
import Select, {Creatable} from 'react-select'

import {inviteUsersComplete, inviteUsers} from '../actions/boardActions'
import {serviceRoot} from '../config'

const emailRegEx = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const validate = values => {
    const errors = {};
    return errors
};


class InviteUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            //email: props.email || "",
            modalIsOpen: props.showInviteUsers,
            selectedBoard: '',
            selectedBoardMembers: [],
            emailAddresses: []
        }
    }

    handleSelectedBoardChange = (board) => {
        this.setState({selectedBoard: board})
        this.setMemberNames(board.value, board.label)
    }

    handleSelectedBoardMembersChange = (members) => {
        this.setState({selectedBoardMembers: members})
    }

    handleEmailAddressesChange = (emails) => {
        this.setState({emailAddresses: emails})
    }

    handleSubmitEmails = (message) => {
        const emails = this.state.emailAddresses.map((email) => {
            return email.value
        })
        console.log(emails)
        const boardMembers =
            this.state.selectedBoardMembers.map((member) => {
                return {
                    userId: member.value,
                    alias: member.alias,
                    fromBoardName: member.boardName
                }
            })

        this.props.onInviteUser(emails, boardMembers, message)
    }


    validateEmailAddress = (email) => {
        return emailRegEx.test(email.label)
    }

    setMemberNames = (boardId, boardName) => {
        const that = this //there's got to be a better way of doing this
        const {activeUserId} = this.props

        $.get(serviceRoot + "boards/" + boardId + "/members", function (data) {
            const memberNames = data.reduce((members, member) => {
                if (activeUserId != member.key)
                    members.push({
                        value: member.key,
                        label: `${member.name} (${boardName})`,
                        boardName,
                        alias: member.name
                    })

                return members
            }, [])

            that.setState({memberNames})
        })
    }


    renderTextarea = field =>
        <>
            <div className="form-group has-error shtum--form-error">
                {field.meta.touched && field.meta.error &&
                <div className="help-block">{field.meta.error}</div>}
            </div>
            <div className="form-group">
                <textarea className="form-control shtum--form-input shtum--font-bold" id="message"
                          placeholder="Enter a message to go with the invite" {...field.input}
                          type={field.type} name="message"/>
            </div>
        </>


    render() {
        const {
            // fields: {emails, message},
            boardList,
            showInviteUsers,
            submitting,
            pending,
            onInviteUser, //TODO
            onInviteUsersComplete,
            handleSubmit
        } = this.props;

        const customStyles = {
            content: {
                top: '60px'
            }
        };

        return (

            <Modal
                className="Modal__Bootstrap modal-dialog shtum--modal shtum--modal-invite-users"
                closeTimeoutMS={150}
                isOpen={showInviteUsers}
                onRequestClose={this.handleModalCloseRequest}
                style={customStyles}
                contentLabel="Invite Users Dialog"
            >
                <div className="modal-content">
                    <div className="modal-header text-center">
                        <h4 className="modal-title">Invite Users</h4>
                    </div>
                    <div className="modal-body errMessage">
                        <form onSubmit={handleSubmit(data => this.handleSubmitEmails(data.message))}>
                            <div className="modal-body">

                                <div className="form-group">
                                    <Creatable name="email-addresses"
                                               className="shtum--form-input-select"
                                               value={this.state.emailAddresses}
                                               onChange={this.handleEmailAddressesChange}
                                               placeholder="Enter emails"
                                               multi={true}
                                               isValidNewOption={this.validateEmailAddress}
                                               promptTextCreator={(val) => {
                                                   return `Add email "${val}"`
                                               }}
                                               noResultsText="Enter a valid email address"
                                    />
                                </div>

                                <div className="form-group inviteBoardSelects">
                                    <Select className="col-xs-12 col-sm-4 inviteBoardNameBox shtum--form-input-select"
                                            name="board-list"
                                            options={boardList}
                                            value={this.state.selectedBoard}
                                            onChange={this.handleSelectedBoardChange}
                                            placeholder="Or choose a board..."
                                    />
                                    <Select className="col-xs-12 col-sm-8 inviteBoardUserBox shtum--form-input-select"
                                            name="member-names"
                                            options={this.state.memberNames}
                                            value={this.state.selectedBoardMembers}
                                            onChange={this.handleSelectedBoardMembersChange}
                                            placeholder="And select board members..."
                                            multi={true}
                                    />
                                </div>

                                <Field
                                    name="message"
                                    component={this.renderTextarea}
                                    type="text"/>
                            </div>
                            <div className="modal-footer errMessage">
                                <div>
                                    <button type="button" className="btn shtum--btn shtum--btn_red" data-dismiss="modal"
                                            onClick={onInviteUsersComplete}>Close
                                    </button>

                                    <button type="submit" className="btn shtum--btn shtum--btn_green_2"
                                            disabled={submitting || pending}>
                                        {submitting || pending ? <i className="fa fa-cog fa-spin"/> :
                                            <i className="fa fa-paper-plane"/>} Invite
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </Modal>
        );
    }
}

InviteUsers = reduxForm({
    form: 'InviteUsersForm',
    // fields: ['emails', 'message'],
    validate
})(InviteUsers);

const mapStateToProps = (state) => {
    let board = state.board
    let boardList = processBoardList(state.userBoards.boardList, board.activeBoard.boardId)
    let activeUserId = state.auth.uid
    return {
        showInviteUsers: board.inviteUsers,
        pending: board.inviteUsersProcessing,
        boardList,
        activeUserId
    }
};

const processBoardList = (boardList, activeBoardId) => {
    var boards = []
    for (var board in boardList) {
        if (board != activeBoardId)
            boards.push({value: board, label: boardList[board].name})
    }

    boards.sort((b1, b2) => {
        return b1.label.toLowerCase() < b2.label.toLowerCase() ? -1 : 1
    })

    return boards
}

const mapDispatchToProps = (dispatch) => {
    return {
        onInviteUsersComplete: () => {
            dispatch(inviteUsersComplete())
        },
        onInviteUser: (emails, boardMembers, message) => {
            dispatch(inviteUsers(emails, boardMembers, message));
        }
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InviteUsers);
